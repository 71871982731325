import React from "react";
import employeeDetails from "../EmployeeData.json";
import EmployeeCard from "../../components/EmployeeCard";
import {
  REVIEW_PENDING_TITLE1,
  REVIEW_PENDING_TITLE2,
} from "../../../../constants";

function PendingReview() {
  return (
    <div className="overview_count_main">
      <p className="review_pending_title">{REVIEW_PENDING_TITLE1}</p>
      <div className="employeeDisplay">
        {employeeDetails.map((item, index) => {
          if (index > 4) return;
          return <EmployeeCard key={item?.id?.toString()} employee={item} />;
        })}
      </div>
      <p className="review_pending_title">{REVIEW_PENDING_TITLE2}</p>
      <div className="employeeDisplay">
        {employeeDetails.map((item, index) => {
          if (index > 4) return;
          return <EmployeeCard key={item?.id?.toString()} employee={item} />;
        })}
      </div>
    </div>
  );
}

export default PendingReview;
