import React from "react";
import "./QuarterReview.css";
import CommentCard from "./CommentCard";
import WaitingResponseCard from "./WaitingResponseCard";
import EmptySubmittedReview from "./EmptySubmittedReview";

const QuarterReview = (props: any) => {
  const { employeeData, managerData } = props;
  return (
    <div className="quarterReview">
      <div className="quarterTitle">{props?.data?.quarter}</div>
      <div className="commentCardContainer">
        {(
          props?.manager
            ? props?.data?.manager?.comment === "" ||
              props?.data?.manager?.comment === undefined
            : props?.data?.employee?.comment === "" ||
              props?.data?.employee?.comment === undefined
        ) ? (
          <EmptySubmittedReview />
        ) : (
          <CommentCard
            comment={
              props?.manager
                ? props?.data?.manager?.comment
                : props?.data?.employee?.comment
            }
            userData={props?.manager ? managerData : employeeData}
          />
        )}
        {/* TODO : check condition when user submit empty response */}
        {!props?.manager && props?.data?.manager === null ? (
          props?.data?.allowManagerComment ? (
            <WaitingResponseCard />
          ) : (
            <EmptySubmittedReview />
          )
        ) : (
            !props?.manager
              ? props?.data?.manager?.comment === "" ||
                props?.data?.manager?.comment === undefined
              : props?.data?.employee?.comment === "" ||
                props?.data?.employee?.comment === undefined
          ) ? (
          <EmptySubmittedReview />
        ) : (
          <CommentCard
            comment={
              !props?.manager
                ? props?.data?.manager?.comment
                : props?.data?.employee?.comment
            }
            userData={props?.manager ? employeeData : managerData}
          />
        )}
      </div>
    </div>
  );
};

export default QuarterReview;
