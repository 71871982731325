import { createAsyncThunk } from "@reduxjs/toolkit";
import api from '../services/api'
//import {CardDetails} from '../../components/separation/clearance-detail/ClearanceInterface'
import { cardData } from '../reducers/card_details'
import moment from 'moment'; 

//get detail action
export const getWebLandingDetail = createAsyncThunk(
  "getWebLandingDetail",
  async (args : any, { rejectWithValue,dispatch }) => {
    const url = `api/v1/webapi/page`
    const month= moment().format('MMMM')
    const year= moment().format('YYYY')
    const lowerCaseCovertion= month.toLowerCase()
    const param={
        storyOfDay: {    
            contentType: "STORY_OF_THE_DAY",    
            isPagination: "false"
        },    
        calendarEvent: {    
            month: lowerCaseCovertion,    
            year: year    
        },    
        holiday: {    
            tag: "UPCOMING",    
            countryCode: "IND",    
            locationCode: "IND_BLR"    
        },
        justOut: {
            contentType: "JUST_OUT",
            isPagination: false
        }    
    }   

    try {
      const response = await api.post(url,param);
      const result = response.data;
      const {justOut,...restResponse} = result
      dispatch(cardData(justOut))
      return restResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
