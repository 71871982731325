import React, { useEffect, useState } from "react";
import "./quarterly-checkin-manager.css";
import "../../components/QuarterReview.css";
import CommentTextArea from "../../components/CommentsTextArea";
import ClosePopUp from "../../components/ClosePopUp";
import QuarterReview from "../../components/QuarterReview";
import CommentCard from "../../components/CommentCard";
import EmptyReviewComments from "../../components/EmptyReviewComments";
import ToastMessage from "../../../common/ToastMsg/toastMessage";
import ToastInfo from "../../../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastClose from "../../../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastError from "../../../../themes/assets/images/performance/svg/ToastError.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import {
  getQuarterlyCheckinComments,
  saveQuarterlyCheckinComments,
} from "../../../../redux/actions/performance";
import { useParams } from "react-router-dom";
import QuarterlyReviewManager from "./QuarterlyReviewManager";
import { Spin } from "antd";
import somethingWrong from "../../../../themes/assets/images/something-went-wrong.png";
import {
  ALL_COMMENTS,
  COMMENT_AREA_PLACEHOLDER,
  CONNECT_DIFFERENT_NETWORK,
  COULD_NOT_SUBMIT,
  QUARTERLY_CHECKIN_INCOMPLETED,
  QUARTERLY_SUCCESSFULLY_COMPLETED,
  SOMETHING_WENT_WRONG,
  SUBMIT_QUATERLY_CHECKIN_BTN,
  UNABLE_TO_SUBMIT,
} from "../../../../constants";

const tempData = [
  {
    quarter: "Second Quarter",
    managerComment: null,
    allowEmployeeComment: false,
    allowManagerComment: true,
    employeeComment:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque quasi obcaecati nisi facilis quod aperiam non ipsa tenetur, amet fugit.",
  },
  {
    quarter: "First Quarter",
    allowEmployeeComment: false,
    allowManagerComment: false,
    managerComment:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur recusandae voluptatum et sit commodi sapiente eos nam consectetur laboriosam eligendi cumque saepe natus atque vero vel exercitationem, fuga debitis aliquam.",
    employeeComment:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque quasi obcaecati nisi facilis quod aperiam non ipsa tenetur, amet fugit.",
  },
];

const QuarterlyCheckInManager = (props: any) => {
  const [modalState, setModalState] = useState(false);
  const [visible, setVisible] = useState(false);
  const [year, setYear] = useState("2024");
  const [comment, setComment] = useState("");
  const dispatch = useAppDispatch();
  const param = useParams();
  let toStopMapping = false;
  const {
    allQuarterlyCheckinComments,
    allQuarterlyCheckinCommentsLoading,
    saveQuarterlyCheckinCommentsLoading,
    saveQuarterlyCheckinCommentsError,
    saveQuarterlyCheckinComments,
    saveQuarterlyCheckinCommentsSuccess,
    allQuarterlyCheckinCommentsError,
  } = useAppSelector((state: any) => state.performance) || [];
  const quarterlyCheckinManagerComments = allQuarterlyCheckinComments?.comments;
  useEffect(() => {
    toStopMapping = false;

    dispatch(
      getQuarterlyCheckinComments({
        year: year,
        reporteeId: param.reporteeId,
      })
    );
  }, [param.reporteeId]);

  useEffect(() => {
    if (
      saveQuarterlyCheckinCommentsError ||
      saveQuarterlyCheckinCommentsSuccess
    ) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 3000);
    }
  }, [saveQuarterlyCheckinCommentsError, saveQuarterlyCheckinCommentsSuccess]);

  const employeeData = allQuarterlyCheckinComments?.employee;
  const managerData = allQuarterlyCheckinComments?.manager;

  const indexToStop = quarterlyCheckinManagerComments?.findIndex(
    (item) => item.allowManagerComment
  );
  const onClickSubmit = (year, quarter, comment) => {
    dispatch(
      saveQuarterlyCheckinComments({
        year: year,
        quarter: quarter,
        reporteeId: param.reporteeId,
        comment: comment,
      })
    )
      .unwrap()
      .then((success) => {
        dispatch(
          getQuarterlyCheckinComments({
            year: year,
          })
        );
      });
  };

  if (allQuarterlyCheckinCommentsError) {
    return (
      <div className="quarterly-checkinEmployee centerItems ">
        <img src={somethingWrong} className="quarterly-checkin-error-main" />
        <div className="something-went-wrong-text-quarterly-checkin">
          {SOMETHING_WENT_WRONG}
        </div>
        <div className="something-went-wrong-subHeder">
          {CONNECT_DIFFERENT_NETWORK}
        </div>
      </div>
    );
  }

  if (allQuarterlyCheckinCommentsLoading) {
    return (
      <div className="quarterly-checkinEmployee centerItems ">
        <Spin spinning={allQuarterlyCheckinCommentsLoading} />
      </div>
    );
  }
  return (
    <Spin spinning={saveQuarterlyCheckinCommentsLoading}>
      <div className="quarterly-checkinEmployee">
        {allQuarterlyCheckinComments?.comments?.length > 0 ? (
          <>
            {allQuarterlyCheckinComments?.comments?.map(
              (item: any, index: any) => {
                if (item?.allowManagerComment) {
                  return (
                    <CommentTextArea
                      key={index?.toString()}
                      title={item?.quarter}
                      placeholder={COMMENT_AREA_PLACEHOLDER}
                      submitButtonText={SUBMIT_QUATERLY_CHECKIN_BTN}
                      onClickSubmit={() =>
                        onClickSubmit(props?.year, index + 1, comment)
                      }
                      comment={comment}
                      setComment={setComment}
                    />
                  );
                }
                return null;
              }
            )}
            {allQuarterlyCheckinComments?.comments?.map(
              (item: any, index: any) => {
                if (toStopMapping) return;
                if (item?.allowManagerComment) {
                  toStopMapping = true;
                  return null;
                }
                return (
                  <>
                    <div className="all-commentsText">{ALL_COMMENTS}</div>
                    <QuarterlyReviewManager
                      data={item}
                      manager={false}
                      key={index}
                      employeeData={employeeData}
                      managerData={managerData}
                    />
                  </>
                );
              }
            )}
          </>
        ) : (
          <EmptyReviewComments />
        )}
        <ClosePopUp
          modalState={modalState}
          setModalState={setModalState}
          onHandleSubmit={() => {
            setModalState(false);
            setVisible(true);
          }}
          title={"Are you sure you want to submit?"}
          subTitle={"Submitting Self-Assessment to Rupam Lahkar"}
        />
        <div className="z-index-outer-div">
          {visible && (
            <ToastMessage
              closeAction={() => setVisible(false)}
              customToastArea={
                saveQuarterlyCheckinComments
                  ? "custom-toast-area skills-extracted"
                  : "custom-toast-area no-skills-extracted"
              }
              customToastLeftArea={"custom-left-area"}
              icon={saveQuarterlyCheckinComments ? ToastInfo : ToastError}
              toastMsgTitle={
                saveQuarterlyCheckinComments
                  ? QUARTERLY_CHECKIN_INCOMPLETED
                  : COULD_NOT_SUBMIT
              }
              toastMsgPara={
                saveQuarterlyCheckinComments
                  ? QUARTERLY_SUCCESSFULLY_COMPLETED
                  : UNABLE_TO_SUBMIT
              }
              customToastRightArea={"close-btn-area"}
              closeIcon={ToastClose}
            />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default QuarterlyCheckInManager;
