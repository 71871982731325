import React from "react";
import { Row, Col, Rate } from "antd";
import "./CommonTable.css";
import "../Performance/ASPForm/aspform.css";
import {
  CALCULATED_RATING_GOALS,
  CALCULATED_RATING_DESC,
  CALCULATED_RATING_TITLE,
  CALCULATED_RATING_BEHAVIOURAL,
} from "../../constants";

const data = [
  {
    goal: "Lorem ipsum dolor sit amet, consect",
    desc: "Achieve high sprint velocity and on-time product releases",
    rating: "0",
  },
  {
    goal: "Sprint Quality Improvement",
    desc: "Within the team to produce high quality product and features",
    rating: "0",
  },
  {
    goal: "Training & KT Sessions",
    desc: "Achieve high sprint velocity and on-time product releases",
    rating: "0",
  },
];

const data2 = [
  {
    competancy: "Effective Communication",
    desc: "Demonstrates effective written and oral communication skill",
    rating: "0",
  },
  {
    competancy: "Self Development",
    desc: "Accomplishes 30 hours of learning per years",
    rating: "0",
  },
  {
    competancy: "Team Work & Collaboration",
    desc: "Being mindful to interdependencies across functions and roles",
    rating: "0",
  },
];
const TableContent = (props: any) => {
  const { col1, col2, rating } = props;
  return (
    <>
      <Row className="row-sub-header">
        <Col span={8}>{col1}</Col>
        <Col span={12}>{col2}</Col>
        <Col span={4} className="rating-row">
          <div>
            <Rate className={"custom-rating"} defaultValue={+rating} />
          </div>
        </Col>
      </Row>
    </>
  );
};

const TableHeader = ({ header1, header2, header3 }) => {
  return (
    <Row className="row-main-header">
      <Col span={8}>{header1}</Col>
      <Col span={12}>{header2}</Col>
      <Col span={4}>{header3}</Col>
    </Row>
  );
};
const CommonTable = () => {
  return (
    <>
      <div>
        <TableHeader
          header1={CALCULATED_RATING_GOALS}
          header2={CALCULATED_RATING_DESC}
          header3={CALCULATED_RATING_TITLE}
        />
        {data.map((ele) => {
          return (
            <TableContent col1={ele.goal} col2={ele.desc} rating={ele.rating} />
          );
        })}
      </div>
      <div className="calculated-form-container">
        <TableHeader
          header1={CALCULATED_RATING_BEHAVIOURAL}
          header2={CALCULATED_RATING_DESC}
          header3={CALCULATED_RATING_TITLE}
        />
        {data2.map((ele) => {
          return (
            <TableContent
              col1={ele.competancy}
              col2={ele.desc}
              rating={ele.rating}
            />
          );
        })}
      </div>
    </>
  );
};
export default CommonTable;
