import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import n from "../../../navigation/Route.Names";
import EmptyGoal from "../../../../themes/assets/images/svg/empty-goals.svg";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  Alert,
  Button,
  Dropdown,
  Space,
  Select,
  Table,
  TableColumnsType,
  Radio,
  Modal,
  Checkbox,
  Input,
} from "antd";
import ToastInfo from "../../../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastClose from "../../../../themes/assets/images/performance/svg/ToastClose.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import MenuIcon from "../../../../themes/assets/images/svg/menu-icon.svg";
import CascadingPopImg from "../../../../themes/assets/images/performance/managerCascadinggoals.png";
import { isChecked } from "../../../utils/util";
import {
  CANCEL,
  DELETE,
  DELETE_MILESTONE_MESSAGE,
  DELETE_MILESTONE_TITLE,
  GOAL_ACTIVITY_SUB_TITLE,
  GOAL_MAIN_PAGE_SUBTITLE1,
  GOAL_MAIN_PAGE_SUBTITLE2,
  GOAL_MAIN_PAGE_NOGOAL,
} from "../../../../constants";
import BreadcrumbComponent from "../../../common/breadcrumb/breadcrumb";
import SearchImg from "../../../../themes/assets/images/performance/Search.png";
import FillButton from "../../../common/button/FillButton";
import ToastMessage from "../../../common/ToastMsg/toastMessage";
import {
  cascadeToEmployeeList,
  postCasecadeGoalCommentManager,
} from "../../../../redux/actions/performance";
import { SearchOutlined } from '@ant-design/icons';

interface DataType {
  key: number;
  name: any;
  goalName: any;
  goalDescription: string; 
  startDate: string;
  endDate: string;
  outcome: string;
  weight: string;
  status: any;
}
interface ExpandedDataType {
  key: React.Key;
  target_date: string;
  actual_date: string;
  name: string;
}

const CascadeGoals = () => {
  const goalsEmployeeList =
    useAppSelector(
      (state: any) => state.performance.casecadeToEmployeeDetailList
    ) || [];

  const [dropdownlistOptions, setDropdownListOption] = useState<any>([]);
  const [containerlistOptions, setContainerListOption] = useState<any>([]);

  useEffect(() => {
    const listOptionsList = goalsEmployeeList.map((employee) => ({
      CascadingCheckBoxImg: employee?.profilePictureImage || CascadingPopImg,
      CascadingCheckBoxTitle: employee?.userName,
      CascadingCheckDes: employee?.designation,
    }));
    setDropdownListOption(listOptionsList);
    setContainerListOption(listOptionsList);
  }, [goalsEmployeeList]);
  const [searchValue, setSearchValue] = useState<any>([]);
  const [option, setOption] = useState<any>([]);
  const [allChecked, setAllChecked] = useState<any>(false);
  const { Search } = Input;
  const handleSearch = (selectedSkills: any) => {
    setAllChecked(selectedSkills.length === dropdownlistOptions.length);
    setSearchValue(selectedSkills);
  };

  const searchHandler = (search: any) => {
    const filterList = dropdownlistOptions.filter((ele: any) =>
      ele?.CascadingCheckBoxTitle?.toLowerCase().includes(search)
    );
    if (search) {
      setContainerListOption(filterList);
    } else {
      setContainerListOption(dropdownlistOptions);
    }
  };
  const onBlurHandler = (search: any) => {
    setContainerListOption(dropdownlistOptions);
  };

  const onChange = (item: any) => {
    const filterData = searchValue.filter(
      (ele) => ele.label === item.CascadingCheckBoxTitle
    );
    const filterDataFilter = searchValue.filter(
      (ele) => ele.label !== item.CascadingCheckBoxTitle
    );
    if (filterData.length === 0) {
      setSearchValue([
        ...searchValue,
        {
          label: item.CascadingCheckBoxTitle,
          value: item.CascadingCheckBoxTitle,
        },
      ]);
      setAllChecked(searchValue.length + 1 === dropdownlistOptions.length);
    } else {
      setAllChecked(searchValue.length - 1 === dropdownlistOptions.length);
      setSearchValue(filterDataFilter);
    }
  };

  const searchImg = (
    <img src={SearchImg} alt="managaer cascading" className="search-icon" />
  );
  const [selected, setSelected] = useState<any[]>([]);

  function handleSelect(value, name) {
    if (value) {
      // if true
      setSelected([...selected, name]); // add to selected
    } else {
      // if false
      setSelected(selected.filter((item) => item !== name)); // remove from selected
    }
  }

  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  const [banner, setBanner] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isCascadeOpen, setIsCascadeOpen] = useState(false);

  const showCascadeModal = () => {
    setIsCascadeOpen(true);
  };

  const handleCascadeOk = () => {
    setIsCascadeOpen(false);
  };

  const handleCascadeCancel = () => {
    setIsCascadeOpen(false);
  };
  const [goalCountStatus, setGoalCountStatus] = useState(false);
  const [draftvisible, setDraftVisible] = useState(false);
  const [goalCount, setGoalCount] = useState(0);
  const [editGoal, setEditGoal] = useState<any>("");
  const navigate = useNavigate();
  const [year, setYear] = useState("2024");

  const showSuccessToast  = () => {
    setIsCascadeOpen(false);
    setDraftVisible(true);
  }
  const hideToast = () => {
    setDraftVisible(false)
  }

  const goalsList =
    useAppSelector(
      (state: any) => state.performance.casecadeGoalCommentManager
    ) || [];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      const countValue = selectedRowKeys.length;
      if (selectedRowKeys) {
        setGoalCountStatus(true);
        setGoalCount(countValue);
      }
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };
  const setGoal = (e,key) =>{
    e.preventDefault();
    setEditGoal(key)
    }
  const columns: ColumnsType<DataType> = [
    {
      title: "Goal Name & Description",
      key: "goalName",
      render: (record) => (
        <div key={record?.sfGoalId}>
          <div className="inner-table-milestone">
            <span className="title-mile">{record.goalName}</span>
            <span className="cont-mile">{record.goalDescription}</span>
          </div>
        </div>
      ),
    },
    {
      title: "startDate",
      dataIndex: "startDate",
    },
    {
      title: "endDate",
      dataIndex: "endDate",
    },
    {
      title: "Outcomes",
      dataIndex: "outcome",
    },
    {
      title: "Weightage",
      dataIndex: "weight",
    },
    {
      title: "Status",
      dataIndex: "goalStatus",
      render: (record) => {
        const { goalStatus, text } = record;
        return goalStatus !== null ? (
          <p
            className={
              record === "Behind"
                ? "Behind-bg"
                : record === "Completed"
                ? "Completed-bg"
                : record === "On Track"
                ? "OnTrack-bg"
                : ""
            }
          >
            {record === "Behind"
              ? "Behind"
              : record === "Completed"
              ? "Completed"
              : record === "On Track"
              ? "On Track"
              : ""}
          </p>
        ) : null;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        const { key = "" } = record;
        return (
          <Space size="middle">
            <Dropdown
              overlayClassName="goals"
              menu={menuProps}
              trigger={["click"]}
            >
              <a href="#" onClick={(e) => setGoal(e, key)}>
                <img src={MenuIcon} alt="MenuIcon" />
              </a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const items = [
    { key: "1", label: "View Goals" },
    { key: "2", label: "Comment" },
  ];

  const handleMoreClick = (e: any) => {
    if (e["key"] === "1") {
      navigate(`/${n.PERFORMANCE_URL}/${n.GOAL_PREVIEW_URL}`);
    } else {
      navigate(`/${n.PERFORMANCE_URL}/${n.GOAL_PREVIEW_URL}`, {});
    }
  };

  const menuProps = {
    items: items,
    onClick: handleMoreClick,
  };

  const tableProps: TableProps<DataType> = {
    bordered: false,
    showHeader: true,
    pagination: false,
  };

  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}`}
          className="link-highlight"
        >
          Performance
        </Link>
      ),
    },
    {
      title: "Cascade Goal",
    },
  ];

  const dispatch = useAppDispatch();

  const onAllChange = () => {
    const filter = dropdownlistOptions.map((item) => ({
      label: item.CascadingCheckBoxTitle,
      value: item.CascadingCheckBoxTitle,
    }));
    if (searchValue.length >= 1) {
      setSearchValue(filter);
      setAllChecked(true);
    }
    if (searchValue.length === 0) {
      setAllChecked(true);
      setSearchValue(filter);
    }
    if (searchValue.length === dropdownlistOptions.length) {
      setAllChecked(!allChecked);
      if (!allChecked) {
        setSearchValue(filter);
      } else {
        setSearchValue([]);
      }
    }
  };

  useEffect(() => {
    dispatch(postCasecadeGoalCommentManager({
      year: year,
    }));
    dispatch(cascadeToEmployeeList({
      year: year,
    }));
  }, []);

  return (
    <>
      <div className="manager-view-container">
        <div className="empDetialBreadcrumb">
          <BreadcrumbComponent items={breadbrumbItems} />
        </div>
        <div className="performance-header-empDetail">
          <div className="left-side">
            <div className="detail-username">Cascade Goal</div>
          </div>
        </div>
        <div className="cascade-third-section">
          <p>Select the goals to assign employee as cascaded goals </p>

          <div className="cascading-goal-card business">
            {goalCountStatus && (
              <div className="selected-goals-details">
                <div className="selected-goals-count">
                  {goalCount} goals selected
                </div>
                <div className="selected-goals-action">
                  <Button
                    className="secondary-btn"
                    onClick={() => {
                      setGoalCountStatus(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button className="primary-btn" onClick={showCascadeModal}>
                    Cascade To
                  </Button>
                </div>
              </div>
            )}
            <h5 className="title">{GOAL_ACTIVITY_SUB_TITLE}</h5>
            {goalsList?.business?.length > 0 ? (
              <div>
                <Table
                  {...tableProps}
                  columns={columns}
                  dataSource={goalsList?.business}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                  className="tbl-outer-section"
                />
              </div>
            ) : (
              <div className="empty-goals">
                <img src={EmptyGoal} alt="" />
                <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
              </div>
            )}
          </div>
          <div className="cascading-goal-card business">
            <h5 className="title">{GOAL_MAIN_PAGE_SUBTITLE1}</h5>
            {goalsList?.technology?.length > 0 ? (
              <Table
                {...tableProps}
                columns={columns}
                dataSource={goalsList?.technology}
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                className="tbl-outer-section"
              />
            ) : (
              <div className="empty-goals">
                <img src={EmptyGoal} alt="" />
                <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
              </div>
            )}
          </div>
          <div className="cascading-goal-card business">
            <h5 className="title">{GOAL_MAIN_PAGE_SUBTITLE2}</h5>
            {goalsList?.people?.length > 0 ? (
              <Table
                {...tableProps}
                columns={columns}
                dataSource={goalsList?.people}
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                className="tbl-outer-section"
              />
            ) : (
              <div className="empty-goals">
                <img src={EmptyGoal} alt="" />
                <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {draftvisible && <ToastMessage
            closeAction={hideToast}
            customToastArea={"custom-toast-area skills-extracted"}
            customToastLeftArea={"custom-left-area"}
            icon={ToastInfo}
            toastMsgTitle={"Goals Cascaded Successfully"}
            toastMsgPara={"You have cascaded the goals to the selected employees "}
            customToastRightArea={"close-btn-area"}
            closeIcon={ToastClose}
            visible={!draftvisible}
          />}

      <Modal
        title="Cascade To"
        open={isCascadeOpen}
        onCancel={handleCascadeCancel}
        footer={false}
        maskClosable={false}
        centered={true}
        className="manager-cascade-popup"
      >
        <div className="manager-search-area">
          <Space style={{ width: "100%", maxHeight: "80px"}} direction="vertical" >
            <Select
              mode="multiple"
              style={{ width: "100%", maxHeight: "80px", overflowY: "auto" }}
              placeholder="Email, Name"
              onChange={handleSearch}
              options={dropdownlistOptions.map((item) => ({
                label: item.CascadingCheckBoxTitle,
                value: item.CascadingCheckBoxTitle,
              }))}
              value={searchValue}
              labelInValue
              onSearch={searchHandler}
              onBlur={onBlurHandler}
              suffixIcon={<SearchOutlined />}
            />
          </Space>
          <FillButton
            fillBtnTxt="Cascade"
            disabled={ searchValue.length === 0 ? true : false}
            fillBtnStyles="cascadeBtn"
            fillBtnAction={showSuccessToast}
          />
        </div>
        <div className="select-all-check-box">
          <div className="select-employee-list">
          Select Employee
          </div>

          <Checkbox name="all" checked={allChecked} onChange={onAllChange}>
            Select All
          </Checkbox>
        </div>
        <div className="cascading-profile-list">
          <div>
            {containerlistOptions.map((item) => (
              <div className="cascade-checkbox-area">
                <Checkbox
                  key={item.id}
                  checked={isChecked(searchValue, item.CascadingCheckBoxTitle)}
                  onChange={() => onChange(item)}
                >
                  <div className="cascading-profile">
                    <div className="cascading-profile-img">
                      <img src={item.CascadingCheckBoxImg} />
                    </div>
                    <div className="cascading-profile-text">
                      <div className="cascading-profile-title">
                        {item.CascadingCheckBoxTitle}
                      </div>
                      <div className="cascading-profile-desc">
                        {item.CascadingCheckDes}
                      </div>
                    </div>
                  </div>
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CascadeGoals;
