import React, { useState } from "react";
import "./Preview.css";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import FillButton from "../common/button/FillButton";
import {
  ADD_GOAL,
  CANCEL,
  EDIT_GOAL,
  PERFORMANCE,
  SEND,
} from "../../constants";
import OutlineButton from "../common/button/OutlineButton";
import {
  PREVIEW_MAIN_TITLE,
  COMMENTS_MANAGER_TITLE,
  REMAINING_TXT,
} from "../../constants";
import SubHeader from "../SubHeader/SubHeader";
import tempMyWorkData from "../MyWork/MyworkSubheaderData";
import { Button } from "antd";
import n from "../navigation/Route.Names";
import CommentDialog from "./components/CommentDialog";
import { Input } from "antd";
import {
  CLOSE,
  COMMENTS_MANAGER,
  END_DATE,
  PREVIEW_COMMENT_TITLE,
  VIEW_COMMENT_TITLE,
  NEW_GOAL_BREAD_TXT,
} from "../../constants";
import {
  resetAllGoalMilestones,
  setGoalId,
  setGoalValues,
  setMandatekeys,
  setMilestoneFormat,
  setMilestoneQuestionDTO,
  setMilestones,
  setWebMilestoneQuestionList,
  setWebMilestones,
  viewState,
} from "../../redux/reducers/performance";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import GoalMilestoneCompoenent from "./form_component/GoalMilestoneComponent";
import {
  getGoalTemplate,
  saveGoal,
  saveGoalComment,
} from "../../redux/actions/performance";
import { camelCase, cloneDeep, isEmpty, startCase } from "lodash";

const { TextArea } = Input;
const Preview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const {
    goalTemplateData,
    webMilestones,
    webMilestoneQuestionList,
    goalValues,
    milestonequestionDTO,
    goalTemplateMilestoneData,
    milestones,
    sectionValues,
    saveGoalWebData,
    saveGoalLoading,
    goalData,
  } = useAppSelector((state: any) => state?.performance);
  const goalEntries = Object.entries(goalValues);
  const [comment, setComment] = useState(goalValues?.commentInfo || null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const listTabs = tempMyWorkData?.webIconInfos?.navigationTab || [];
  const { sectionList = [] } = goalTemplateData?.pageList?.[0] || {};
  const location = useLocation();
  const {
    data = {},
    isPreview = false,
    isUpdate = false,
    savePayload: payloadFromEditGoal,
    isNew = false,
  } = location?.state || {};
  const { year, userId } = data || {};
  const [editedMilestones, setEditedMilestones] = useState([]);

  useEffect(() => {
    if (isPreview) {
      appDispatch(
        getGoalTemplate({
          year,
          userId,
          questionnaireCategory: "WEB_GOAL",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!saveGoalLoading && saveGoalWebData?.saved) {
      dispatch(resetAllGoalMilestones());
      navigate(`/${n.PERFORMANCE_URL}`, { state: { performanceTabKey: "2" } });
    }
  }, [saveGoalLoading, saveGoalWebData]);

  useEffect(() => {
    if (!goalTemplateMilestoneData?.pageList?.[0] && userId) {
      appDispatch(
        getGoalTemplate({
          year,
          userId,
          questionnaireCategory: "WEB_MILESTONE",
        })
      );
    }
  }, [dispatch, userId, year]);

  useEffect(() => {
    if (goalTemplateMilestoneData?.pageList?.[0]) {
      const { sectionList = [] } =
        goalTemplateMilestoneData?.pageList?.[0] || {};
      const { questionList } = sectionList?.[0] || {};
      const questionIds: any = {};
      const questionIdWithName: any = {};
      const mandateKeys: any = [];
      if (!milestones?.length && questionList?.length) {
        questionList.forEach((question) => {
          const { questionId = "", questionDTO } = question || "";
          questionIds[questionId] = "";
          questionIdWithName[questionId] = questionDTO?.question || "";

          if (questionDTO?.mandatory) {
            mandateKeys.push(questionId);
          }
        });
        const milestone = { order: 1, ...questionIds };
        dispatch(setWebMilestoneQuestionList(questionIdWithName));
        dispatch(setMilestoneFormat(milestone));
        dispatch(setMandatekeys(mandateKeys));
        dispatch(setMilestones([milestone]));
      }
    }
  }, [goalTemplateMilestoneData]);

  useEffect(() => {
    if (!isEmpty(editedMilestones) && milestones) {
      const updateMilestones = Array(editedMilestones?.length || 1)
        .fill(milestones[0])
        .map((mileItem, index) => {
          let item = cloneDeep(mileItem);
          Object.keys(item).forEach((keyName) => {
            if (keyName !== "order") {
              let milestoneKeyName = camelCase(
                webMilestoneQuestionList[keyName]
              );
              if (milestoneKeyName === "targetDate") {
                milestoneKeyName = "formattedDueDate";
              }
              if (milestoneKeyName === "actualDate") {
                milestoneKeyName = "formattedActualDate";
              }
              item[keyName] = editedMilestones[index]?.[milestoneKeyName];
            }
          });

          return item;
        });

      dispatch(setMilestones(updateMilestones));
      dispatch(setWebMilestones(updateMilestones));
    }
  }, [editedMilestones]);

  useEffect(() => {
    if (isPreview && isEmpty(goalValues) && sectionList.length) {
      const goalNames = {};
      let updatedData: any = {};
      if (isPreview && !isEmpty(data)) {
        if (!isEmpty(data)) {
          Object.entries(data).forEach(([goalKey, goalValue]) => {
            updatedData[startCase(goalKey).toLowerCase()] = goalValue;
          });
        }
      }
      sectionList.forEach((section) => {
        section.questionList.forEach((question: any) => {
          const { question: name } = question?.questionDTO || "";
          if (name === "Milestones") {
            dispatch(setMilestoneQuestionDTO(question?.questionDTO));
            if (updatedData?.milestones) {
              setEditedMilestones(updatedData.milestones);
            }
          }
          const lowerCaseName = name.toLowerCase();
          goalNames[name] = updatedData?.[lowerCaseName] || "";

          if (lowerCaseName === "metrics") {
            goalNames[name] = updatedData?.["metric"] || "";
          }
        });
      });
      dispatch(setGoalValues({ ...goalNames }));
      dispatch(setGoalId(goalValues.id));
    }
  }, [sectionList, isPreview]);

  const handleSave = () => {
    const savePayload: any = [];

    if (Object.entries(sectionValues)?.length) {
      Object.entries(sectionValues).forEach(([sectionId, answers]): any => {
        const item: any = {
          sectionId,
          questionsAnswered:
            answers && Object.entries(answers)?.length
              ? Object.entries(answers).map(([questionId, answer]): any => ({
                  questionId,
                  answer,
                  submittedResponse: null,
                }))
              : [],
          isRepeatable: false,
          questionAnsweredList: null,
        };
        savePayload.push(item);
      });
    }

    appDispatch(
      saveGoal({
        year,
        userId,
        questionnaireCategory: "WEB_GOAL",
        sfGoalId: goalData?.sfGoalId || data?.sfGoalId || "",
        payload: {
          pageId: null,
          sections: isEmpty(payloadFromEditGoal)
            ? savePayload
            : payloadFromEditGoal,
        },
        status: "SAVE",
        method: "put",
        goalId:
          goalData?.goalId || data?.goalId || saveGoalWebData?.goalId || "",
      })
    );
  };

  const fillBtnAction = (value: object) => {
    if (isUpdate) {
      handleSave();
    } else if (isPreview) {
      navigate(`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}`, {
        state: {
          data,
          isValid: true,
          isEdit: true,
          isPreview: true,
        },
      });
    } else {
      navigate(`/${n.PERFORMANCE_URL}`, { state: { performanceTabKey: "2" } });
    }
  };

  const cancelBtn = (value: object) => {
    navigate(`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}`);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSaveComment = () => {
    const { userId, goalId, year } = data || {};
    setIsModalOpen(false);
    appDispatch(
      saveGoalComment({
        year,
        userId,
        payload: {
          comment,
        },
        goalId,
      })
    );
    navigate(`/web/my_work/${"team_performance"}/detail`, {
      state: { performanceTabKey: "2" },
    });
    dispatch(viewState(true));
  };
  const [viewer, setViewer] = useState(false); //manger=false or user=true
  const remainingCharacters = 300 - Number(comment?.length || 0);

  const items = [
    {
      title: (
        <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
          {PERFORMANCE}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}`}
          className="link-highlight"
        >
          {NEW_GOAL_BREAD_TXT}
        </Link>
      ),
    },
    {
      title: "View Goal",
    },
  ];

  return (
    <>
      <div className="subheaderStyle">
        <SubHeader
          listTabs={listTabs}
          setCurrentTab={({ item }) => {
            /*setActiveCategory(tab.detail);
            setCurrentTab(tab);*/
            navigate(`/${n.START}/${n.MYWORK_URL}/${item.url}`);
          }}
          //currentTab={{ detail: "contents", index: 0 }}
          isLink={true}
        />
      </div>
      <div className="container">
        <div className="breadcrumb_preview">
          <BreadcrumbComponent items={items} />
        </div>
        <div className="goal-preview-header">
          <div className="goal-preview-title">
            <h3 className="preview-main-title">{PREVIEW_MAIN_TITLE}</h3>
          </div>
          {!isNew ? (
            viewer ? (
              <Button
                className="viewcomment"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                {VIEW_COMMENT_TITLE}
              </Button>
            ) : (
              <Button
                className="managercomment"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                {PREVIEW_COMMENT_TITLE}
              </Button>
            )
          ) : (
            ""
          )}
        </div>

        <div className="preview-goal-main">
          <div className="preview-goal-sub">
            <div className="row-section">
              {goalEntries?.length
                ? goalEntries.map(
                    ([goalName = "", goalEntry = ""]: any) =>
                      !["Milestones", "Make private", "End date"].includes(
                        goalName
                      ) && (
                        <div className="row-section">
                          {goalName === "Start date" ? (
                            <div>
                              <h5>
                                {goalName} and {END_DATE}
                              </h5>
                              <p>
                                {goalEntry} - {goalValues["End date"]}
                              </p>
                            </div>
                          ) : (
                            <div>
                              <h5>
                                {goalName === "Status"
                                  ? "Visibility"
                                  : goalName}
                              </h5>
                              <p>
                                {goalName === "Status"
                                  ? goalEntry === "Make private"
                                    ? "Public"
                                    : "Private"
                                  : goalEntry}
                                {goalName === "Weight" && <span>%</span>}
                              </p>
                            </div>
                          )}
                        </div>
                      )
                  )
                : ""}
            </div>
            {data.goalStatus ? (
              <div className="status-section">
                <Button
                  type="primary"
                  style={{ backgroundColor: data?.goalStatusColor }}
                  className={`preview-goal-Status ${data?.goalStatus || ""}`}
                >
                  {data.goalStatus}
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="preview-goal-milestones">
            <GoalMilestoneCompoenent
              questionDTO={milestonequestionDTO}
              webMilestones={webMilestones}
              webMilestoneQuestionList={webMilestoneQuestionList}
              isEdit={false}
            />
          </div>
          <div className="button-area">
            <>
              <OutlineButton
                outlineBtnStyles="outlineBtnStyles"
                backText={CANCEL}
                outlineBtnAction={cancelBtn}
              />
              <FillButton
                fillBtnStyles="fillBtnStyles"
                fillBtnTxt={
                  !isNew && isPreview
                    ? isUpdate
                      ? "Update Goal"
                      : EDIT_GOAL
                    : ADD_GOAL
                }
                fillBtnAction={fillBtnAction}
              />
            </>
          </div>
        </div>
      </div>
      <CommentDialog
        openModal={isModalOpen}
        successHeading={PERFORMANCE}
        DialogHeading={
          viewer
            ? `${COMMENTS_MANAGER}${
                goalValues?.commentInfo?.managerInfo?.userName || ""
              }`
            : "Add Comment"
        }
        closeIcon={true}
        isEdit={!isEmpty(goalValues?.commentInfo?.managerInfo)}
        editAction={handleSaveComment}
        editBtn={"Edit"}
        goalValues={goalValues}
        modalImageStyles={{ display: "none" }}
        msgContent={[
          <React.Fragment key="comment">
            <div>{COMMENTS_MANAGER_TITLE}</div>
            <TextArea
              rows={4}
              disabled={false} //viewer
              placeholder={viewer ? goalValues?.commentInfo : ""}
              value={comment}
              onChange={(e) => {
                e.target.value?.length <= 300 &&
                  setComment(e.target.value.replace(/\n/g, ""));
              }}
            />
            <div
              className={`${
                !remainingCharacters ? "comment-character-count" : ""
              } remainingCharacters`}
            >
              {remainingCharacters} {REMAINING_TXT}
            </div>
          </React.Fragment>,
        ]}
        isCancel={viewer}
        okTxt={viewer ? CLOSE : SEND}
        okBtn={handleSaveComment}
        onOk={handleSaveComment}
        onCancel={handleCancel}
        modalSubHeading={"commentheading"}
        modalStyles={"commentdialog"}
        modalContent={"commentview"}
        maskClosable={false}
      />
    </>
  );
};

export default Preview;
