import { useEffect, useRef } from "react";
import TabButton from "../common/tabButton/tabButton";
import "./SubHeader.css";
import { useLocation } from "react-router";
import { useAppDispatch } from "../../redux/store/redux-hooks";
import { getCardContentDetail } from "../../redux/actions/card_details";
import { debounce } from "../utils/util";
import rightArrow from "../../themes/assets/images/rightArrow.png";
import leftArrow from "../../themes/assets/images/leftArrow.png";
import { ALTIVERSE, MYWORK_URL, MY_SPACE, START } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { setActiveTab } from "../../redux/reducers/card_details";

const TabButtonHolder = (props: any) => {
  const { listTabs } = props;

  const location = useLocation();
  const param = useParams();
  const navigate = useNavigate();

  const { pathname } = location;

  const dispatch = useAppDispatch();

  const onPressButton = (item: any, index: any) => {
    const { properties } = item;
    //checking for redirection based tabs
    if (item?.redirectionUrl || properties?.redirectionUrl) {
      window.open(item?.redirectionUrl || properties?.redirectionUrl, "_blank");
      if (pathname?.includes("altiverse"))
        navigate(`/${START}/${ALTIVERSE}/JUST_OUT`);
      else if (pathname?.includes("my_work"))
        navigate(`/${START}/${MYWORK_URL}/Approvals`);
      else if (pathname?.includes("my_space"))
        navigate(`/${START}/${MY_SPACE}/ASOP`);
      else navigate(`/${START}/${ALTIVERSE}/JUST_OUT`);
      return;
    }

    if (pathname?.includes("altiverse"))
      navigate(`/${START}/${ALTIVERSE}/${item?.iconId}`);
    else if (pathname?.includes("my_work"))
      navigate(`/${START}/${MYWORK_URL}/${item?.iconId}`);
    else if (pathname?.includes("my_space"))
      navigate(`/${START}/${MY_SPACE}/${item?.iconId}`);
    else {
      navigate(`/${START}/${ALTIVERSE}/${item?.iconId}`);
    }
  };

  const GetTabData = () => {
    dispatch(setActiveTab(param?.page));
    dispatch(
      getCardContentDetail({
        isPagination: false,
        iconId: param?.page || listTabs[0]?.iconId,
        isScroll: false,
      })
    );
  };

  const checkIsTabActive = (tabIconId, currentUrl) => {
    if (
      currentUrl?.page === undefined &&
      currentUrl?.contentType === undefined &&
      listTabs?.[0]?.iconId === tabIconId
    ) {
      return true;
    }
    if (
      tabIconId === currentUrl?.page ||
      tabIconId?.toLowerCase() === currentUrl?.page?.toLowerCase() ||
      tabIconId === currentUrl?.contentType
    )
      return true;
    return false;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (
      (param?.page || listTabs?.[0]?.iconId) &&
      pathname?.includes("altiverse") &&
      !pathname?.includes("E_BOOKS") &&
      !pathname?.includes("detail")
    )
      GetTabData();
  }, [pathname]);

  return (
    <div className="tabButtonContainer">
      {listTabs.map((item: any, index: any) => {
        const active = checkIsTabActive(item?.iconId, param)
          ? item?.activeImageUrl
          : item?.inactiveImageUrl;

        return (
          <TabButton
            item={item}
            index={index}
            icon={active}
            onPress={() => onPressButton(item, index)}
            activeTab={checkIsTabActive(item?.iconId, param)}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default function SubHeader(props: any) {
  const { listTabs } = props;
  const containerRef: any = useRef();

  const onScrollLeft = () => {
    containerRef.current.scrollLeft = containerRef.current.scrollLeft - 300;
  };
  const onScrollRight = () => {
    containerRef.current.scrollLeft = containerRef.current.scrollLeft + 300;
  };

  return (
    <div className="subHeaderContainer" ref={containerRef}>
      {/* <button onClick={onScrollLeft} className="left-arrow">
        <img className="arrow-img" src={leftArrow} alt="" />
      </button>
      <button onClick={onScrollRight} className="right-arrow">
        <img className="arrow-img" src={rightArrow} alt="" />
      </button> */}

      <TabButtonHolder listTabs={listTabs} />
    </div>
  );
}
