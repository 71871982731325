import React from "react";
import { Button } from "antd";
import "../tabButton/tabButton.css";

import DClock from "../../../themes/assets/images/svg/inactive/Clock.svg";
import DBook from "../../../themes/assets/images/svg/inactive/Book.svg";
import DFireSimple from "../../../themes/assets/images/svg/inactive/FireSimple.svg";
import DMedal from "../../../themes/assets/images/svg/inactive/Medal.svg";
import DTwitterLogo from "../../../themes/assets/images/svg/inactive/TwitterLogo.svg";
import DWaveform from "../../../themes/assets/images/svg/inactive/Waveform.svg";
import DBOOKMARK from "../../../themes/assets/images/svg/inactive/Bookmark.svg";
import DBLOG from "../../../themes/assets/images/svg/inactive/Blog.svg";

import AClock from "../../../themes/assets/images/svg/active/Clock.svg";
import ABook from "../../../themes/assets/images/svg/active/Book.svg";
import AFireSimple from "../../../themes/assets/images/svg/active/FireSimple.svg";
import AMedal from "../../../themes/assets/images/svg/active/Medal.svg";
import ATwitterLogo from "../../../themes/assets/images/svg/active/TwitterLogo.svg";
import AWaveform from "../../../themes/assets/images/svg/active/Waveform.svg";
import ABOOKMARK from "../../../themes/assets/images/svg/active/Bookmark.svg";
import ABLOG from "../../../themes/assets/images/svg/active/Blog.svg";

import ALeave from "../../../themes/assets/images/svg/active/Leave.svg";
import AOpportunity from "../../../themes/assets/images/svg/active/Opportunities.svg";
import APaybooks from "../../../themes/assets/images/svg/active/Paybooks.svg";
import ABenifits from "../../../themes/assets/images/svg/active/Benefits.svg";
import APolicyHub from "../../../themes/assets/images/svg/active/PolicyHub.svg";
import AASOP from "../../../themes/assets/images/svg/active/ASOP.svg";
import AHelpDesk from "../../../themes/assets/images/svg/active/HelpDesk.svg";

import DLeave from "../../../themes/assets/images/svg/inactive/Leave.svg";
import DOpportunity from "../../../themes/assets/images/svg/inactive/Opportunities.svg";
import DPaybooks from "../../../themes/assets/images/svg/inactive/Paybooks.svg";
import DBenifits from "../../../themes/assets/images/svg/inactive/Benefits.svg";
import DPolicyHub from "../../../themes/assets/images/svg/inactive/PolicyHub.svg";
import DASOP from "../../../themes/assets/images/svg/inactive/ASOP.svg";
import DHelpDesk from "../../../themes/assets/images/svg/inactive/HelpDesk.svg";

import DBrandShop from "../../../themes/assets/images/svg/inactive/BrandShop.svg";
import DForm16 from "../../../themes/assets/images/svg/inactive/Form16.svg";
import DHolidayCalendar from "../../../themes/assets/images/svg/inactive/HolidayCalendar.svg";
import DIJP from "../../../themes/assets/images/svg/inactive/IJP.svg";
import DLaurels from "../../../themes/assets/images/svg/inactive/Laurels.svg";
import DPayslip from "../../../themes/assets/images/svg/inactive/Payslip.svg";

import DApprovals from "../../../themes/assets/images/svg/inactive/Approvals.svg";
import DCompetency from "../../../themes/assets/images/svg/inactive/Competency.svg";
import DCREO from "../../../themes/assets/images/svg/inactive/CREO.svg";
import DFacilityAdmin from "../../../themes/assets/images/svg/inactive/FacilityAdmin.svg";
import DInnovation from "../../../themes/assets/images/svg/inactive/Innovation.svg";
import DLeadershipDashboard from "../../../themes/assets/images/svg/inactive/LeadershipDashboard.svg";
import DLearningDevelopment from "../../../themes/assets/images/svg/inactive/LearningDevelopment.svg";
import DPerformance from "../../../themes/assets/images/svg/inactive/Performance.svg";
import DPlayground from "../../../themes/assets/images/svg/inactive/Playground.svg";
import DPRISM from "../../../themes/assets/images/svg/inactive/PRISM.svg";
import DRefferals from "../../../themes/assets/images/svg/inactive/Refferals.svg";
import DSodexo from "../../../themes/assets/images/svg/inactive/Sodexo.svg";
import DTravelExpense from "../../../themes/assets/images/svg/inactive/TravelExpense.svg";
import DTimesheet from "../../../themes/assets/images/svg/inactive/Timesheet.svg";
import DWINGS from "../../../themes/assets/images/svg/inactive/WINGS.svg";
import DCalendar from "../../../themes/assets/images/svg/inactive/Calendar.svg";
import DAdminPanel from "../../../themes/assets/images/svg/inactive/AdminPanel.svg";
import DSeparation from "../../../themes/assets/images/svg/inactive/Separation.svg";
import DGoals from "../../../themes/assets/images/svg/inactive/Goals.svg";
import DPodcast from "../../../themes/assets/images/svg/inactive/Podcast.svg";

import ABrandShop from "../../../themes/assets/images/svg/active/BrandShop.svg";
import AForm16 from "../../../themes/assets/images/svg/active/Form16.svg";
import AHolidayCalendar from "../../../themes/assets/images/svg/active/HolidayCalendar.svg";
import AIJP from "../../../themes/assets/images/svg/active/IJP.svg";
import ALaurels from "../../../themes/assets/images/svg/active/Laurels.svg";
import APayslip from "../../../themes/assets/images/svg/active/Payslip.svg";

import AApprovals from "../../../themes/assets/images/svg/active/Approvals.svg";
import ACompetency from "../../../themes/assets/images/svg/active/Competency.svg";
import ACREO from "../../../themes/assets/images/svg/active/CREO.svg";
import AFacilityAdmin from "../../../themes/assets/images/svg/active/FacilityAdmin.svg";
import AInnovation from "../../../themes/assets/images/svg/active/Innovation.svg";
import ALeadershipDashboard from "../../../themes/assets/images/svg/active/LeadershipDashboard.svg";
import ALearningDevelopment from "../../../themes/assets/images/svg/active/LearningDevelopment.svg";
import APerformance from "../../../themes/assets/images/svg/active/Performance.svg";
import APlayground from "../../../themes/assets/images/svg/active/Playground.svg";
import APRISM from "../../../themes/assets/images/svg/active/PRISM.svg";
import ARefferals from "../../../themes/assets/images/svg/active/Refferals.svg";
import ASodexo from "../../../themes/assets/images/svg/active/Sodexo.svg";
import ATravelExpense from "../../../themes/assets/images/svg/active/TravelExpense.svg";
import ATimesheet from "../../../themes/assets/images/svg/active/Timesheet.svg";
import AWINGS from "../../../themes/assets/images/svg/active/WINGS.svg";
import ACalendar from "../../../themes/assets/images/svg/active/Calendar.svg";
import Altifeed from "../../../themes/assets/images/svg/active/Altifeed.svg";
import AltifeedInactive from "../../../themes/assets/images/svg/inactive/Altifeed.svg";
import AAdminPanel from "../../../themes/assets/images/svg/active/AdminPanel.svg";
import ASeparation from "../../../themes/assets/images/svg/active/Separation.svg";
import AGoals from "../../../themes/assets/images/svg/active/Goals.svg";
import AITC from "../../../themes/assets/images/svg/active/ITC.svg";
import DITC from "../../../themes/assets/images/svg/inactive/ITC.svg";
import APodcast from "../../../themes/assets/images/svg/active/Podcast.svg";
import amplitude from "amplitude-js";

const activeImages: any = {
  JUST_OUT: AClock,
  TWEETS: ATwitterLogo,
  SUCCESS_STORY: AMedal,
  TOPICKS: AFireSimple,
  VOICE_OF_YOU: AWaveform,
  E_BOOKS: ABook,
  BOOKMARK: ABOOKMARK,
  BLOGS: ABLOG,
  Leave: ALeave,
  Opportunity: AOpportunity,
  Paybooks: APaybooks,
  Benifits: ABenifits,
  Policy_Hub: APolicyHub,
  ASOP: AASOP,
  Help_Desk: AHelpDesk,
  Brand_Shop: ABrandShop,
  Form16: AForm16,
  Holiday_Calendar: AHolidayCalendar,
  IJP: AIJP,
  Laurels: ALaurels,
  Payslip: APayslip,
  Approvals: AApprovals,
  Competency: ACompetency,
  CREO: ACREO,
  Facility_Admin: AFacilityAdmin,
  Innovation: AInnovation,
  Leadership_Dashboard: ALeadershipDashboard,
  Learning_Development: ALearningDevelopment,
  Performance: APerformance,
  team_performance: APerformance,
  Playground: APlayground,
  PRISM: APRISM,
  Refferals: ARefferals,
  Sodexo: ASodexo,
  Travel_Expense: ATravelExpense,
  Timesheet: ATimesheet,
  WINGS: AWINGS,
  Calendar: ACalendar,
  ALTIFEED: Altifeed,
  Admin_Panel: AAdminPanel,
  Separation: ASeparation,
  Goals: AGoals,
  ITcomputation: AITC,
  Prodcast: APodcast,
};
const inactiveImages: any = {
  JUST_OUT: DClock,
  TWEETS: DTwitterLogo,
  SUCCESS_STORY: DMedal,
  TOPICKS: DFireSimple,
  VOICE_OF_YOU: DWaveform,
  E_BOOKS: DBook,
  BOOKMARK: DBOOKMARK,
  BLOGS: DBLOG,
  Leave: DLeave,
  Opportunity: DOpportunity,
  Paybooks: DPaybooks,
  Benifits: DBenifits,
  Policy_Hub: DPolicyHub,
  ASOP: DASOP,
  Help_Desk: DHelpDesk,
  Brand_Shop: DBrandShop,
  Form16: DForm16,
  Holiday_Calendar: DHolidayCalendar,
  IJP: DIJP,
  Laurels: DLaurels,
  Payslip: DPayslip,
  Approvals: DApprovals,
  Competency: DCompetency,
  CREO: DCREO,
  Facility_Admin: DFacilityAdmin,
  Innovation: DInnovation,
  Leadership_Dashboard: DLeadershipDashboard,
  Learning_Development: DLearningDevelopment,
  Performance: DPerformance,
  team_performance: DPerformance,
  Playground: DPlayground,
  PRISM: DPRISM,
  Refferals: DRefferals,
  Sodexo: DSodexo,
  Travel_Expense: DTravelExpense,
  Timesheet: DTimesheet,
  WINGS: DWINGS,
  Calendar: DCalendar,
  ALTIFEED: AltifeedInactive,
  Admin_Panel: DAdminPanel,
  Separation: DSeparation,
  Goals: DGoals,
  ITcomputation: DITC,
  Prodcast: DPodcast,
};

const TabButton = (props: any) => {
  let activateTab = props?.activeTab ? "tabButtonActive" : "tabButton";

  return (
    <Button
      block
      className={activateTab}
      type="primary"
      onClick={() => {
        amplitude.getInstance().logEvent(`${props?.item?.iconId}`);
        props?.onPress(props?.item, props?.index);
      }}
    >
      <img
        className="tabImage"
        src={
          props?.activeTab
            ? activeImages[props?.item?.iconId]
            : inactiveImages[props?.item?.iconId]
        }
        alt=""
      />
      <> {props?.item?.iconLabel}</>
    </Button>
  );
};

export default TabButton;
