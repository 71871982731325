import React, { useEffect, useState } from "react";
import "./EmployeeCard.css";
import { useNavigate } from "react-router-dom";
import AVATARIMG from "../../../themes/assets/images/performance/avatar-emp.jpg";
import AvatarProfile from "../../Avatar/AvatarProfile";

const EmployeeCard = ({ employee }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() =>
        navigate(
          `/web/my_work/${"team_performance"}/detail/${employee.reporteeId}`
        )
      }
      className="cardcomponents"
      key={employee.id}
    >
      <div className="image-Container">
        {employee.profilePictureImage ? (
          <img
            className="image"
            src={`data:image/jpeg;base64,${employee.profilePictureImage}`}
            alt=""
          />
        ) : (
          employee.userName && (
            <div className="avatar-container">
              <AvatarProfile
                name={employee.userName}
                size={47}
                profilePic={""}
              />
            </div>
          )
        )}
      </div>
      <div className="text">
        <div className="employee-name-apprasial">
          <div className="name">{employee.userName}</div>
          {employee.appraisalQuarter && (
            <div className="appraisal-quarter">{employee.appraisalQuarter}</div>
          )}
        </div>
        <div className="designation">{employee.designation}</div>
      </div>
    </div>
  );
};
export default EmployeeCard;
