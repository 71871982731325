import React, { useState } from "react";
import "./RichTextEditor.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import ColorActive from "../../../themes/assets/images/svg/active/Color.svg";
import ColorInactive from "../../../themes/assets/images/svg/inactive/Color.svg";
import BoldActive from "../../../themes/assets/images/svg/active/Bold.svg";
import BoldInactive from "../../../themes/assets/images/svg/inactive/Bold.svg";
import UnderlineActive from "../../../themes/assets/images/svg/active/TextUnderline.svg";
import UnderlineInactive from "../../../themes/assets/images/svg/inactive/TextUnderline.svg";
import OrderedListActive from "../../../themes/assets/images/svg/active/OrderedList.svg";
import OrderedListInactive from "../../../themes/assets/images/svg/inactive/OrderedList.svg";
import UnorderedListActive from "../../../themes/assets/images/svg/active/UnorderedList.svg";
import UnorderedListInactive from "../../../themes/assets/images/svg/inactive/UnorderedList.svg";
import { Dropdown } from "antd";

// const EditorButtons = (props) => {
//   const buttonStyle = props?.active
//     ? "editor-button active-editor-button"
//     : "editor-button";

//   return (
//     <div onClick={props?.onClick} className={buttonStyle}>
//       <img
//         className="editor-icon"
//         alt="editor-icon"
//         src={props?.active ? props?.activeIcon : props?.inactiveIcon}
//       />
//     </div>
//   );
// };

// const RichTextEditor = (props) => {
//   const { content, setContent } = props;

//   const makeBold = () => {
//     document.execCommand("bold", false, "");
//   };

//   const makeItalic = () => {
//     document.execCommand("italic", false, "");
//   };

//   const makeUnderline = () => {
//     document.execCommand("underline", false, "");
//   };

//   const changeColor = () => {
//     const color: any = prompt("Enter color name or hex code:", "");
//     document.execCommand("foreColor", false, color);
//   };

//   const insertOrderedList = () => {
//     document.execCommand("insertOrderedList", false, "");
//   };

//   const insertUnorderedList = () => {
//     document.execCommand("insertUnorderedList", false, "");
//   };

//   const handleOnChange = (e) => {
//     setContent(e.target.innerHTML);
//   };

//   return (
//     <div className="editor-container">
//       <div className="heading-container">
//         <div className="editor-heading">
//           {"Employee Comment"}
//           <span>*</span>
//         </div>
//         <div className="buttons-container">
//           <EditorButtons
//             onClick={changeColor}
//             active={true}
//             activeIcon={ColorActive}
//             inactiveIcon={ColorInactive}
//           />
//           <EditorButtons
//             onClick={makeBold}
//             active={true}
//             activeIcon={BoldActive}
//             inactiveIcon={BoldInactive}
//           />
//           <EditorButtons
//             onClick={makeUnderline}
//             active={true}
//             activeIcon={UnderlineActive}
//             inactiveIcon={UnderlineInactive}
//           />
//           <EditorButtons
//             onClick={insertOrderedList}
//             active={true}
//             activeIcon={OrderedListActive}
//             inactiveIcon={OrderedListInactive}
//           />
//           <EditorButtons
//             onClick={insertUnorderedList}
//             active={true}
//             activeIcon={UnorderedListActive}
//             inactiveIcon={UnorderedListInactive}
//           />

//           {/* <button onClick={makeBold}>Bold</button>
//           <button onClick={makeItalic}>Italic</button>
//           <button onClick={makeUnderline}>Underline</button>
//           <button onClick={changeColor}>Change Color</button>
//           <button onClick={insertOrderedList}>Ordered List</button>
//           <button onClick={insertUnorderedList}>Unordered List</button> */}
//         </div>
//       </div>

//       <div
//         contentEditable
//         className="editor-content"
//         // dangerouslySetInnerHTML={{ __html: content }}
//         onInput={handleOnChange}
//       />
//     </div>
//   );
// };

// const modules = {
//   toolbar: {
//     container: "#toolbar",
//   },
//   clipboard: {
//     matchVisual: false,
//   },

function RichTextEditor(props) {
  const modules = {
    toolbar: [
      [{ color: ["#000000", "#2B5DA9", "#910000", "#10666B", "#B54901", "#746704", ] }],
      ["bold", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const [editorHtml, setEditorHtml] = useState("");

  return (
    <div className="text-editor">
      <ReactQuill
        theme="snow"
        modules={modules}
        onChange={setEditorHtml}
        value={editorHtml}
        placeholder="Please add your comments"
      />
    </div>
  );
}

export default RichTextEditor;
