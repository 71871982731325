import { useState } from "react";
import { useNavigate } from "react-router-dom";
import n from "../../../navigation/Route.Names";
import OutlineButton from "../../../common/button/OutlineButton";
import WeightLeft from "../../themes/assets/images/svg/weight-left.svg";
import EmptyGoal from "../../../../themes/assets/images/svg/empty-goals.svg";
import AaccessTime from "../../../../themes/assets/images/svg/front/active_access_time.svg";
import CloseIconPopUp from "../../themes/assets/images/svg/Close-black.svg";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  Alert,
  Button,
  Dropdown,
  Menu,
  Space,
  Table,
  TableColumnsType,
} from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import { ExpandableConfig } from "rc-table/lib/interface";
import MenuIcon from "../../../../themes/assets/images/svg/menu-icon.svg";
import ExpandLess from "../../../../themes/assets/images/svg/Expand_less.svg";
import ExpandMore from "../../../../themes/assets/images/svg/Expand_more-black.svg";
import Warning from "../../themes/assets/images/svg/Alert/Warning.svg";
import {
  CANCEL,
  DELETE,
  DELETE_MILESTONE_MESSAGE,
  DELETE_MILESTONE_TITLE,
  GOAL_ACTIVITY_SUB_TITLE,
  GOAL_MAIN_PAGE_SUBTITLE1,
  GOAL_MAIN_PAGE_SUBTITLE2,
  GOAL_MAIN_PAGE_NOGOAL,
  VIEW_GOAL,
  EDIT_GOAL,
  PREVIEW_COMMENT_TITLE,
} from "../../../../constants";
import { setGoalData } from "../../../../redux/reducers/performance";

interface DataType {
  goalId: number;
  goalName: any;
  goalDescription: string;
  startDate: string;
  endDate: string;
  outcome: string;
  weight: string;
  goalStatus: any;
}

interface ExpandedDataType {
  key: React.Key;
  target_date: string;
  actual_date: string;
  name: string;
}

const EmptyGoals = () => {
  const [banner, setBanner] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editGoal, setEditGoal] = useState<any>("");
  const navigate = useNavigate();
  const goalsList =
    useAppSelector((state: any) => state.performance.getEmployeeFilteredData) ||
    [];
  const renderStatusButton = (goalStatus, record) => {
    const { goalStatusColor } = record || {};
    return (
      <Button
        style={{ backgroundColor: goalStatusColor }}
        className={`goal-status-btn ${goalStatus}`}
      >
        {goalStatus}
      </Button>
    );
  };
  const dispatch = useAppDispatch();

  const goalView = (goal) => {
    navigate(`/${n.PERFORMANCE_URL}/previewgoal`, {
      state: {
        data: goal,
        isPreview: true,
      },
    });
    dispatch(setGoalData(goal));
  };

  const renderActionsMenu = (goal) => (
    <Menu>
      <Menu.Item key="comment" onClick={() => goalView(goal)}>
        {PREVIEW_COMMENT_TITLE}
      </Menu.Item>
      <Menu.Item key="view" onClick={() => goalView(goal)}>
        {VIEW_GOAL}
      </Menu.Item>
    </Menu>
  );

  const columns: ColumnsType<DataType> = [
    {
      title: "Goal Name & Description",
      dataIndex: ["goalName", "goalDescription"],
      render: (text, record) => (
        <>
          <div className="inner-table-milestone">
            {/* <img src={AaccessTime} alt="AaccessTime" /> */}
            <span className="title-mile">{record["goalName"]}</span>
            <span className="cont-mile">{record["goalDescription"]}</span>
          </div>
        </>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
    },
    {
      title: "Due Date",
      dataIndex: "endDate",
    },
    {
      title: "Outcomes",
      dataIndex: "outcome",
    },
    {
      title: "Weightage",
      dataIndex: "weight",
    },
    {
      title: "Status",
      dataIndex: "goalStatus",
      render: (record, item) => renderStatusButton(record, item),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        const { key = "" } = record;
        return (
          <Space size="middle">
            <Dropdown
              overlayClassName="goals"
              overlay={() => renderActionsMenu(record)}
              trigger={["click"]}
            >
              <img src={MenuIcon} alt="MenuIcon" />
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const items = [
    { key: "1", label: "View Goals" },
    { key: "2", label: "Comment" },
  ];

  const handleMoreClick = (e: any) => {
    if (e["key"] === "1") {
      navigate(`/${n.PERFORMANCE_URL}/${n.GOAL_PREVIEW_URL}`);
    } else {
      navigate(`/${n.PERFORMANCE_URL}/${n.GOAL_PREVIEW_URL}`, {});
    }
  };

  const menuProps = {
    items: items,
    onClick: handleMoreClick,
  };
  const defaultExpandable = {
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <img
          src={ExpandLess}
          onClick={(e) => onExpand(record, e)}
          className="expand"
          alt="expand"
        />
      ) : (
        <img
          src={ExpandMore}
          onClick={(e) => onExpand(record, e)}
          className="expand"
          alt="expand"
        />
      ),
    expandedRowRender: (record: any) => {
      const columns: TableColumnsType<ExpandedDataType> = [
        {
          title: "Milestones",
          dataIndex: "name",
          key: "name",
          render: (record) => (
            <div className="inner-table-milestone">
              <img src={AaccessTime} alt="AaccessTime" />
              <span>{record}</span>
            </div>
          ),
        },
        { title: "Target Date", dataIndex: "target_date", key: "target_date" },
        { title: "Actual Date", dataIndex: "actual_date", key: "actual_date" },
        {
          title: "",
          dataIndex: "operation",
          key: "operation",
          render: () => (
            <Space size="middle">
              <a>Edit</a>
            </Space>
          ),
        },
      ];

      const milestones: ExpandedDataType[] = [];
      for (let i = 0; i < 3; ++i) {
        milestones.push({
          key: i.toString(),
          target_date: "2014-12-24 23:12:00",
          actual_date: "2014-12-24 23:12:00",
          name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        });
      }
      return (
        <Table
          columns={columns}
          dataSource={milestones}
          pagination={false}
          className="tbl-inner-section"
        />
      );
    },
  };

  const [expandable, setExpandable] = useState<
    ExpandableConfig<DataType> | undefined
  >(defaultExpandable);
  const closeHandler = () => {
    setBanner(false);
  };

  const tableColumns = columns.map((item) => ({ ...item }));

  const tableProps: TableProps<DataType> = {
    bordered: false,
    showHeader: true,
    pagination: false,
    expandable,
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    //dispatch(reset({}));
    //navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
  };
  return (
    <>
      <div>
        <p>
          <span>Total Weight:</span>{" "}
          <span className="total-weight-green">0.0%</span>
        </p>
        <div className="business">
          <h5 className="title">{GOAL_ACTIVITY_SUB_TITLE}</h5>
          {goalsList?.business?.length > 0 ? (
            <Table
              {...tableProps}
              columns={tableColumns}
              dataSource={goalsList?.business}
              className="tbl-outer-section"
            />
          ) : (
            <div className="empty-goals">
              <img src={EmptyGoal} alt="" />
              <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
            </div>
          )}
        </div>
        <div className="business">
          <h5 className="title">{GOAL_MAIN_PAGE_SUBTITLE1}</h5>
          {goalsList?.technology?.length > 0 ? (
            <Table
              {...tableProps}
              columns={tableColumns}
              dataSource={goalsList?.technology}
              className="tbl-outer-section"
            />
          ) : (
            <div className="empty-goals">
              <img src={EmptyGoal} alt="" />
              <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
            </div>
          )}
        </div>
        <div className="business">
          <h5 className="title">{GOAL_MAIN_PAGE_SUBTITLE2}</h5>
          {goalsList?.people?.length > 0 ? (
            <Table
              {...tableProps}
              columns={tableColumns}
              dataSource={goalsList?.people}
              className="tbl-outer-section"
            />
          ) : (
            <div className="empty-goals">
              <img src={EmptyGoal} alt="" />
              <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmptyGoals;
