import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import n from "../navigation/Route.Names";
import OutlineButton from "../common/button/OutlineButton";
import WeightLeft from "../../themes/assets/images/svg/weight-left.svg";
import EmptyGoal from "../../themes/assets/images/svg/empty-goals.svg";
import CloseIconPopUp from "../../themes/assets/images/svg/Close-black.svg";
import { Alert, Spin } from "antd";
import CascadingInfo from "../../themes/assets/images/svg/goals/cascadingAlertInfo.svg";
import Warning from "../../themes/assets/images/svg/Alert/Warning.svg";
import {
  CASCADING_ALERT_INFO,
  GOAL_MAIN_PAGE_NOGOAL,
  LISTING_TOTAL_WEIGHT,
  REVIEW_TXT,
  SUBMIT_ALERT_TEXT,
} from "../../constants";
import {
  clearDeleteGoalStatus,
  resetAllGoalMilestones,
  setCascadedGoal,
  setGoalData,
  setGoalId,
  setGoalValues,
  setNewGoalValues,
  setShouldGetGoals,
} from "../../redux/reducers/performance";
import { getAllGoalTemplate } from "../../redux/actions/performance";
import { groupBy, isEmpty } from "lodash";
import "./EmptyGoals.css";
import GoalTable from "./GoalTable";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";

const GoalsList = () => {
  const [banner, setBanner] = useState(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    allGoalTemplateData,
    shouldGetGoals,
    allGoalTemplateLoading,
    totalGoals,
    cascadeGoal,
  } = useAppSelector((state: any) => state.performance) || [];

  const getAllGoals = () => {
    dispatch(getAllGoalTemplate());
    dispatch(resetAllGoalMilestones());
    dispatch(setGoalValues({}));
    dispatch(setNewGoalValues({}));
  };

  useEffect(() => {
    shouldGetGoals && getAllGoals();
  }, [shouldGetGoals]);

  useEffect(() => {
    return () => {
      dispatch(setShouldGetGoals(true));
      dispatch(clearDeleteGoalStatus());
    };
  }, []);

  const { goalInfo = [], totalWeight = 0 } = allGoalTemplateData || {};
  const [categories, setCategories] = useState({});

  useEffect(() => {
    if (goalInfo?.length) {
      setCategories(groupBy(goalInfo, "category"));
      const updateCascadeGoal = goalInfo?.length
        ? goalInfo.find((item) => item?.cascadeOpen)
        : {};

      dispatch(setCascadedGoal(updateCascadeGoal));
    }
  }, [goalInfo]);

  const closeHandler = () => {
    setBanner(false);
  };

  const getCategoryGoals = (type) => (
    <div className="business">
      <h5 className="title">{type}</h5>
      {categories?.[type]?.length ? (
        <GoalTable data={categories?.[type]} />
      ) : (
        <div className="empty-goals">
          <img src={EmptyGoal} alt="" />
          <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
        </div>
      )}
    </div>
  );

  const editGoal = (goal) => {
    navigate(`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}`, {
      state: {
        data: goal,
        isValid: true,
        isEdit: true,
        isPreview: true,
      },
    });
    dispatch(setGoalData(goal));
    dispatch(setGoalId(goal.goalId));
  };

  const handleReview = () => editGoal(cascadeGoal);

  return (
    <>
      <div>
        {totalGoals ? (
          <Alert
            message={SUBMIT_ALERT_TEXT}
            type="warning"
            icon={<img src={Warning} alt="" />}
            showIcon
          />
        ) : (
          ""
        )}
        {!isEmpty(cascadeGoal) ? (
          <div className="cascading-alert-area">
            <Alert
              message={cascadeGoal?.cascadedBy + " " + CASCADING_ALERT_INFO}
              type="warning"
              icon={<img src={CascadingInfo} alt="" />}
              showIcon
              action={
                <OutlineButton
                  outlineBtnAction={handleReview}
                  outlineBtnStyles="review-btn"
                  backText={REVIEW_TXT}
                />
              }
            />
          </div>
        ) : (
          ""
        )}
        <p>
          <span>{LISTING_TOTAL_WEIGHT}:</span>{" "}
          <span className="total-weight-green">{totalWeight || 0}%</span>
        </p>
        {banner && (
          <div className="today-weight">
            <div className="weight-left">
              <img src={WeightLeft} alt="" />
            </div>
            <div className="weight-center">
              <h5>Hi, I am Vijay!</h5>
              <p>
                I am a Senior Engineer here at Altimetrik, I would love to show
                you how having <span className="smart">SMART</span> goals helped
                me in my journey. Wanna see my Goals and Outcomes of last
                assessment year?
              </p>
              <OutlineButton
                outlineBtnAction={() => {
                  navigate(`/${n.PERFORMANCE_URL}/${n.SAMRT_GOAL_URL}`);
                }}
                backText="+ Check My Goals!"
                outlineBtnStyles="check-goal-btn"
              />
            </div>
            <img
              className="close-icon"
              src={CloseIconPopUp}
              alt="CloseIconPopUp"
              onClick={closeHandler}
            />
          </div>
        )}

        {getCategoryGoals("Business")}
        {getCategoryGoals("Technology")}
        {getCategoryGoals("People")}
      </div>
      <Spin spinning={allGoalTemplateLoading} />
    </>
  );
};

export default GoalsList;
