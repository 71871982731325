import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Spin, List, Skeleton } from "antd";
import "./index.css";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import { getHoliday } from "../../redux/actions/my_space";
import { CalendarFixedIcon, CalendarFloaterIcon } from "../common/svg/svg";
import { dateSplit } from "../utils/util";

const HolidayCalendar = (props: any) => {
  const { activekey, country, city } = props;
  const dispatch = useAppDispatch();
  const { holiday: holidayList } =
    useAppSelector((state) => state.holidayList) || [];

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (country || city) dispatch(getHoliday({ activekey, country, city }));
  }, [activekey, country, city]);

  const imageOnLoad = () => {
    setIsActive(false);
  };

  const imageErrorLoad = () => {
    setIsActive(true);
  };

  return (
    <>
      <div>
        <Spin spinning={false} delay={500}>
          <List
            grid={{
              gutter: 24,
              xs: 1,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={holidayList}
            renderItem={(element: any, index) => (
              <List.Item>
                <div className="calendar_card_container">
                  <div className="calendar_img_container">
                    <Skeleton.Node
                      active={isActive}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <LazyLoadImage
                        onLoad={imageOnLoad}
                        src={element?.referenceUrl?.url}
                        className="calendar_img"
                        alt="banner"
                        effect="blur"
                      />
                    </Skeleton.Node>
                  </div>
                  <div className="calendar_main">
                    <span
                      className="calendar_date_main"
                      data-testid="item-label"
                    >
                      <p className="calendar_month">
                        {dateSplit(element?.date, "month")}
                      </p>
                      <p className="calendar_day">
                        {dateSplit(element?.date, "date")}
                      </p>
                      <p className="calendar_week">
                        {dateSplit(element?.date, "day")}
                      </p>
                    </span>
                    <span data-testid="item-label" className="calendar_name">
                      {element?.name}
                      <p>
                        {element?.holidayClass.includes("Fixed") ? (
                          <span className="leave-type-icon">
                            <CalendarFixedIcon />
                          </span>
                        ) : (
                          <span className="leave-type-icon">
                            <CalendarFloaterIcon />
                          </span>
                        )}
                        {element?.holidayClass}
                      </p>
                    </span>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </Spin>
      </div>
    </>
  );
};

export default HolidayCalendar;
