import { createAsyncThunk } from "@reduxjs/toolkit";
import performance from "../services/performance";

//get performancestaticData detail action
export const getUserPerformanceStaticData = createAsyncThunk(
  "getUserPerformanceStaticData",
  async (args: any, { rejectWithValue }) => {
    const url = `api/v1/getUserPerformanceStaticData`;
    try {
      // const response = await performance.get(url);
      // const result = response.data;
      const result = {
        someStaticData: "",
        performanceYears: [2024, 2023, 2022, 2021, 2020, 2019, 2018],
      };
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get quarterlyCheckinDataEmployee detail action
export const quarterlyCheckinDataEmployee = createAsyncThunk(
  "quarterlyCheckinDataEmployee",
  async (args: any, { rejectWithValue }) => {
    const { year } = args;
    const url = `api/v1/performance-review/quarterly-checkin?year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get goal template
export const getGoalTemplate = createAsyncThunk(
  "getGoalTemplate",
  async (args: any, { rejectWithValue }) => {
    const { year, userId, questionnaireCategory, goalId = "" } = args;
    const url = `api/v1/goal/template?year=${year}&userId=${userId}&questionnaireCategory=${questionnaireCategory}${
      goalId ? `&goalId=${goalId}` : ""
    }`;

    try {
      const response = await performance.get(url);
      const result = response.data;
      return { result, questionnaireCategory };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//save template
export const saveGoal = createAsyncThunk(
  "saveGoalTemplate",
  async (args: any, { rejectWithValue }) => {
    const {
      year,
      questionnaireCategory,
      userId,
      status,
      payload,
      method = "post",
      goalId = "",
      sfGoalId,
    } = args;
    const params = { year, status, userId, questionnaireCategory };
    if (sfGoalId) {
      params["sfGoalId"] = sfGoalId;
    } else if (goalId) {
      params["goalId"] = goalId;
    }

    try {
      const response = await (method === "post"
        ? performance.post("api/v1/goal", payload, { params })
        : performance.put("api/v1/goal", payload, { params }));
      const result = response.data;
      return { result, questionnaireCategory };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//save Goal comment
export const saveGoalComment = createAsyncThunk(
  "saveGoalComment",
  async (args: any, { rejectWithValue }) => {
    const { year, userId, payload, goalId = "" } = args;
    const url = `api/v1/goal/manager/comment?year=${year}&reporteeId=${userId}&goalId=${goalId}`;
    try {
      const response = await performance.post(url, payload);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// archive goal
export const archiveGoal = createAsyncThunk(
  "archiveGoal",
  async (args: any, { rejectWithValue }) => {
    const { payload } = args;
    const url = `api/v1/goal/archive`;
    try {
      const response = await performance.post(url, payload);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//save Goal comment
export const submitGoal = createAsyncThunk(
  "submitGoal",
  async (args: any, { rejectWithValue }) => {
    const { year } = args;
    const url = `/api/v1/goal/submit?year=${year}`;
    try {
      const response = await performance.post(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//post quarterly checkin data employee
export const postQuarterlyCheckinCommentEmployee = createAsyncThunk(
  "postQuarterlyCheckinCommentEmployee",
  async (args: any, { rejectWithValue }) => {
    const { year, quarter, comment } = args;
    const url = `api/v1/performance-review/quarterlyCheckin?year=${year}&quarter=${quarter}`;
    try {
      const request = { comment: comment };
      const response = await performance.post(url, request);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get Employee List in Manager View
export const getEmployeeListData = createAsyncThunk(
  "getEmployeeListData",
  async (args: any, { rejectWithValue }) => {
    const { year, searchString } = args;
    let url = `api/v1/goal/manager/details?year=${year}`;
    if (searchString) {
      url += `&searchString=${searchString}`;
    }
    //const url = `api/v1/goal/manager/details?year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get Employee goals List in Manager View
export const getEmployeeGoalListData = createAsyncThunk(
  "getEmployeeGoalListData",
  async (args: any, { rejectWithValue }) => {
    const { year, reporteeId } = args;
    let url = `api/v1/goal/manager/goaldetails?year=${year}`;
    if (reporteeId) {
      url += `&reporteeId=${reporteeId}`;
    }
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get all goals template
export const getAllGoalTemplate = createAsyncThunk(
  "getAllGoalTemplate",
  async (_, { rejectWithValue }) => {
    const url = `api/v1/goal/landing-page`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return { result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get all archive goals
export const getAllArchiveGoals = createAsyncThunk(
  "getAllArchiveGoals",
  async (_, { rejectWithValue }) => {
    const url = `api/v1/goal/archive`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return { result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//delete archive goal
export const deleteArchiveGoal = createAsyncThunk(
  "deleteArchiveGoal",
  async ({ goalId = "" }: any, { rejectWithValue }) => {
    const url = `api/v1/goal/archive?goalIds=${goalId}`;
    try {
      const response = await performance.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get all goals template
export const deleteGoal = createAsyncThunk(
  "deleteGoal",
  async (params: any, { rejectWithValue }) => {
    const url = `api/v1/goal`;
    try {
      const response = await performance.delete(url, { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//restore archive goal
export const getRestoreArchiveGoal = createAsyncThunk(
  "getrestoreArchiveGoal",
  async ({ sfGoalIds = "" }: any, { rejectWithValue }) => {
    const url = `api/v1/goal/restore/archive?sfGoalIds=${sfGoalIds}`;
    try {
      const response = await performance.post(url);
      return response?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//post cascade goal by manager
export const postCasecadeGoalCommentManager = createAsyncThunk(
  "postCasecadeGoalCommentManager",
  async (args: any, { rejectWithValue }) => {
    const { year } = args;
    const url = `api/v1/goal/landing-page?year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//cascade to employee from the list
export const cascadeToEmployeeList = createAsyncThunk(
  "cascadeToEmployeeList",
  async (args: any, { rejectWithValue }) => {
    const { year, searchString } = args;
    let url = `api/v1/goal/manager/details?year=${year}`;
    if (searchString) {
      url += `&searchString=${searchString}`;
    }
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Quarterly checkin comment by manager 
export const saveQuarterlyCheckinComments = createAsyncThunk(
  "saveQuarterlyCheckinComments",
  async (args: any, { rejectWithValue }) => {
    const {year, quarter, reporteeId, comment} = args;
    const url = `api/v1/performance-review/manager/quarterlyCheckin?year=${year}&quarter=${quarter}&reporteeId=${reporteeId}`;
    try {
      const request = { comment: comment };
      const response = await performance.post(url, request);
      const result = response.data || {};
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Quarterly checkin comment details by manager 
export const getQuarterlyCheckinComments = createAsyncThunk(
  "getQuarterlyCheckinComments",
  async (args: any, { rejectWithValue }) => {
    const {year, reporteeId} = args;
    const url = `api/v1/performance-review/manager/quarterly-checkin?year=${year}&reporteeId=${reporteeId}`;
    try {
      const response = await performance.get(url);
      const result = response.data || {};
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//contribute to employee from the list
export const getContributePublicGoalList = createAsyncThunk(
  "getContributePublicGoalList",
  async (args: any, { rejectWithValue }) => {
    const {searchString} = args;
    let url = `api/v1/goal/contribute/search-public-goal`;
    if(searchString){
      url+= `?searchString=${searchString}`
    }
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//contribute to employee from the list
export const getContributeViewGoal = createAsyncThunk(
  "getContributeViewGoal",
  async (args: any, { rejectWithValue }) => {
    const { questionnaireCategory, publicGoalId } = args;
    const url = `api/v1/goal/template?userId=11397&questionnaireCategory=${questionnaireCategory}&year=2024&publicGoalId=${publicGoalId}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return { result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
