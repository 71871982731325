import "./index.css";
import { useState } from "react";
import { Alert, Button, Dropdown, Menu, Space, Table } from "antd";
import n from "../../navigation/Route.Names";
import { Link, useNavigate } from "react-router-dom";
import {
  EDIT_GOAL,
  DELETE_GOAL,
  VIEW_GOAL,
  MOVE_ARCHIVE_GOAL,
  LANDING_COMMENT_TITLE,
  MILESTONE_DESCRIPTION_LABEL,
  ACTUAL_DATE_LABEL,
  DUE_DATE_LABEL,
  LISTING_HEADER_NAME_DESC,
  LISTING_HEADER_START_DATE,
  LISTING_HEADER_END_DATE,
  LISTING_HEADER_OUTCOMES,
  LISTING_HEADER_WEIGHTAGE,
  LISTING_HEADER_STATUS,
  LISTING_HEADER_ACTIONS,
  CONFIRM_DELETE_MSG,
  CONFIRM_DELETE_LABEL,
  DELETE,
  CANCEL,
  ARCHIVE_GOAL,
  ARCHIVE_GOAL_DESC,
  ARCHIVE,
} from "../../../constants";
import ModalDialog from "../../common/modal/modal";
import { setGoalId, setGoalData } from "../../../redux/reducers/performance";
import { useAppDispatch } from "../../../redux/store/redux-hooks";
import { archiveGoal, deleteGoal } from "../../../redux/actions/performance";
import ExpandLess from "../../../themes/assets/images/svg/Expand_less.svg";
import ExpandMore from "../../../themes/assets/images/svg/Expand_more-black.svg";
import CommentIcon from "../../../themes/assets/images/performance/svg/ChatCircleWeb.svg";
import Notification from "../../../themes/assets/images/performance/svg/notificationdot.svg";
import MenuIcon from "../../../themes/assets/images/svg/menu-icon.svg";
import DeleteGoalImage from "../../../themes/assets/images/performance/svg/DeleteGoal.svg";
import ArchiveGoalImage from "../../../themes/assets/images/performance/svg/ArchiveGoal.svg";

const GoalTable = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);

  const [selectedGoal, setSelectedGoal] = useState<any>(null);

  const showDeleteModal = (goal) => {
    setSelectedGoal(goal);
    setDeleteModalVisible(true);
  };

  const showArhiveModal = (goal) => {
    setSelectedGoal(goal);
    setArchiveModalVisible(true);
  };

  const hideModal = () => {
    setSelectedGoal(null);
    setDeleteModalVisible(false);
    setArchiveModalVisible(false);
  };

  const handleDeleteGoal = () => {
    const queryParams = { year: selectedGoal?.year || 2024 };
    if (selectedGoal?.goalId) {
      queryParams["goalId"] = selectedGoal?.goalId;
    }
    if (selectedGoal?.sfGoalId) {
      queryParams["sfGoalId"] = selectedGoal?.sfGoalId;
    }
    dispatch(deleteGoal(queryParams));
    hideModal();
  };

  const handleArchiveGoal = () => {
    dispatch(archiveGoal({ payload: selectedGoal }));
    hideModal();
  };

  const renderStatusButton = (goalStatus, record) => {
    const { goalStatusColor } = record || {};
    return (
      <Button
        type="primary"
        style={{ backgroundColor: goalStatusColor }}
        className={goalStatus}
      >
        {goalStatus}
      </Button>
    );
  };

  const goalView = (goal) => {
    navigate(`/${n.PERFORMANCE_URL}/previewgoal`, {
      state: {
        data: goal,
        isPreview: true,
      },
    });
    dispatch(setGoalData(goal));
  };

  const editGoal = (goal) => {
    navigate(`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}`, {
      state: {
        data: goal,
        isValid: true,
        isEdit: true,
        isPreview: true,
      },
    });
    dispatch(setGoalData(goal));
    dispatch(setGoalId(goal.goalId));
  };

  const renderActionsMenu = (goal) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => editGoal(goal)}>
        {EDIT_GOAL}
      </Menu.Item>
      <Menu.Item key="view" onClick={() => goalView(goal)}>
        {VIEW_GOAL}
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => showDeleteModal(goal)}>
        {DELETE_GOAL}
      </Menu.Item>
      <Menu.Item key="archeive" onClick={() => showArhiveModal(goal)}>
        {MOVE_ARCHIVE_GOAL}
      </Menu.Item>
    </Menu>
  );

  const renderActionsDropdown = (goal) => (
    <Dropdown
      overlayClassName="goals"
      overlay={() => renderActionsMenu(goal)}
      trigger={["click"]}
    >
      <img src={MenuIcon} className="listing-dots-icos" alt="MenuIcon" />
    </Dropdown>
  );
  const getMilestoneColumns = () => [
    {
      title: MILESTONE_DESCRIPTION_LABEL,
      dataIndex: "description",
      key: "description",
    },
    {
      title: DUE_DATE_LABEL,
      dataIndex: "formattedDueDate",
      key: "formattedDueDate",
    },
    {
      title: ACTUAL_DATE_LABEL,
      dataIndex: "formattedActualDate",
      key: "formattedActualDate",
    },
    {
      title: "Status",
      dataIndex: "milestoneStatus",
      key: "milestoneStatus",
      render: (record) => {
        const { goalStatus, text } = record;
        return goalStatus !== null ? (
          <p
            className={
              record === "Behind"
                ? "Behind-bg"
                : record === "Completed"
                ? "Completed-bg"
                : record === "On Track"
                ? "OnTrack-bg"
                : ""
            }
          >
            {record === "Behind"
              ? "Behind"
              : record === "Completed"
              ? "Completed"
              : record === "On Track"
              ? "On Track"
              : ""}
          </p>
        ) : null;
      },
    },
    {
      title: "",
      dataIndex: "operation",
      key: "operation",
      render: () => (
        <Space size="middle">
          <Link to={`/${n.PERFORMANCE_URL}/${n.MILESTONE_URL}`}>Edit</Link>
        </Space>
      ),
    },
  ];

  const columns = [
    {
      title: LISTING_HEADER_NAME_DESC,
      key: "goalName",
      className: "goal-name-desc",
      render: (record) => (
        <>
          <div className={record.cascadeOpen ? "cascaded-by-area" : ""}>
            {record.cascadeOpen ? `Cascaded by ${record.cascadedBy}` : ""}
          </div>
          <div className="goal-name-heading">{record.goalName}</div>
          <div className="goal-desc-heading">{record.goalDescription}</div>
          {record.commentInfo?.comment ? (
            <div className="notificationdisplay">
              <div className="icon">
                <img
                  className="commentimage"
                  src={CommentIcon}
                  alt="CommentIcon"
                />
                {record.commentInfo?.status !== "READ" && (
                  <img
                    className="imagenotification"
                    src={Notification}
                    alt="NotificationIcon"
                  />
                )}
              </div>
              <button className="commentsText" onClick={() => goalView(record)}>
                {LANDING_COMMENT_TITLE}
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: LISTING_HEADER_START_DATE,
      dataIndex: "startDate",
      key: "startDate",
    },
    { title: LISTING_HEADER_END_DATE, dataIndex: "endDate", key: "endDate" },
    { title: LISTING_HEADER_OUTCOMES, dataIndex: "outcome", key: "outcome" },
    {
      title: LISTING_HEADER_WEIGHTAGE,
      dataIndex: "weight",
      key: "weight",
      render: (weight) => <div>{weight}%</div>,
    },
    {
      title: LISTING_HEADER_STATUS,
      dataIndex: "goalStatus",
      render: (record, item) => renderStatusButton(record, item),
    },
    {
      title: LISTING_HEADER_ACTIONS,
      key: "actions",
      render: renderActionsDropdown,
    },
  ];

  const onExpand = (expanded, record) => {
    const keys: any = expanded ? [record.goalId] : [];
    setExpandedRowKeys(keys);
  };

  const renderExpandedRow = (record) => (
    <Table
      columns={getMilestoneColumns()}
      dataSource={record.milestones}
      pagination={false}
      className="tbl-inner-section"
    />
  );

  const expandIcon = ({ expanded, onExpand, record }) =>
    expanded ? (
      <img
        src={ExpandLess}
        onClick={() => onExpand(record)}
        className="expand"
        alt="expand"
      />
    ) : (
      <img
        src={ExpandMore}
        onClick={() => onExpand(record)}
        className="expand"
        alt="expand"
      />
    );

  return (
    <>
      <div className="main-table">
        <Table
          dataSource={data}
          columns={columns}
          className="tbl-outer-section cascaded-table tr"
          expandable={{
            expandedRowRender: renderExpandedRow,
            onExpand: onExpand,
            expandedRowKeys: expandedRowKeys,
            expandIcon: expandIcon,
          }}
          rowKey="goalId"
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                // goalView(record);
              },
            };
          }}
        />
      </div>
      <ModalDialog
        openModal={deleteModalVisible || archiveModalVisible}
        beforeImageText={archiveModalVisible ? ARCHIVE_GOAL : ""}
        DialogHeading={deleteModalVisible ? CONFIRM_DELETE_LABEL : ARCHIVE_GOAL}
        closeIcon={false}
        indexScroll={{ display: "none" }}
        modalHeadingStyles={{ dislay: "none" }}
        popupDivider={{ display: "none" }}
        visible={deleteModalVisible || archiveModalVisible}
        msgContent={
          <p>{deleteModalVisible ? CONFIRM_DELETE_MSG : ARCHIVE_GOAL_DESC}?</p>
        }
        okTxt={deleteModalVisible ? DELETE : ARCHIVE}
        cancelTxt={CANCEL}
        cancelBtn={hideModal}
        okBtn={deleteModalVisible ? handleDeleteGoal : handleArchiveGoal}
        onOk={deleteModalVisible ? handleDeleteGoal : handleArchiveGoal}
        onCancel={hideModal}
        modalSubHeading={"modalSubHeading"}
        modalStyles={`backpopup ${
          deleteModalVisible ? "delete-milestone-popup" : ""
        }`}
        modalContent="archive-goal-sub-title"
        maskClosable={false}
        modalImageClasses={
          deleteModalVisible ? "delete-goal" : "archive-goal-popup"
        }
        modalImage={deleteModalVisible ? DeleteGoalImage : ArchiveGoalImage}
      />
    </>
  );
};

export default GoalTable;
