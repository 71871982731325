import { useEffect } from "react";
import "./contribute.css";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import FillButton from "../../common/button/FillButton";
import OutlineButton from "../../common/button/OutlineButton";
import n from "../../navigation/Route.Names";
import AccessTime from "../../../themes/assets/images/svg/front/active_access_time.svg";
import BusinessMilestone from "../../../themes/assets/images/svg/BusinessMilestone.svg";
import {
  GOAL_TITLE,
  GOAL_SUBTITLE,
  EDIT_GOAL,
  CANCEL,
  END_DATE,
} from "../../../constants";
import { useState } from "react";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import ModalDialog from "../../common/modal/modal";
import TextArea from "antd/es/input/TextArea";
import { Input } from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/store/redux-hooks";
import { getContributeViewGoal } from "../../../redux/actions/performance";
import { forEach } from "lodash";

const ContributeViewGoal = () => {
  const param = useParams();

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getContributeViewGoal({ 
      userId: param?.userId,
      year: param?.year,
      questionnaireCategory: "web_goal",
      publicGoalId: param?.publicGoalId,
    }));
  }, [ param?.year, param?.publicGoalId]);

  const contributeViewGoalData =
  useAppSelector(
    (state: any) => state?.performance?.contributeViewGoalList
  ) || [];
  let viewGoalQuestion:any[] = []

   contributeViewGoalData?.sectionList?.forEach((item:any,index:any) => {
    item.forEach ((item:any,index:any) => {
      viewGoalQuestion.push ({question:item?.questionDTO?.question, answer:item?.questionDTO?.answer})
    })
  })
  const endDateItem = viewGoalQuestion.find(
    (index,item:any) => index.question === "End date" && index.answer !== item.answer
  );


  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isEmptyMilestone, setEmptyMilestone] = useState(false);
  const fillBtnAction = (value: object) => {
    navigate(`${n.CONTRIBUTE_EDIT_GOAL_URL}`);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const back = () => {
    navigate(`/${n.PERFORMANCE_URL}/${n.CHALLENG_GOAL_URL}`);
  };

  const items = [
    {
      title: (
        <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
          Perfomance
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.PERFORMANCE_URL}/${n.CHALLENG_GOAL_URL}`}
          className="link-highlight"
        >
          Contribute Goal
        </Link>
      ),
    },
    {
      title: "View Goal",
    },
  ];

  

  return (
    <div className="contribute_view-otr">
      <div className="container">
        <div className="breadcrumb_preview">
          <BreadcrumbComponent
            items={items}
          />
        </div>
        <div className="goal-preview-header">
          <div className="goal-preview-title">
            <h3 className="preview-main-title">View Goal</h3>
          </div>
        </div>
        <div className="preview-goal-main">
          <div className="view-status-row">
            <div className="row-section">
              {viewGoalQuestion?.length
                ? viewGoalQuestion.map(
                    (item: any) =>
                      !["Milestones", "Make private", "Status", "End date"].includes (
                        item.question
                      ) && (
                        <div className="row-section">
                          {item.question === "Start date" ? (
                            <div>
                              <h5>
                                {item.question} and {endDateItem.question}
                              </h5>
                              <p>
                                {item.answer || "-"} - {endDateItem.answer || "-"}
                              </p>
                            </div>
                          ) : (
                            <div>
                              <h5>{item.question}</h5>
                              <p>{item.answer || "-"} </p>
                            </div>
                          )}
                        </div>
                      )
                  )
                : ""}
            </div>
          </div>
          <div className="milestone-section">
            <div className="milestone-header-section">
              <div className="milestone-header-left">
                <h5>
                  {GOAL_TITLE}
                  <span className="requird-star">*</span>
                </h5>
                <span>{GOAL_SUBTITLE}</span>
              </div>
            </div>
            {isEmptyMilestone ? (
              <div className="milestone-body-section">
                <div className="milestone-body-header">
                  <img src={AccessTime} alt="" />
                  <h5>Milestones 1</h5>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500. Lorem Ipsum has been
                  the industry's standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled
                </p>
                <div className="milestone-body-dates">
                  <span className="milestone-start-date">
                    Target Date : 31/02/2023
                  </span>
                  <span>Actual Date : 31/02/2023</span>
                </div>
              </div>
            ) : (
              <div className="empty-milestone">
                <img src={BusinessMilestone} alt="" />
                <span>
                  No milestone has been added yet. To add milestones, click on
                  the button.
                </span>
              </div>
            )}
          </div>
          <div className="button-area">
            <OutlineButton
              outlineBtnStyles="outlineBtnStyles"
              outlineBtnAction={back}
              backText={CANCEL}
            />
            <FillButton
              fillBtnStyles="fillBtnStyles"
              fillBtnTxt={EDIT_GOAL}
              fillBtnAction={fillBtnAction}
            />
          </div>
        </div>
      </div>
      <ModalDialog />
    </div>
  );
};

export default ContributeViewGoal;
