import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import n from "../navigation/Route.Names";
import "./PrivateLayout.css";
import MyWork from "../MyWork/my_work";
import Loading from "../common/loading/loading";
import ViewGoal from "../Performance/ViewGoal";
import PerformancePreview from "../Performance/Preview";
import SepationMainScreen from "../separation/Separation.Main";
import Clearance from "../separation/clearance-detail/Clearance";
import Certification from "../certifications/Certification";
import FinalHolidayCalendar from "../HolidayCalendar";
import AddCertification from "../certification_form/AddCertification";
import EditCertification from "../certification_form/EditCertification";
import EmployeeDetailPerformance from "../Performance/ManagerView/EmployeeDetails";
import Preview from "../certifications/Preview";
import "./Header.css";
import AsopMainPage from "../Asop";
import EbookIntroduction from "../EBooks/EbookIntroduction";
import EBookDetail from "../EBooks/EbookDetail";
import TeamPerformance from "../Performance/ManagerView";
import SmartGoals from "../Performance/SmartGoals";
import ChallengingGoal from "../Performance/contributeGoals";
import ContributeViewGoal from "../Performance/contributeGoals/contributeViewGoal";
import ContributeEditGoal from "../Performance/contributeGoals/contributeEditGoal";
import GoalEvaluation from "../Performance/ASPForm/GoalEvaluation";
import CompotencyEvaluation from "../Performance/ASPForm/CompotencyEvaluation";
import ManagerGoalEvaluation from "../Performance/ManagerView/EmployeeDetails/ASPForm/ManagerGoalEvaluation";
import ManagerCompotencyEvaluation from "../Performance/ManagerView/EmployeeDetails/ASPForm/ManagerCompotencyEvaluation";
import PotentialRating from "../Performance/ManagerView/EmployeeDetails/ASPForm/PotentialRating";
import CascadingEditGoal from "../Performance/CascadingGoals/CascadingEditGoals";
import CascadingViewGoal from "../Performance/CascadingGoals/CascadingViewGoal";
import Faq from "../Asop/Faq";
import UserGuide from "../Asop/UserGuide";
import HolidayCalendar from "../HolidayCalendar";
import GamificationProfile from "../gamification/components/LeaderBoard/LeaderBoardLanding/Profile";
import LockedBadges from "../gamification/components/LeaderBoard/Badges/LockedBadges";
import LockedBadgesDetail from "../gamification/components/LeaderBoard/Badges/LockedBadgesDetail";
import PointsDashboard from "../gamification/components/Points/PointsDashboard/PointsDashboard";
import amplitude from "amplitude-js";
import { useLocation } from "react-router-dom";

import CascadeGoals from "../Performance/ManagerView/EmployeeDetails/CascadeGoals";
import ActiveIconDetail from "../gamification/components/LeaderBoard/Badges/ActiveIconDetail";
import {
  BADGES_URL,
  BARTLE_TEST_URL,
  EARNED_BADGES,
  GAMIFICATION_URL,
  HOLIDAY_CALENDAR_URL,
  LEADERBOARD_URL,
  LOCKED_BADGES,
  POINTS_DASHBOARD_URL,
  START,
} from "../../constants";
import LeaveManagement from "../leave-management";
import {
  ADD_CERTIFICATION_URL,
  CERTIFICATION_URL,
  EDIT_CERTIFICATION_URL,
} from "../../constants";
import SmartGoalPreview from "../Performance/SmartGoalsPreview";

const ArchivedGoal = lazy(() => import("../Performance/ArchivedGoal/index"));
const RootLayout = lazy(() => import("../altiverse/main/main"));
const NoMatch = lazy(() => import("../common/noMatch/noMatch"));
const Home = lazy(() => import("../home/home"));
const Detail = lazy(() => import("../Detail"));
const SuccessStoryDetail = lazy(() => import("../Detail/successStoryDetail"));
const MySpace = lazy(() => import("../my_space/my_space"));
const Discover = lazy(() => import("../DiscoverDex"));
const PolicyDetail = lazy(() => import("../PolicyHub/PolicyDetail"));
const AltimetrikPhysicalAccessManagementPolicy = lazy(
  () =>
    import("../PolicyHub/StaticFiles/AltimetrikPhysicalAccessManagementPolicy")
);
const PolicyHub = lazy(() => import("../../components/PolicyHub/index"));

const Performance = lazy(() => import("../Performance"));
const NewGoal = lazy(() => import("../Performance/NewGoal"));
const NewMilestone = lazy(() => import("../Performance/NewMilestone"));
const ViewAspForm = lazy(() => import("../Performance/ASPForm/FormView/index"));
const BartleTest = lazy(() => import("../bartle_test/index"));
const GamificationHomePage = lazy(() => import("../gamification/index"));

if (
  process.env.REACT_APP_DEX_WEB_DEV_URL ===
  "https://dex-api.altimetrik.com/dexapi/"
) {
  amplitude.getInstance().init("cee88f1d55c077089d009ad11479e704");
} else {
  amplitude.getInstance().init("f9ebc2c690959d6c91bfaeaeaf097624");
}

function PrivateLayout() {
  return (
    <Routes>
      <Route
        path={`/${n.START}/`}
        element={
          <Suspense fallback={<Loading />}>
            <RootLayout />
          </Suspense>
        }
      >
        <Route
          index
          path=""
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={`${CERTIFICATION_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <Certification />
            </Suspense>
          }
        />
        <Route
          path={`${HOLIDAY_CALENDAR_URL}`}
          element={<FinalHolidayCalendar />}
        />
        <Route
          path={`${ADD_CERTIFICATION_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <AddCertification />
            </Suspense>
          }
        />
        <Route
          path={`${EDIT_CERTIFICATION_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <EditCertification />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}`}
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/:page`}
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/:contentType/detail/:id`}
          element={
            <Suspense fallback={<Loading />}>
              <Detail />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/detail/:id`}
          element={
            <Suspense fallback={<Loading />}>
              <Detail />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/successStorydetail`}
          element={
            <Suspense fallback={<Loading />}>
              <SuccessStoryDetail />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/:page/INTRODUCTION/:code`}
          element={
            <Suspense fallback={<Loading />}>
              <EbookIntroduction />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/:page/DETAILS/:code`}
          element={
            <Suspense fallback={<Loading />}>
              <EBookDetail />
            </Suspense>
          }
        />
        <Route
          path={n.MYWORK_URL}
          element={
            <Suspense fallback={<Loading />}>
              <MyWork />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/:page`}
          element={
            <Suspense fallback={<Loading />}>
              <MyWork />
            </Suspense>
          }
        />
        <Route
          path="discover"
          element={
            <Suspense fallback={<Loading />}>
              <Discover />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}`}
          element={
            <Suspense fallback={<Loading />}>
              <MySpace />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/:page`}
          element={
            <Suspense fallback={<Loading />}>
              <MySpace />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/policy_hub/:name`}
          element={
            <Suspense fallback={<Loading />}>
              <PolicyDetail />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/${n.HOLIDAY_CALENDAR_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <HolidayCalendar />
            </Suspense>
          }
        />
        {/* <Route
          path={`${n.MY_SPACE}/ASOP`}
          element={
            <Suspense fallback={<Loading />}>
              <AsopMainPage />
            </Suspense>
          }
        /> */}
        <Route
          path={`${n.MY_SPACE}/ASOP/Faq`}
          element={
            <Suspense fallback={<Loading />}>
              <Faq />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/ASOP/userGuide`}
          element={
            <Suspense fallback={<Loading />}>
              <UserGuide />
            </Suspense>
          }
        />
        {/*<Route path={n.MY_SPACE_CONTENTS} element={<Suspense fallback={<Loading/>}><Content/></Suspense>} />
            <Route path={n.My_SPACE_EDITPOP} element={<Suspense fallback={<Loading/>}><EditPopUp/></Suspense>} />
            <Route path={`/${n.TABLE_DATA}`} element={<Survey />} />*/}
        <Route
          path={`${n.MY_SPACE}`}
          element={
            <Suspense fallback={<Loading />}>
              <NoMatch isCode={false} message="Mywork Page" />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/leave`}
          element={
            <Suspense fallback={<Loading />}>
              <LeaveManagement />
            </Suspense>
          }
        />
        <Route
          path={`performance`}
          element={
            <Suspense fallback={<Loading />}>
              <Performance />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Policy_Hub/`}
          element={
            <Suspense fallback={<Loading />}>
              <PolicyHub />
            </Suspense>
          }
        />

        <Route
          path={`${n.MYWORK_URL}/performance/newgoal`}
          element={
            <Suspense fallback={<Loading />}>
              <NewGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/performance/milestone`}
          element={
            <Suspense fallback={<Loading />}>
              <NewMilestone />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Performance/smartgoal`}
          element={
            <Suspense fallback={<Loading />}>
              <SmartGoals />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Performance/goalEvaluation`}
          element={
            <Suspense fallback={<Loading />}>
              <GoalEvaluation />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Performance/compotencyevaluation`}
          element={
            <Suspense fallback={<Loading />}>
              <CompotencyEvaluation />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Performance/view_asp`}
          element={
            <Suspense fallback={<Loading />}>
              <ViewAspForm />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Performance/ChallengingGoal`}
          element={
            <Suspense fallback={<Loading />}>
              <ChallengingGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Performance/CascadingEditGoal`}
          element={
            <Suspense fallback={<Loading />}>
              <CascadingEditGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Performance/CascadingViewGoal`}
          element={
            <Suspense fallback={<Loading />}>
              <CascadingViewGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Performance/ContributeViewGoal/:publicGoalId`}
          element={
            <Suspense fallback={<Loading />}>
              <ContributeViewGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Performance/ContributeViewGoal/:publicGoalId/ContributeEditGoal`}
          element={
            <Suspense fallback={<Loading />}>
              <ContributeEditGoal />
            </Suspense>
          }
        />
        <Route
          path={`newgoal`}
          element={
            <Suspense fallback={<Loading />}>
              <NewGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/performance/smartgoal/${n.SMART_GOALS_PREVIEW_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <ViewGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/performance/previewgoal`}
          element={
            <Suspense fallback={<Loading />}>
              <PerformancePreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/performance/ViewGoal`}
          element={
            <Suspense fallback={<Loading />}>
              <SmartGoalPreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/detail/AltimetrikPhysicalAccessManagementPolicy`}
          element={
            <Suspense fallback={<Loading />}>
              <AltimetrikPhysicalAccessManagementPolicy />
            </Suspense>
          }
        />
        <Route
          path={`${LEADERBOARD_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <GamificationProfile />
            </Suspense>
          }
        />
        <Route
          path={`${BADGES_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <LockedBadges />
            </Suspense>
          }
        />
        <Route
          path={LOCKED_BADGES}
          element={
            <Suspense fallback={<Loading />}>
              <LockedBadgesDetail />
            </Suspense>
          }
        />
        <Route
          path={POINTS_DASHBOARD_URL}
          element={
            <Suspense fallback={<Loading />}>
              <PointsDashboard />
            </Suspense>
          }
        />
        <Route
          path={EARNED_BADGES}
          element={
            <Suspense fallback={<Loading />}>
              <ActiveIconDetail />
            </Suspense>
          }
        />
        <Route
          path={`team_performance`}
          element={
            <Suspense fallback={<Loading />}>
              <TeamPerformance />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/team_performance/manager_goal_evaluation`}
          element={
            <Suspense fallback={<Loading />}>
              <ManagerGoalEvaluation />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/team_performance/manager_compotency_evaluation`}
          element={
            <Suspense fallback={<Loading />}>
              <ManagerCompotencyEvaluation />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/team_performance/potential_rating`}
          element={
            <Suspense fallback={<Loading />}>
              <PotentialRating />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/team_performance/cascadegoals`}
          element={
            <Suspense fallback={<Loading />}>
              <CascadeGoals />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/performance/${n?.ARCHIVED_GOAL_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <ArchivedGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/:page/detail/:reporteeId`}
          element={
            <Suspense fallback={<Loading />}>
              <EmployeeDetailPerformance />
            </Suspense>
          }
        />
        <Route path={`${n.SEPARATION_DETAIL_URL}`} element={<Clearance />} />
        <Route path={`${n.CERTIFICATION_PREVIEW_URL}`} element={<Preview />} />
        <Route
          path={`${n.ADD_CERTIFICATION_URL}`}
          element={<AddCertification />}
        />
        <Route
          path={`${n.EDIT_CERTIFICATION_URL}`}
          element={<EditCertification />}
        />

        {/* <Route path={`${n.SEPARATION_URL}`} element={<SepationMainScreen />}>
          <Route path={`${n.CERTIFICATION_URL}`} element={<Certification />} />
          <Route
            path={`${n.CERTIFICATION_PREVIEW_URL}`}
            element={<Preview />}
          />
          <Route
            path={`${n.ADD_CERTIFICATION_URL}`}
            element={<AddCertification />}
          />
          <Route
            path={`${n.EDIT_CERTIFICATION_URL}`}
            element={<EditCertification />}
          />
          <Route path={`${n.LOGOUT_URL}`} element={<></>} />
          <Route path="*" element={<NoMatch />} />
        </Route> */}
        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <NoMatch isCode={true} message="Oops! Page Not Found" />
            </Suspense>
          }
        />
        <Route
          index
          path={`${BARTLE_TEST_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <BartleTest />
            </Suspense>
          }
        />
        <Route
          index
          path={`${GAMIFICATION_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <GamificationHomePage />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default PrivateLayout;
