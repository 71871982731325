import "./CommentCard.css";
import AVATARIMG from "../../../themes/assets/images/performance/avatar-emp.jpg";
import { Rate } from "antd";
import "./ManagerRatingCard.css";

const ManagerRatingCard = (props: any) => {
  const style = props?.large
    ? "commentCard-checkin "
    : "commentCard-checkin medium";

  return (
    <div className={style}>
      <div className="manager-comment">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor inci labor dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor inci labor dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </div>
      <p>
        <Rate className={"custom-rating"} allowHalf defaultValue={0} />
      </p>
      <div className="profile-checkin">
        <img src={AVATARIMG} alt="" className="profilepic-checkin" />
        <div className="userData-checkin">
          <div className="name-checkin">Shashank Shekhar</div>
          <div className="designation-checkin">
            Senior Manager - Product and Industry Practice
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerRatingCard;
