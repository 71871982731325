import React from "react";
import { useNavigate } from "react-router-dom";
import n from "../../navigation/Route.Names";
import OutlineButton from "../../common/button/OutlineButton";

const Overview = () => {
    const navigate = useNavigate();
    return (
        <div>
            <OutlineButton outlineBtnAction={() => { navigate(`/${n.PERFORMANCE_URL}/${n.CHALLENG_GOAL_URL}`); }} backText="Contribute Goals" />
        </div>
    )
}
export default Overview;