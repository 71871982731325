import { useNavigate, useLocation } from "react-router-dom";
import "../NewGoal.css";
import "../Milestones.css";
import "../ViewGoal.css";
import BusinessMilestone from "../../../themes/assets/images/svg/BusinessMilestone.svg";
import FillButton from "../../common/button/FillButton";
import { useSelector } from "react-redux";
import n from "../../navigation/Route.Names";
import { isEmpty } from "lodash";
import AddedMilestones from "../Milestones";

const GoalMilestoneCompoenent = (props: any) => {
  const {
    data,
    webMilestones,
    questionDTO,
    webMilestoneQuestionList,
    disableMilestone,
    isPreview,
    isEdit,
    newGoalValues,
  } = props;
  const { question, questionProperties } = questionDTO || {};
  const { isValidated } = useSelector((state: any) => state.newGoal);
  const navigate = useNavigate();
  const location = useLocation();
  const isValid = location?.state?.isValid;
  const fillBtnAction = (value: object) => {
    navigate(`/${n.PERFORMANCE_URL}/${n.MILESTONE_URL}`, {
      state: { isPreview, isEdit, data: !isEmpty(data) ? data : newGoalValues },
    });
  };
  return (
    <>
      {isEmpty(webMilestones) ? (
        <div className="milestone-body-section milestone-section">
          <div className="milestone-body-header">
            {/* <img src={AccessTime} alt="" /> */}
            <h5>{question}</h5>
          </div>
          <p>{questionProperties?.fileProperties?.placeHolder1 || ""}</p>
          <div className="empty-milestone milestone-section">
            <img src={BusinessMilestone} alt="" />
            <span className="no-milestone-caption">
              {questionProperties?.fileProperties?.placeHolder2 || ""}
            </span>
            <FillButton
              fillBtnTxt={questionProperties?.fileProperties?.buttonName || ""}
              fillBtnAction={fillBtnAction}
              disabled={disableMilestone}
            />
          </div>
        </div>
      ) : (
        <AddedMilestones
          milestones={webMilestones}
          webMilestoneQuestionList={webMilestoneQuestionList}
          {...props}
        />
      )}
    </>
  );
};

export default GoalMilestoneCompoenent;
