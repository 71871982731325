import { useNavigate } from "react-router-dom";
import "./ViewGoal.css";
import { Card } from "antd";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import OutlineButton from "../common/button/OutlineButton";
import AccessTime from "../../themes/assets/images/svg/front/active_access_time.svg";
import Success from "../../themes/assets/images/svg/front/Success.svg";
import AccessTimeGrey from "../../themes/assets/images/svg/front/access_time.svg";
import FillButton from "../common/button/FillButton";
import { ADD_GOAL, CANCEL } from "../../constants";
import n from "../navigation/Route.Names";

const ViewGoal = () => {
  const navigate = useNavigate();

  const fillBtnAction = (value: object) => {
    navigate(`/${n.START}/${n.MILESTONE_URL}`);
  };
  const cancelBtn = (value: object) => {
    navigate(`/${n.START}/${n.MILESTONE_URL}`);
  };

  return (
    <>
      <div className="view-goal">
        <Card style={{ width: "100%" }}>
          <div className="view-status-row">
            <div className="row-section">
              <h5>Category</h5>
              <p>Business Category</p>
            </div>
            <div className="status-section">
              <p>On Track</p>
            </div>
          </div>
          <div className="row-section">
            <h5>Goal Name</h5>
            <p>Sprint Quality Management</p>
          </div>
          <div className="row-section">
            <h5>Goal Description</h5>
            <p>
              Achieve high sprint velocity and on-time product releases so that
              strategic timelines are met.Ensure best practices followed within
              the team to produce high quality product and features
            </p>
          </div>
          <div className="row-section">
            <h5>Outcome</h5>
            <p>Delivery Excellence and Innovation</p>
          </div>
          <div className="row-section">
            <h5>Metric</h5>
            <p>Business Category</p>
          </div>
          <div className="row-section">
            <h5>Weight</h5>
            <p>25%</p>
          </div>
          <div className="row-section">
            <h5>Visibility</h5>
            <p>Private</p>
          </div>
          <div className="row-section">
            <h5>Start and End Date</h5>
            <p>01/02/2022 - 12/02/2023</p>
          </div>
          <div className="milestone-section">
            <div className="milestone-header-section">
              <div className="milestone-header-left">
                <h5 className="milestone-title">
                  Milestones<span>*</span>
                </h5>
                <span>Break your goal into smaller manageable chunks.</span>
              </div>
            </div>
            <div className="milestone-body-section">
              <div className="milestone-body-header">
                <img src={Success} alt="" />
                <h5>Milestones 1</h5>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled
              </p>
              <div className="milestone-body-dates">
                <span className="milestone-start-date">
                  Target Date : 31/02/2023
                </span>
                <span>Actual Date : 31/02/2023</span>
              </div>
            </div>
            <div className="milestone-body-section">
              <div className="milestone-body-header">
                <img src={AccessTime} alt="" />
                <h5>Milestones 1</h5>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled
              </p>
              <div className="milestone-body-dates">
                <span className="milestone-start-date">
                  Target Date : 31/02/2023
                </span>
                <span>Actual Date : 31/02/2023</span>
              </div>
            </div>
            <div className="milestone-body-section">
              <div className="milestone-body-header">
                <img src={Success} />
                <h5>Milestones 1</h5>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled
              </p>
              <div className="milestone-body-dates">
                <span className="milestone-start-date">
                  Target Date : 31/02/2023
                </span>
                <span>Actual Date : 31/02/2023</span>
              </div>
            </div>
            <div className="milestone-body-section">
              <div className="milestone-body-header">
                <img src={AccessTimeGrey} />
                <h5>Milestones 1</h5>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled
              </p>
              <div className="milestone-body-dates">
                <span className="milestone-start-date">
                  Target Date : 31/02/2023
                </span>
                <span>Actual Date : 31/02/2023</span>
              </div>
            </div>
          </div>
          <div className="button-area">
            <OutlineButton
              outlineBtnStyles="outlineBtnStyles"
              backText={CANCEL}
              outlineBtnAction={cancelBtn}
            />
            <FillButton
              fillBtnStyles="fillBtnStyles"
              fillBtnTxt={ADD_GOAL}
              fillBtnAction={fillBtnAction}
            />
          </div>
        </Card>
      </div>
    </>
  );
};
export default ViewGoal;
