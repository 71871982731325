import { useNavigate, useLocation, Link } from "react-router-dom";
import "./contribute.css";
import "../NewGoal.css";
import {
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import OutlineButton from "../../common/button/OutlineButton";
import AccessTime from "../../../themes/assets/images/svg/front/active_access_time.svg";
import BusinessMilestone from "../../../themes/assets/images/svg/BusinessMilestone.svg";
import { useEffect, useState } from "react";
import FillButton from "../../common/button/FillButton";
import {
  CANCEL,
  PREVIEW,
  SAVE,
  GOAL_TITLE,
  GOAL_SUBTITLE,
  NEWGOAL_CHECKBOX,
} from "../../../constants";
import n from "../../navigation/Route.Names";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/store/redux-hooks";
import { setIsValidated } from "../../../redux/reducers/new_goal";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import tempMyWorkData from "../../MyWork/MyworkSubheaderData";
import { viewState } from "../../../redux/reducers/performance";

const { Option } = Select;
const ContributeEditGoal = (props: any) => {
  const [isEmptyMilestone, setEmptyMilestone] = useState(false);
  const { isValidated } = useSelector((state: any) => state.newGoal);
  const [goalName, setGoalName] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const location = useLocation();
  const isValid = location?.state?.isValid;
  const currentGoal = location.state?.data || [];

  const onFinish = (value: object) => {
    console.log(value);
  };

  const fillBtnAction = (value: object) => {
    navigate(`/${n.PERFORMANCE_URL}/${n.MILESTONE_URL}`);
  };
  const back = () => {
    navigate(`/${n.PERFORMANCE_URL}/${n.CONTRIBUTE_VIEW_GOAL_URL}`);
  };

  useEffect(() => {
    if (
      values?.category &&
      values?.description &&
      values?.endDate &&
      values?.name &&
      values?.metrics &&
      values?.outcome &&
      values?.startDate &&
      values?.weight
    ) {
      dispatch(setIsValidated(true));
    } else {
      dispatch(setIsValidated(false));
    }
  }, [values]);

  const preview = (value: object) => {
    navigate(`/${n.PERFORMANCE_URL}`, { state: { performanceTabKey: "2" } });
    dispatch(viewState(true));
  };

  //const listTabs = tempMyWorkData?.webIconInfos?.navigationTab || [];
  const items = [
    {
      title: (
        <Link to={`${n.PERFORMANCE_URL}`} className="link-highlight">
          Perfomance
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.PERFORMANCE_URL}/${n.CHALLENG_GOAL_URL}`}
          className="link-highlight"
        >
          Contribute Goal
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.PERFORMANCE_URL}/${n.CONTRIBUTE_VIEW_GOAL_URL}`}
          className="link-highlight"
        >
          View Goal
        </Link>
      ),
    },
    {
      title: "Edit Goal",
    },
  ];

  return (
    <div className="new-goal contribute_edit-goal">
      <div className="breadcrumb_newgoal">
        <BreadcrumbComponent
          // rootPath={"Perfomance / Contribute Goal / View Goal"}
          // subPath={"Edit Goal"}
          // rootLink={`${n.PERFORMANCE_URL}/${n.CONTRIBUTE_VIEW_GOAL_URL}/${n.CONTRIBUTE_EDIT_GOAL_URL}`}
          items={items}
        />
        <h1>Edit Goals</h1>
      </div>
      <Card style={{ width: "100%" }}>
        <Form
          form={form}
          name="form_item"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label="Category"
            name="category"
            validateTrigger="onBlur"
            required={true}
          >
            <Select
              style={{ width: "20%" }}
              prefixCls="ant-placeholder"
              defaultValue={currentGoal?.category || ""}
            >
              <Option value="">Choose a category</Option>
              <Option value="USD">Business Category</Option>
            </Select>
          </Form.Item>
          <Form.Item required={true} label="Goal Name" name="name">
            <Input.TextArea
              placeholder="Write a goal name"
              required={true}
              autoSize={{ minRows: 1, maxRows: 1 }}
              value={goalName}
              maxLength={35}
              onChange={(e: any) => {
                setGoalName(e?.target?.value);
              }}
              defaultValue={currentGoal?.name?.title}
            />
          </Form.Item>
          {/* <span className="charCountGoal">{`${35 - (goalName?.length !== undefined ? goalName?.length : 0)
                        } ${goalName?.length === 35 ? `character` : `characters`
                        } remaining `}</span> */}
          <Form.Item
            required={true}
            label="Goal Description"
            name="description"
          >
            <Input.TextArea
              placeholder="Write a goal description"
              required={true}
              autoSize={{ minRows: 4, maxRows: 5 }}
              defaultValue={currentGoal?.name?.subtitle}
            />
          </Form.Item>
          <Form.Item required={true} label="Metrics" name="metrics">
            <Input.TextArea
              placeholder="Write the metric to measure the goal"
              required={true}
              autoSize={{ minRows: 4, maxRows: 5 }}
              defaultValue={currentGoal?.metrics}
            />
          </Form.Item>
          <div className="goal-row-two-section">
            <Form.Item
              required={true}
              label="OutCome:"
              name="outcome"
              validateTrigger="onBlur"
            >
              <Select
                placeholder="Select"
                style={{ width: "100%" }}
                defaultValue={currentGoal?.outcomes}
              >
                <Option value="USD">
                  {currentGoal?.outcomes || "Business Category"}
                </Option>
              </Select>
            </Form.Item>
            <div className="weight_section">
              <div className="weight_left_section">
                <Form.Item required={true} label="Weight" name="weight">
                  <InputNumber
                    defaultValue={currentGoal?.weightage}
                    required={true}
                    min={0}
                    max={100}
                    placeholder="Enter in percentage"
                    step={1}
                    precision={0}
                  />
                </Form.Item>
                <span className="weightSpan">80% more remaining</span>
              </div>
              <div className="weight_right_section">
                <span>%</span>
              </div>
            </div>
          </div>
          <div className="goal-row-two-section dates">
            <Form.Item required={true} label="Start Date" name="startDate">
              <DatePicker
                placeholder="Goal start date"
                // format={CERTIFICATION_DATE_FORMAT}
                inputReadOnly
              />
            </Form.Item>
            <Form.Item required={true} label="End Date" name={"endDate"}>
              <DatePicker
                placeholder="Goal end date"
                //format={CERTIFICATION_DATE_FORMAT}
                inputReadOnly
              />
            </Form.Item>
          </div>
          <div className="milestone-section">
            <div className="milestone-header-section">
              <div className="milestone-header-left">
                <h5>{GOAL_TITLE}</h5>
                <span>{GOAL_SUBTITLE}</span>
              </div>
              {/* <div className="milesstone-header-right">
                <OutlineButton backText="Edit Milestones"/>
            </div> */}
            </div>
            {isEmptyMilestone ? (
              <div className="milestone-body-section">
                <div className="milestone-body-header">
                  <img src={AccessTime} alt="" />
                  <h5>Milestones 1</h5>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500. Lorem Ipsum has been
                  the industry's standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled
                </p>
                <div className="milestone-body-dates">
                  <span className="milestone-start-date">
                    Target Date : 31/02/2023
                  </span>
                  <span>Actual Date : 31/02/2023</span>
                </div>
              </div>
            ) : (
              <div className="empty-milestone">
                <img src={BusinessMilestone} alt="" />
                <span>
                  No milestone has been added yet. Click to add milestone.
                </span>
                <FillButton
                  fillBtnTxt="Break into Milestones"
                  fillBtnAction={fillBtnAction}
                  disabled={isValid ? false : !isValidated}
                />
              </div>
            )}
          </div>
          <Form.Item label="Status" name="status">
            <Input
              className="status-bar"
              placeholder="Status"
              defaultValue={currentGoal?.status?.text}
            />
          </Form.Item>
          <Form.Item
            name="agreement"
            valuePropName="checked"
            // rules={[
            //   {
            //     validator: (_, value) =>
            //       value ? Promise.resolve() : Promise.reject(),
            //   },
            // ]}
          >
            <Checkbox defaultChecked>{NEWGOAL_CHECKBOX}</Checkbox>
          </Form.Item>
          <div className="button-area">
            <OutlineButton
              outlineBtnStyles="outlineBtnStyles"
              backText={CANCEL}
              outlineBtnAction={back}
            />
            <FillButton
              fillBtnStyles="fillBtnStyles"
              fillBtnTxt={PREVIEW}
              fillBtnAction={preview}
            />
          </div>
        </Form>
      </Card>
    </div>
  );
};
export default ContributeEditGoal;
