import { useNavigate } from "react-router-dom";
import { ASP_START_NEXT } from "../../../constants";
import FillButton from "../../common/button/FillButton";
import OutlineButton from "../../common/button/OutlineButton";
import n from "../../navigation/Route.Names";
import "../performance.css";
import "./GoalEvalution.css";
import { Card, Form, Rate, Input, Progress, Steps, Button } from "antd";
import ColorPaletteIcon from "../../../themes/assets/images/svg/goals/compotency_icon1.svg";
import BoldIcon from "../../../themes/assets/images/svg/goals/compotency_icon2.svg";
import UnderlineIcon from "../../../themes/assets/images/svg/goals/compotency_icon3.svg";
import OrderedListIcon from "../../../themes/assets/images/svg/goals/compotency_icon4.svg";
import NumericListIcon from "../../../themes/assets/images/svg/goals/compotency_icon5.svg";
import EvaluationCard from "./GoalEvaluationCard";
import { useState } from "react";
import Slider from "react-slick";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import {
  ASP_COMPOTENCY_EVALUATION_EMPLOYEE_LABEL,
  ASP_COMPOTENCY_EVALUATION_RATING_LABEL,
} from "../../../constants";
import RichTextEditor from "../components/RichTextEditor";
import CardEdit from "../../../themes/assets/images/performance/ASPForm/PencilSimple.svg";
import CardDelete from "../../../themes/assets/images/performance/ASPForm/Trash.svg";

const { TextArea } = Input;

const steps = [
  {
    title: "First Quarter Review",
    subTitle: " Completed on 7 January 2024",
    content: "First-content",
    status: "wait",
  },
  {
    title: "Second Quarter Review",
    subTitle: " Completed on 7 January 2024",
    content: "Second-content",
    status: "wait",
  },
  {
    title: "Third Quarter Review",
    subTitle: " Completed on 7 January 2024",
    content: "Last-content",
    status: "wait",
  },
  {
    title: "Year End Review Employee",
    subTitle: " Completed on 7 January 2024",
    content: "First-content",
    status: "wait",
  },
  {
    title: "Year End Review Manager",
    subTitle: " Completed on 7 January 2024",
    content: "Second-content",
    status: "wait",
  },
];
const stepsSecond = [
  {
    title: "Calibration",
    subTitle: " Completed on 7 January 2024",
    content: "First-content",
    status: "wait",
  },
  {
    title: "Performance Review Discussion",
    subTitle: " Completed on 7 January 2024",
    content: "Second-content",
    status: "wait",
  },
  {
    title: "Employee Signature",
    subTitle: " Completed on 7 January 2024",
    content: "Last-content",
    status: "wait",
  },
  {
    title: "Manager Signature",
    subTitle: " Completed on 7 January 2024",
    content: "Second-content",
    status: "wait",
  },
  {
    title: "Completed",
    subTitle: " Completed on 7 January 2024",
    content: "Last-content",
    status: "wait",
  },
];
const GoalEvaluationList = [
  {
    id: 1,
    EvalutionLabel: "Goal Name",
    EvalutionDescrption: "Sprint Quality Management",
  },
  {
    id: 2,
    EvalutionLabel: "Metric",
    EvalutionDescrption:
      "1. 90 to 100% accurate loading of engineering team 2.1. # feature de-comits due to poor planning and execution are minimal 2.2. Less defect density (2%)",
  },
  {
    id: 3,
    EvalutionLabel: "Start Date",
    EvalutionDescrption: "01/01/2023",
  },
  {
    id: 4,
    EvalutionLabel: "Due Date",
    EvalutionDescrption: "12/31/2023",
  },
  {
    id: 5,
    EvalutionLabel: "Status",
    EvalutionDescrption: "On Track",
  },
  {
    id: 6,
    EvalutionLabel: "Weight",
    EvalutionDescrption: "25%",
  },
  {
    id: 7,
    EvalutionLabel: "Goal Description",
    EvalutionDescrption:
      "Achieve high sprint velocity and on-time product releases so that strategic timelines are met.Ensure best practices followed within the team to produce high quality product and features",
  },
];
const GoalEvaluationListTwo = [
  {
    id: 1,
    EvalutionLabel: "Goal Name",
    EvalutionDescrption: "Quality of Individual Work",
  },
  {
    id: 2,
    EvalutionLabel: "Metric",
    EvalutionDescrption: "Metric 1",
  },
  {
    id: 3,
    EvalutionLabel: "Start Date",
    EvalutionDescrption: "01/01/2023",
  },
  {
    id: 4,
    EvalutionLabel: "Due Date",
    EvalutionDescrption: "12/31/2023",
  },
  {
    id: 5,
    EvalutionLabel: "Status",
    EvalutionDescrption: "On Track",
  },
  {
    id: 6,
    EvalutionLabel: "Weight",
    EvalutionDescrption: "25%",
  },
  {
    id: 7,
    EvalutionLabel: "Goal Description",
    EvalutionDescrption:
      "Achieve high sprint velocity and on-time product releases so that strategic timelines are met.Ensure best practices followed within the team to produce high quality product and features. igh sprint velocity and on-time product releases so that strategic timelines are met.Ensure best practices followed within the team to produce high quality product and feat.",
  },
];
const GoalEvaluationListThree = [
  {
    id: 1,
    EvalutionLabel: "Goal Name",
    EvalutionDescrption: "Backlog Visibility & Readiness",
  },
  {
    id: 2,
    EvalutionLabel: "Metric",
    EvalutionDescrption:
      "1. 90 to 100% accurate loading of engineering team 2.1. # feature de-comits due to poor planning and execution ",
  },
  {
    id: 3,
    EvalutionLabel: "Start Date",
    EvalutionDescrption: "01/01/2023",
  },
  {
    id: 4,
    EvalutionLabel: "Due Date",
    EvalutionDescrption: "12/31/2023",
  },
  {
    id: 5,
    EvalutionLabel: "Status",
    EvalutionDescrption: "On Track",
  },
  {
    id: 6,
    EvalutionLabel: "Weight",
    EvalutionDescrption: "15%",
  },
  {
    id: 7,
    EvalutionLabel: "Goal Description",
    EvalutionDescrption:
      "Achieve high sprint velocity and on-time product releases so that strategic timelines are met.Ensure.",
  },
];
const items = steps.map((item) => ({
  key: item.title,
  title: item.title,
  subTitle: item.subTitle,
}));
const newItems = stepsSecond.map((item) => ({
  key: item.title,
  title: item.title,
  subTitle: item.subTitle,
}));
const GoalEvaluation = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [current, setCurrent] = useState(0);
  const onChange = (value: number) => {
    console.log("onChange:", value);
    setCurrent(value);
  };
  const [remainingNum, setRemainingNum] = useState(0);
  const onChangeRemaining = (value: number) => {
    console.log("onChange:", value);
    setRemainingNum(value);
  };
  const [value, setValue] = useState("");
  let navigate = useNavigate();
  return (
    <div className="goal-evalution-eval">
      <div className="layoutContainer">
        <BreadcrumbComponent
          rootPath={"Performance / ASP Form"}
          subPath={"Year End Evaluation"}
          rootLink={`${n.PERFORMANCE_URL}/${n.PERFORMANCE_URL}`}
        />
        <div className="competency-heading-section">
          <h1>2023 Year End Evaluation</h1>
          <div className="progress-section">
            <p>Page 1 of 2</p>
            <Progress percent={20} />
          </div>
        </div>
        <Slider className="stepper-process" {...settings}>
          <div className="stepper-section">
            <Steps
              onChange={onChange}
              current={current}
              items={items}
              labelPlacement="vertical"
            />
          </div>
          <div className="stepper-section">
            <Steps
              onChange={onChangeRemaining}
              current={remainingNum}
              items={newItems}
              labelPlacement="vertical"
            />
          </div>
        </Slider>
        <div className="goal-evalution-head">
          <div className="goal-evalution-head-title">
            <h1>
              Goal Evaluation<span>100.0%</span>
            </h1>
            <div className="add-milestone-btn">
              <OutlineButton
                outlineBtnStyles="outlineBtnStyles"
                backText=" + Add Goal"
              />
            </div>
          </div>
          <Card className="card_list-evalution" style={{ width: "100%" }}>
            <span className="badge_card">Business</span>
            <div className="card_edit-sec">
              <span className="edit_card">
                <img src={CardEdit} />
              </span>
              <span className="delete_card">
                <img src={CardDelete} />
              </span>
            </div>
            <div className="goal-evalution-title">
              <h2>Sprint Quality Management</h2>
            </div>
            <div className="compotency-main-section">
              <div className="compotency-inner-section">
                <Form.Item
                  label={ASP_COMPOTENCY_EVALUATION_RATING_LABEL}
                  required
                >
                  <div>
                    <Rate className={"custom-rating"} defaultValue={0} />
                  </div>
                </Form.Item>
              </div>
              <div className="compotency-inner-section text-editor-section">
                <Form.Item
                  label={ASP_COMPOTENCY_EVALUATION_EMPLOYEE_LABEL}
                  required
                >
                  <div>
                    <RichTextEditor content={value} setContent={setValue} />
                  </div>
                </Form.Item>
                {/* <div className="texteditor-section">
                                    <Button><img alt="ColorPaletteIcon" src={ColorPaletteIcon} /></Button>
                                    <Button><img alt="BoldIcon" src={BoldIcon} /></Button>
                                    <Button><img alt="UnderlineIcon" src={UnderlineIcon} /></Button>
                                    <Button><img alt="OrderedListIcon" src={OrderedListIcon} /></Button>
                                    <Button><img alt="NumericListIcon" src={NumericListIcon} /></Button>
                                </div> */}
              </div>
            </div>
            <div className="goal-evalution-list">
              {GoalEvaluationList.map((item) => (
                <EvaluationCard key={item?.id?.toString()} employee={item} />
              ))}
            </div>
          </Card>
          <Card className="card_list-evalution" style={{ width: "100%" }}>
            <span className="badge_card">Business</span>
            <div className="card_edit-sec">
              <span className="edit_card">
                <img src={CardEdit} />
              </span>
              <span className="delete_card">
                <img src={CardDelete} />
              </span>
            </div>
            <div className="goal-evalution-title">
              <h2>Quality of Individual Work</h2>
            </div>
            <div className="compotency-main-section">
              <div className="compotency-inner-section">
                <Form.Item
                  label={ASP_COMPOTENCY_EVALUATION_RATING_LABEL}
                  required
                >
                  <div>
                    <Rate
                      className={"custom-rating"}
                      allowHalf
                      defaultValue={0}
                    />
                  </div>
                </Form.Item>
              </div>
              <div className="compotency-inner-section text-editor-section">
                <Form.Item
                  label={ASP_COMPOTENCY_EVALUATION_EMPLOYEE_LABEL}
                  required
                >
                  <div>
                    <RichTextEditor content={value} setContent={setValue} />
                  </div>
                </Form.Item>
                {/* <div className="texteditor-section">
                                    <Button><img alt="ColorPaletteIcon" src={ColorPaletteIcon} /></Button>
                                    <Button><img alt="BoldIcon" src={BoldIcon} /></Button>
                                    <Button><img alt="UnderlineIcon" src={UnderlineIcon} /></Button>
                                    <Button><img alt="OrderedListIcon" src={OrderedListIcon} /></Button>
                                    <Button><img alt="NumericListIcon" src={NumericListIcon} /></Button>
                                </div> */}
              </div>
            </div>
            <div className="goal-evalution-list">
              {GoalEvaluationListTwo.map((item) => (
                <EvaluationCard key={item?.id?.toString()} employee={item} />
              ))}
            </div>
          </Card>
          <Card className="card_list-evalution" style={{ width: "100%" }}>
            <span className="badge_card">Technology</span>
            <div className="card_edit-sec">
              <span className="edit_card">
                <img src={CardEdit} />
              </span>
              <span className="delete_card">
                <img src={CardDelete} />
              </span>
            </div>
            <div className="goal-evalution-title">
              <h2>Backlog Visibility & Readiness</h2>
            </div>
            <div className="compotency-main-section">
              <div className="compotency-inner-section">
                <Form.Item
                  label={ASP_COMPOTENCY_EVALUATION_RATING_LABEL}
                  required
                >
                  <div>
                    <Rate
                      className={"custom-rating"}
                      allowHalf
                      defaultValue={0}
                    />
                  </div>
                </Form.Item>
              </div>
              <div className="compotency-inner-section text-editor-section">
                <Form.Item
                  label={ASP_COMPOTENCY_EVALUATION_EMPLOYEE_LABEL}
                  required
                >
                  <div>
                    <RichTextEditor content={value} setContent={setValue} />
                  </div>
                </Form.Item>
                {/* <div className="texteditor-section">
                                    <Button><img alt="ColorPaletteIcon" src={ColorPaletteIcon} /></Button>
                                    <Button><img alt="BoldIcon" src={BoldIcon} /></Button>
                                    <Button><img alt="UnderlineIcon" src={UnderlineIcon} /></Button>
                                    <Button><img alt="OrderedListIcon" src={OrderedListIcon} /></Button>
                                    <Button><img alt="NumericListIcon" src={NumericListIcon} /></Button>
                                </div> */}
              </div>
            </div>
            <div className="goal-evalution-list">
              {GoalEvaluationListThree.map((item) => (
                <EvaluationCard key={item?.id?.toString()} employee={item} />
              ))}
            </div>
          </Card>
          <Card
            className="card_list-evalution field_label  text-editor-section"
            style={{ width: "100%" }}
          >
            <div className="goal-evalution-title">
              <h3>Overall Comments on Goal Achievements</h3>
              {/* <div className="texteditor-section">
                                <Button><img alt="ColorPaletteIcon" src={ColorPaletteIcon} /></Button>
                                <Button><img alt="BoldIcon" src={BoldIcon} /></Button>
                                <Button><img alt="UnderlineIcon" src={UnderlineIcon} /></Button>
                                <Button><img alt="OrderedListIcon" src={OrderedListIcon} /></Button>
                                <Button><img alt="NumericListIcon" src={NumericListIcon} /></Button>
                            </div> */}
            </div>
            <RichTextEditor content={value} setContent={setValue} />
          </Card>
        </div>
        <div className="goal_outline-btn-sec">
          <OutlineButton
            outlineBtnStyles={"cancel-outline-btn"}
            outlineBtnAction={() => {}}
            backText="Cancel"
          />
          <div className="goal_fill-inner-btn-sec">
            <OutlineButton
              outlineBtnStyles={"previous-outline-btn"}
              outlineBtnAction={() => {}}
              backText="Save"
            />
            <FillButton
              fillBtnStyles={"submit-fill-btn"}
              fillBtnAction={() => {
                navigate(
                  `/${n.PERFORMANCE_URL}/${n.COMPOTENCY_EVALUATION_URL}`
                );
              }}
              fillBtnTxt={ASP_START_NEXT}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalEvaluation;
