import React, { useState, useEffect } from "react";
import EmployeeCard from "../../components/EmployeeCard";
import "./index.css";
import { Input } from "antd";
import CheckinSearchIn from "../../../../themes/assets/images/performance/svg/search-Icon-checkin.svg";
import employeeDetails from "../EmployeeData.json";
import NoDataFound from "../../../../themes/assets/images/not-found.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import { getEmployeeListData } from "../../../../redux/actions/performance";
import { SearchIconManagerView } from "../../../common/svg/svg";

const EmployeeList = ({ year }) => {
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [searchDetails, setSearchDetails] = useState<any>([]);
  const employeeListData = useAppSelector((state: any) => state.performance);
  const dispatch = useAppDispatch();
  const employeeWholeList = employeeListData?.getEmployeeData;
  useEffect(() => {
    dispatch(getEmployeeListData({ year: year }));
  }, []);

  const handleSearch = (e: any) => {
    const searchValue = e.target?.value;
    if (searchValue === "" || searchValue.length >= 3) {
      dispatch(
        getEmployeeListData({
          year: year,
          searchString: searchValue,
        })
      );
    }
  };
  useEffect(() => {
    const employeeListSorted = employeeList.sort((a, b) =>
      a.userName.localeCompare(b.userName)
    );
    setEmployeeList(employeeListSorted);
  }, []);

  return (
    <div className="list">
      <div className="topcomponents">
        <div className="contents">
          <p className="titlelist">
            List of Employees ({employeeWholeList.length})
          </p>
        </div>
        <div className="search">
          <Input placeholder="Search" onChange={handleSearch} />
          <SearchIconManagerView />
        </div>
      </div>

      <div className="employeeDisplay">
        {employeeWholeList.length === 0 ? (
          <div>
            <img
              className="employee_list_not_found"
              src={NoDataFound}
              alt="No data"
            />
          </div>
        ) : (
          employeeWholeList.map((item: any, index: any) => (
            <EmployeeCard key={index} employee={item} />
          ))
        )}
      </div>
    </div>
  );
};
export default EmployeeList;
