import { useNavigate, Link } from "react-router-dom";
import {
  ASP_COMPOTENCY_EVALUATION_EMPLOYEE_LABEL,
  ASP_COMPOTENCY_EVALUATION_RATING_LABEL,
  ASP_COMPOTENCY_EVALUATION_TITLE,
  ASP_START_NEXT,
  CANCEL,
  CONFIRMATION_TITLE,
  SUBMIT,
  SUBMITTING_YEAR_END_EVALUATION,
} from "../../../constants";
import FillButton from "../../common/button/FillButton";
import "../performance.css";
import {
  Card,
  Form,
  Rate,
  Input,
  Button,
  Steps,
  message,
  Progress,
} from "antd";
import { useState } from "react";
import "./aspform.css";
import ColorPaletteIcon from "../../../themes/assets/images/svg/goals/compotency_icon1.svg";
import BoldIcon from "../../../themes/assets/images/svg/goals/compotency_icon2.svg";
import UnderlineIcon from "../../../themes/assets/images/svg/goals/compotency_icon3.svg";
import OrderedListIcon from "../../../themes/assets/images/svg/goals/compotency_icon4.svg";
import NumericListIcon from "../../../themes/assets/images/svg/goals/compotency_icon5.svg";
import OutlineButton from "../../common/button/OutlineButton";
import n from "../../navigation/Route.Names";
import ModalDialog from "../../common/modal/modal";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowLeft from "../../../themes/assets/images/svg/goals/arrowleft.svg";
import ArrowRight from "../../../themes/assets/images/svg/goals/arrowright.svg";
import RichTextEditor from "../components/RichTextEditor";

const { TextArea } = Input;
const steps = [
  {
    title: "First Quarter Review",
    subTitle: " Completed on 7 January 2024",
    content: "First-content",
    status: "wait",
  },
  {
    title: "Second Quarter Review",
    subTitle: " Completed on 7 January 2024",
    content: "Second-content",
    status: "wait",
  },
  {
    title: "Third Quarter Review",
    subTitle: " Completed on 7 January 2024",
    content: "Last-content",
    status: "wait",
  },
  {
    title: "Year End Review Employee",
    subTitle: " Completed on 7 January 2024",
    content: "First-content",
    status: "wait",
  },
  {
    title: "Year End Review Manager",
    subTitle: " Completed on 7 January 2024",
    content: "Second-content",
    status: "wait",
  },
];
const stepsSecond = [
  {
    title: "Calibration",
    subTitle: " Completed on 7 January 2024",
    content: "First-content",
    status: "wait",
  },
  {
    title: "Performance Review Discussion",
    subTitle: " Completed on 7 January 2024",
    content: "Second-content",
    status: "wait",
  },
  {
    title: "Employee Signature",
    subTitle: " Completed on 7 January 2024",
    content: "Last-content",
    status: "wait",
  },
  {
    title: "Manager Signature",
    subTitle: " Completed on 7 January 2024",
    content: "Second-content",
    status: "wait",
  },
  {
    title: "Completed",
    subTitle: " Completed on 7 January 2024",
    content: "Last-content",
    status: "wait",
  },
];
// function SampleNextArrow(props:any) {
//   const { className, style, onClick } = props;

//   return (
//     <div>
//   <img src={ArrowRight} />
//   </div>
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div><img src={ArrowLeft}  /></div>
//   );
// }
const CompotencyEvaluation = () => {
  const [current, setCurrent] = useState(0);
  const onChange = (value: number) => {
    console.log("onChange:", value);
    setCurrent(value);
  };
  const [remainingNum, setRemainingNum] = useState(0);
  const onChangeRemaining = (value: number) => {
    console.log("onChange:", value);
    setRemainingNum(value);
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    subTitle: item.subTitle,
  }));
  const newItems = stepsSecond.map((item) => ({
    key: item.title,
    title: item.title,
    subTitle: item.subTitle,
  }));
  const [value, setValue] = useState("");
  let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleDelete = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const breadbrumbItems = [
    {
      title: (
        <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
          Performance
        </Link>
      ),
    },
    {
      title: (
        <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
          ASP Form
        </Link>
      ),
    },
    {
      title: "Year End Evaluation",
    },
  ];

  return (
    <>
      <div className="layoutContainer">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="competency-heading-section">
          <h1>2023 Year End Evaluation</h1>
          <div className="progress-section">
            <p>Page 2 of 2</p>
            <Progress percent={30} />
          </div>
        </div>
        <Slider className="stepper-process" {...settings}>
          <div className="stepper-section">
            <Steps
              onChange={onChange}
              current={current}
              items={items}
              labelPlacement="vertical"
            />
          </div>
          <div className="stepper-section">
            <Steps
              onChange={onChangeRemaining}
              current={remainingNum}
              items={newItems}
              labelPlacement="vertical"
            />
          </div>
          {/* <div>
        <div className=""></div>
        <h5>Third Quarter Review</h5>
        <p>Completed on 7 January 2024</p>
      </div>
      <div>
        <h3>2</h3>
      </div>
      <div>
        <h3>3</h3>
      </div>
      <div>
        <h3>4</h3>
      </div>
      <div>
        <h3>5</h3>
      </div>
      <div>
        <h3>6</h3>
      </div> */}
        </Slider>
        {/* <div className="stepper-section">
            <Steps current={current} items={items} labelPlacement="vertical" />
      <div>{steps[current].content}</div>
      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
      </div> */}
        <h4 className="common-short-heading">
          {ASP_COMPOTENCY_EVALUATION_TITLE}
        </h4>
        <Card className="compotency-card" style={{ width: "100%" }}>
          <div className="compotency-main-section">
            <div className="compotency-inner-section">
              <h4>Effective Communication</h4>
              <ol type="1">
                <li>
                  Demonstrates effective written and oral communication skills.{" "}
                </li>
                <li>
                  Structures communication appropriately, listens carefully
                  (content, choice of words and grammar in line with audience)
                  and fluently.
                </li>
                <li>
                  Sharing relevant information to the respective stake holders &
                  Adhering to communication channel etiquettes
                </li>
              </ol>
            </div>
            <div className="compotency-inner-section">
              <Form.Item
                label={ASP_COMPOTENCY_EVALUATION_RATING_LABEL}
                required
              >
                <div>
                  <Rate className={"custom-rating"} defaultValue={0} />
                </div>
              </Form.Item>
            </div>
            <div className="compotency-inner-section text-editor-section">
              <Form.Item
                label={ASP_COMPOTENCY_EVALUATION_EMPLOYEE_LABEL}
                required
              >
                <div>
                  <RichTextEditor content={value} setContent={setValue} />
                </div>
              </Form.Item>
              {/* <div className="texteditor-section">
                                <Button><img src={ColorPaletteIcon} /></Button>
                                <Button><img src={BoldIcon} /></Button>
                                <Button><img src={UnderlineIcon} /></Button>
                                <Button><img src={OrderedListIcon} /></Button>
                                <Button><img src={NumericListIcon} /></Button>
                            </div> */}
            </div>
          </div>
        </Card>
        <Card className="compotency-card" style={{ width: "100%" }}>
          <div className="compotency-main-section">
            <div className="compotency-inner-section">
              <h4>Self Development</h4>
              <p>
                Accomplishes 30 hours of learning per year based on the
                discussion with Reporting Manager
              </p>
            </div>
            <div className="compotency-inner-section">
              <Form.Item
                label={ASP_COMPOTENCY_EVALUATION_RATING_LABEL}
                required
              >
                <div>
                  <Rate className={"custom-rating"} defaultValue={0} />
                </div>
              </Form.Item>
            </div>
            <div className="compotency-inner-section text-editor-section">
              <Form.Item
                label={ASP_COMPOTENCY_EVALUATION_EMPLOYEE_LABEL}
                required
              >
                <div>
                  <RichTextEditor content={value} setContent={setValue} />
                </div>
              </Form.Item>
              {/* <div className="texteditor-section">
                                <Button><img src={ColorPaletteIcon} /></Button>
                                <Button><img src={BoldIcon} /></Button>
                                <Button><img src={UnderlineIcon} /></Button>
                                <Button><img src={OrderedListIcon} /></Button>
                                <Button><img src={NumericListIcon} /></Button>
                            </div> */}
            </div>
          </div>
        </Card>
        <Card className="compotency-card" style={{ width: "100%" }}>
          <div className="compotency-main-section">
            <div className="compotency-inner-section">
              <h4>Team Work & Collaboration</h4>
              <ol type="1">
                <li>
                  Being mindful to interdependencies across functions and roles{" "}
                </li>
                <li>
                  Building a working relationship with the peers by seeking
                  ideas and suggestions from peers, when required
                </li>
                <li>Knowledge sharing & learning</li>
                <li>Collaboration</li>
              </ol>
            </div>
            <div className="compotency-inner-section">
              <Form.Item
                label={ASP_COMPOTENCY_EVALUATION_RATING_LABEL}
                required
              >
                <div>
                  <Rate className={"custom-rating"} defaultValue={0} />
                </div>
              </Form.Item>
            </div>
            <div className="compotency-inner-section text-editor-section">
              <Form.Item
                label={ASP_COMPOTENCY_EVALUATION_EMPLOYEE_LABEL}
                required
              >
                <div>
                  <RichTextEditor content={value} setContent={setValue} />
                </div>
              </Form.Item>
              {/* <div className="texteditor-section">
                                <Button><img src={ColorPaletteIcon} /></Button>
                                <Button><img src={BoldIcon} /></Button>
                                <Button><img src={UnderlineIcon} /></Button>
                                <Button><img src={OrderedListIcon} /></Button>
                                <Button><img src={NumericListIcon} /></Button>
                            </div> */}
            </div>
          </div>
        </Card>
        <div className="footer-btn-section">
          <div className="footer-btn-left">
            <OutlineButton
              outlineBtnStyles={"cancel-outline-btn"}
              outlineBtnAction={() => {}}
              backText="Cancel"
            />
          </div>
          <div className="footer-btn-right">
            <OutlineButton
              outlineBtnStyles={"previous-outline-btn"}
              outlineBtnAction={() => {
                navigate(`/${n.PERFORMANCE_URL}/${n.GOAL_EVALUATION_URL}`);
              }}
              backText="Previous"
            />
            <OutlineButton
              outlineBtnStyles={"previous-outline-btn"}
              outlineBtnAction={() => {}}
              backText="Save"
            />
            <FillButton
              fillBtnStyles={"submit-fill-btn"}
              fillBtnAction={showModal}
              fillBtnTxt={"Submit Year End Evaluation"}
            />
          </div>
        </div>
        <ModalDialog
          openModal={isModalOpen}
          DialogHeading={CONFIRMATION_TITLE}
          closeIcon={false}
          modalImageStyles={{ display: "none" }}
          modalHeadingStyles={{ display: "none" }}
          popupDivider={{ display: "none" }}
          indexScroll={{ display: "none" }}
          modalCenterImgStyles={{ display: "none" }}
          msgContent={SUBMITTING_YEAR_END_EVALUATION}
          okTxt={SUBMIT}
          cancelTxt={CANCEL}
          cancelBtn={handleCancel}
          okBtn={handleOk}
          onOk={handleOk}
          onCancel={handleCancel}
          modalStyles={"backpopup delete-milestone-popup compotency-popup"}
          maskClosable={false}
        />
      </div>
    </>
  );
};

export default CompotencyEvaluation;
