import { useNavigate } from "react-router-dom";
import "./PotentialRating.css";
import {
  ASP_COMPOTENCY_EVALUATION_MANAGER_LABEL,
  POTENTIAL_RATING_TITLE,
  POTENTIAL_RATING_NOTE,
  POTENTIAL_CHART,
  POTENTIAL_BOX_TITLE,
  POTENTIAL_X_AXIS,
  POTENTIAL_Y_AXIS,
  RANK_SELECTION_TITLE,
  ASP_SUBMIT_EVALUATION,
  SAVE,
  CANCEL,
  MIN_ROWS,
  MAX_ROWS,
  PLACEHOLDER_RANK_SELECT,
  PLACEHOLDER_ADD_COMMENT,
} from "../../../../../constants";
import FillButton from "../../../../common/button/FillButton";
import { Card, Col, Row, Form, Input } from "antd";
import { useState } from "react";
import OutlineButton from "../../../../common/button/OutlineButton";
import TextStylesChange from "../../../../Performance/components/TextStylesChange";
import LeftArrow from "../../../../../themes/assets/images/performance/svg/LeftArrow.svg";
import RightArrow from "../../../../../themes/assets/images/performance/svg/RightArrow.svg";
import TopArrow from "../../../../../themes/assets/images/performance/svg/TopArrow.svg";
import BottomArrow from "../../../../../themes/assets/images/performance/svg/BottomArrow.svg";

const { TextArea } = Input;

const cardDetails = [
  {
    title: "1 - Risk",
    desc: "Poor Performance, Low Potential",
    color: "#CC0000",
  },
  {
    title: "2 - Average Performer",
    desc: "Average Performance, Limited Potential",
    color: "#1976D2",
  },
  {
    title: "3 - Performer",
    desc: "Good Performance, Limited Potential",
    color: "#1976D2",
  },
  {
    title: "4 - Questionable",
    desc: "Poor Performance, Moderate Potential",
    color: "#E8A911",
  },
  {
    title: "5 - Contributor",
    desc: "Good Performance, Moderate Potential",
    color: "#1976D2",
  },
  {
    title: "6 - Emerging Star",
    desc: "Good Performance, Moderate Potential",
    color: "#008000",
  },
  {
    title: "7 - Needs Coaching",
    desc: "Poor Performance, High Potential",
    color: "#E8A911",
  },
  {
    title: "8 - Rising Star",
    desc: "Good Performance, High Potential",
    color: "#008000",
  },
  {
    title: "9 - Star",
    desc: "Excellent Performance, High Potential",
    color: "#008000",
  },
];

const CompotencyEvaluation = () => {
  const [value, setValue] = useState("");
  const [selectedPreformance, setSelectedPerformance] = useState({
    title: "",
    desc: "",
  });
  let navigate = useNavigate();

  const rankInputValue = !!selectedPreformance.title
    ? `${selectedPreformance.title} :  ${selectedPreformance.desc}`
    : "";

  return (
    <>
      <div className="layoutContainer">
        <div className="compotency-inner-section">
          <h4 className="common-short-heading">{POTENTIAL_RATING_TITLE}</h4>
          <p className="potential-rating-note">{POTENTIAL_RATING_NOTE}</p>
        </div>
        <Card className="compotency-card">
          <div className="compotency-main-section">
            <div className="compotency-inner-section">
              <h4>{POTENTIAL_CHART}</h4>
              <p>{POTENTIAL_BOX_TITLE}</p>
              <div className="cards_container">
                <div className="x-axis-potential">
                  <img
                    className="top-arrow-x-axis"
                    src={TopArrow}
                    alt="Top Arrow"
                  />
                  <span>{POTENTIAL_X_AXIS}</span>
                  <img
                    className="bottom-arrow-x-axis"
                    src={BottomArrow}
                    alt="Bottom Arrow"
                  />
                </div>
                <Row gutter={16}>
                  {cardDetails.map((card: any, index) => {
                    const showOverlay =
                      selectedPreformance.title === card.title;
                    return (
                      <Col
                        span={8}
                        onClick={() => setSelectedPerformance(card)}
                      >
                        <div
                          className="card_wrapper"
                          style={{ borderColor: card.color }}
                        >
                          {showOverlay && (
                            <div
                              className="overlay"
                              style={{ backgroundColor: card.color }}
                            ></div>
                          )}

                          <div className="card_content">
                            <span
                              className="potential_indicator"
                              style={{
                                background: card.color,
                              }}
                            ></span>
                            <div>
                              <div className="potential_chart_title">
                                {card?.title}
                              </div>
                              <div className="potential_chart_desc">
                                {card?.desc}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
            <div className="y-axis-perfomance">
              <img
                className="left-arrow-y-axis"
                src={LeftArrow}
                alt="Left Arrow"
              />
              <span>{POTENTIAL_Y_AXIS}</span>
              <img
                className="right-arrow-y-axis"
                src={RightArrow}
                alt="Right Arrow"
              />
            </div>
            <div>
              <div className="rank-selected-title">{RANK_SELECTION_TITLE}</div>
              <Form.Item>
                <Input
                  className="rank-selected-input"
                  placeholder={PLACEHOLDER_RANK_SELECT}
                  value={rankInputValue}
                />
              </Form.Item>
            </div>
            <div className="compotency-inner-section">
              <Form.Item
                label={ASP_COMPOTENCY_EVALUATION_MANAGER_LABEL}
                required
              >
                <div>
                  <TextArea
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={PLACEHOLDER_ADD_COMMENT}
                    autoSize={{ minRows: MIN_ROWS, maxRows: MAX_ROWS }}
                  />
                </div>
              </Form.Item>
              <div>
                <TextStylesChange />
              </div>
            </div>
          </div>
        </Card>
        <div className="footer-btn-section">
          <div className="footer-btn-left">
            <OutlineButton
              outlineBtnStyles={"cancel-outline-btn"}
              outlineBtnAction={() => {}}
              backText={CANCEL}
            />
          </div>
          <div className="footer-btn-right">
            <OutlineButton
              outlineBtnStyles={"previous-outline-btn"}
              outlineBtnAction={() => {
                navigate(
                  `/web/my_work/${"team_performance"}/manager_compotency_evaluation`
                );
              }}
              backText="Previous"
            />
            <OutlineButton
              outlineBtnStyles={"previous-outline-btn"}
              outlineBtnAction={() => {}}
              backText={SAVE}
            />
            <FillButton
              fillBtnStyles={"submit-fill-btn"}
              //   fillBtnAction={showModal}
              fillBtnTxt={ASP_SUBMIT_EVALUATION}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompotencyEvaluation;
