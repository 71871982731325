import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import type { TabsProps } from "antd";
import { Dropdown, Space, Card, Tabs, Alert } from "antd";
import "./performance.css";
import FillButton from "../common/button/FillButton";
import EmptyGoals from "./EmptyGoals";
import QuarterlyCheckIn from "./quarterly-checkin/employee-flow/quarterly-checkin";
import ExpandMore from "../../themes/assets/images/svg/Expand_more.svg";
import OutlineButton from "../common/button/OutlineButton";
import {
  SUBMIT_DRAFT_ALERT_MSG,
  SUBMIT_DRAFT_POPUP_TITLE,
  SUBMIT_GOAL_CONFIRM_MSG,
} from "../../constants";
import n from "../navigation/Route.Names";
import ToastMessage from "../common/ToastMsg/toastMessage";

import ToastClose from "../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastDraft from "../../themes/assets/images/performance/svg/ToastDraft.svg";
import Overview from "./overview";
import AspForm from "./ASPForm";
import { useSelector } from "react-redux";
import GoalSelection from "./components/GoalSelection";
import { resetState } from "../../redux/reducers/performance";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import { submitGoal } from "../../redux/actions/performance";
import ModalDialog from "../common/modal/modal";
import DraftGoalImage from "../../themes/assets/images/performance/svg/DraftGoal.svg";
import SubmitGoalImage from "../../themes/assets/images/performance/svg/SubmitGoal.svg";

import { isEmpty } from "lodash";

const Performance = () => {
  const dispatch = useAppDispatch();
  const [year, setYear] = useState("2024");
  const [banner, setBanner] = useState(true);
  const [submittedToast, setSubmittedToast] = useState(false);
  const [showGoalSelectionMenu, setShowGoalSelectionMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [draftvisible, setDraftVisible] = useState(false);
  const draftState = useSelector((state: any) => state.performance.isDrafted);
  const [isSubmitModalOpen, setSubmitModalOpen] = useState(false);

  const {
    totalGoals = 0,
    submittedGoalData,
    draftGoalExists,
    allGoalTemplateData,
  } = useAppSelector((state: any) => state.performance) || [];
  const { currentYear = null } = allGoalTemplateData?.webGoalTabInfo || {};

  useEffect(() => {
    currentYear && setYear(currentYear);
  }, [currentYear]);
  useEffect(() => {
    setDraftVisible(draftState);
  }, [draftState]);

  useEffect(() => {
    if (!isEmpty(submittedGoalData)) {
      setSubmittedToast(true);
    } else {
      setSubmittedToast(false);
    }
  }, [submittedGoalData]);

  const items: MenuProps["items"] = [
    {
      label: "2024",
      key: "2024",
    },
    {
      label: "2023",
      key: "2023",
    },
    {
      label: "2022",
      key: "2022",
    },
    {
      label: "2021",
      key: "2021",
    },
    {
      label: "2020",
      key: "2020",
    },
    {
      label: "2019",
      key: "2019",
    },
  ];

  const tabitems: TabsProps["items"] = [
    {
      key: "1",
      label: "Overview",
      children: <Overview />,
    },
    {
      key: "2",
      label: "Goals",
      children: <EmptyGoals />,
    },
    {
      key: "3",
      label: "Quarterly Check In",
      children: <QuarterlyCheckIn year={year} />,
    },
    {
      key: "4",
      label: "ASP Form",
      children: <AspForm />,
    },
  ];

  const handleMoreClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "Archive Goals") {
      navigate(`/${n.PERFORMANCE_URL}/${n.ARCHIVED_GOAL_URL}`);
    }
  };

  const handleYearClick: MenuProps["onClick"] = (e) => {
    setYear(e.key);
  };

  const moreitems: MenuProps["items"] = [
    {
      label: "Export",
      key: "Export",
    },
    {
      label: "Archive Goals",
      key: "Archive Goals",
    },
    {
      label: "Move Goals",
      key: "Move Goals",
    },
  ];

  const menuYear = {
    items,
    onClick: handleYearClick,
    selectable: true,
    defaultSelectedKeys: [year],
  };

  const menuProps = {
    items: moreitems,
    onClick: handleMoreClick,
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  const fillBtnAction = (value: object) => {
    setSubmitModalOpen(true);
  };

  const handleAddGoal = (value: object) => {
    setShowGoalSelectionMenu(true);
  };

  const handleCancel = () => setSubmitModalOpen(false);

  const handleOk = () => {
    !draftGoalExists && dispatch(submitGoal({ year }));
    setSubmitModalOpen(false);
  };
  const closeHandler = () => {
    setBanner(false);
  };

  return (
    <>
      <div className="performanceContainer">
        {/* This banner code will use in future*/}
        {/* {banner && (
          <div className="breadcrum_performance">
            <Alert
              className="performance_success-alert"
              message="You have added your goal successfully 🎉."
              type="success"
              // icon={<img src={Warning} alt="" />}
              // showIcon
            />
            <img
              className="success-close-icon"
              src={CloseIconPopUp}
              alt="CloseIconPopUp"
              onClick={closeHandler}
            />
          </div>
        )} */}
        <div className="performance-header">
          <div className="left-side">
            <h5>My Performance</h5>
            <Dropdown menu={menuYear} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <Space className="year-selection">
                  {year}
                  <img src={ExpandMore} alt="" />
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="right-side">
            {totalGoals ? (
              <FillButton
                fillBtnTxt="Submit Goals"
                fillBtnAction={fillBtnAction}
              />
            ) : (
              ""
            )}
            <OutlineButton
              backText="+ Add Goals"
              outlineBtnAction={handleAddGoal}
            />
            <Dropdown.Button
              menu={menuProps}
              trigger={["click"]}
              className="menu-icon"
            />
          </div>
        </div>
        <div className="performance-body">
          <Card style={{ width: "100%" }}>
            <Tabs
              defaultActiveKey={location?.state?.performanceTabKey || "1"}
              items={tabitems}
              onChange={onChange}
            />
          </Card>
        </div>
        {showGoalSelectionMenu && (
          <GoalSelection
            onClickCancel={() => setShowGoalSelectionMenu(false)}
          />
        )}
      </div>

      {submittedToast && (
        <ToastMessage
          closeAction={() => {
            setSubmittedToast(false);
            dispatch(resetState());
          }}
          customToastArea={"custom-toast-area draft-skills-extracted"}
          customToastLeftArea={"custom-left-area"}
          icon={ToastDraft}
          toastMsgTitle={submittedGoalData?.message}
          toastMsgPara={submittedGoalData?.genericMessage}
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}

      <ModalDialog
        openModal={isSubmitModalOpen}
        DialogHeading={
          draftGoalExists ? `${SUBMIT_DRAFT_ALERT_MSG}` : "Submit Goals"
        }
        closeIcon={false}
        indexScroll={{ display: "none" }}
        modalImageStyles={draftGoalExists ? {} : { display: "none" }}
        modalImageClasses="draft-submit-popup-img"
        modalHeadingStyles={{ dislay: "none" }}
        modalCenterImgClasses="submit-goal-popup-img"
        popupDivider={{ display: "none" }}
        msgContent={
          draftGoalExists
            ? `${SUBMIT_DRAFT_POPUP_TITLE}`
            : `${SUBMIT_GOAL_CONFIRM_MSG}`
        }
        okTxt={draftGoalExists ? "Okay" : "Submit"}
        cancelTxt={"Cancel"}
        cancelBtn={handleCancel}
        okBtn={handleOk}
        onOk={handleOk}
        onCancel={handleCancel}
        modalSubHeading={"modalSubHeading"}
        modalStyles={"backpopup delete-milestone-popup"}
        maskClosable={false}
        modalImage={draftGoalExists ? DraftGoalImage : SubmitGoalImage}
      />
    </>
  );
};

export default Performance;
