import React from "react";
import { Button } from "antd";
import n from "../navigation/Route.Names";
import AaccessTime from "../../themes/assets/images/svg/front/active_access_time.svg";
import "./Milestones.css";
import { useNavigate } from "react-router-dom";
import { PREVIEW_GOAL_HEADINGS, PREVIEW_GOAL_EDIT_BTN } from "../../constants";

// Under preview: milestone component
const Milestones = (props: any) => {
  const {
    isEdit = true,
    milestones,
    webMilestoneQuestionList,
    questionDTO,
  } = props;
  const { question, questionProperties } = questionDTO || {};
  const navigate = useNavigate();

  return (
    <div className="milestone-section">
      <div className="preview-addgoal-title-btn-main">
        <div>
          <p className="review-addgoal-title">{question}</p>
          <p>{questionProperties?.fileProperties?.placeHolder1 || ""}</p>
          {/* <p>{GOAL_SUBTITLE}</p> */}
        </div>
        {isEdit && (
          <Button
            className="preview-addgoal-btn"
            onClick={() =>
              navigate(`/${n.PERFORMANCE_URL}/${n.MILESTONE_URL}`, {
                state: { isEdit: true },
              })
            }
          >
            {PREVIEW_GOAL_EDIT_BTN}
          </Button>
        )}
      </div>

      {milestones?.length &&
        milestones.map((item: any, index: any) => {
          const [q0, q1, q2, q3] = Object.keys(item);
          const [a0, a1, a2, a3]: any = Object.values(item);
          return (
            <div className="inner-table-milestone">
              <p className="preview-milestones">
                <img
                  src={AaccessTime}
                  alt="AaccessTime"
                  style={{ marginRight: "5px" }}
                />
                {PREVIEW_GOAL_HEADINGS} {index + 1}
              </p>
              <div className="preview-milestones-name">{a1}</div>
              <div className="preview-milestones-dates">
                {webMilestoneQuestionList[q2]} : {a2} |
                {webMilestoneQuestionList[q3]} : {a3}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Milestones;
