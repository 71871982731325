import { Button } from "antd";

function FillButton(props:any) {
    return(
        <>
        <Button disabled={props.disabled} className={props.fillBtnStyles} type="primary" onClick={props.fillBtnAction}>{props.fillBtnTxt}</Button>
        </>
    )
}


export default FillButton;