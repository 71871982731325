import { useState } from "react";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import { Dropdown, MenuProps, TabsProps, Card, Tabs, Button } from "antd";
import "./index.css";
import ExpandMore from "../../../../themes/assets/images/svg/Expand_more.svg";
import QuarterlyCheckInManager from "./quarterly-checkin-manager";
import BreadcrumbComponent from "../../../common/breadcrumb/breadcrumb";
import capitalizeFirstLetter from "../../../utils/util";
import ASPForm from "../../ManagerView/EmployeeDetails/ASPForm/index";
import ToastMessage from "../../../common/ToastMsg/toastMessage";
import ToastInfo from "../../../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastClose from "../../../../themes/assets/images/performance/svg/ToastClose.svg";
import EmptyGoals from "../../EmptyGoals";
import ManagerGoals from "./goals-employee";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../../../redux/reducers/performance";
import { RootState } from "../../../../redux/store";
import n from "../../../navigation/Route.Names";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import {
  getEmployeeGoalListData,
  getEmployeeListData,
} from "../../../../redux/actions/performance";

const ComingSoonComponent = () => {
  return <div>Coming Soon</div>;
};

const EmployeeDetailPerformance = () => {
  const navigate = useNavigate();
  const [year, setYear] = useState("2024");
  const [reporteeId, setReporteeId] = useState<any>();
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const param = useParams();
  const dispatch = useAppDispatch();
  const viewState = useSelector((state: any) => state.performance.isVisible);
  const employeeListData = useAppSelector((state: any) => state.performance);
  const employeereportId = employeeListData?.getEmployeeFilteredData;
  useEffect(() => {
    setVisible(viewState);
    dispatch(
      getEmployeeGoalListData({
        year: year,
        reporteeId: param.reporteeId,
      })
    );
  }, [viewState, param.reporteeId]);
  const items: MenuProps["items"] = [
    {
      label: "2023",
      key: "2023",
    },
    {
      label: "2022",
      key: "2022",
    },
    {
      label: "2021",
      key: "2021",
    },
    {
      label: "2020",
      key: "2020",
    },
    {
      label: "2019",
      key: "2019",
    },
  ];

  const tabitems: TabsProps["items"] = [
    {
      key: "1",
      label: "Overview",
      children: <ComingSoonComponent />,
    },
    {
      key: "2",
      label: "Goals",
      children: <ManagerGoals />,
    },
    {
      key: "3",
      label: "Review Comments",
      children: <QuarterlyCheckInManager />,
    },
    {
      key: "4",
      label: "ASP Form",
      children: <ASPForm />,
    },
  ];

  const handleMoreClick: MenuProps["onClick"] = (e) => {
    console.log("click", e);
  };

  const handleYearClick: MenuProps["onClick"] = (e) => {
    setYear(e.key);
  };

  const moreitems: MenuProps["items"] = [
    {
      label: "Export",
      key: "Export",
    },
    {
      label: "Move Goals",
      key: "Move Goals",
    },
  ];

  const menuYear = {
    items,
    onClick: handleYearClick,
    selectable: true,
    defaultSelectedKeys: [year],
  };

  const menuProps = {
    items: moreitems,
    onClick: handleMoreClick,
  };

  const onChange = (key: string) => {
    console.log(key);
  };
  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}`}
          className="link-highlight"
        >
          Team Performance
        </Link>
      ),
    },
    {
      title: employeeListData?.getEmployeeFilteredData?.reporteeInfo?.userName,
    },
  ];
  return (
    <>
      <div className="team-performanceContainer">
        <div className="empDetialBreadcrumb">
          <BreadcrumbComponent items={breadbrumbItems} />
        </div>
        <div className="performance-header-empDetail">
          <div className="left-side">
            <div className="detail-username">
              {param.reporteeId === employeereportId?.reporteeInfo?.reporteeId
                ? employeeListData?.getEmployeeFilteredData?.reporteeInfo
                    ?.userName
                : null}
            </div>
          </div>
          <div className="right-side">
            <div className="cascade-right-button">
              <Button
                onClick={() => {
                  navigate(
                    `/web/my_work/${"team_performance"}/${
                      n.MANAGAER_CASCADE_GOAL_URL
                    }`
                  );
                }}
                className="cascade-btn-style"
              >
                Cascade Goal
              </Button>
              <Dropdown.Button
                menu={menuProps}
                trigger={["click"]}
                className="menu-icon"
              />
            </div>
          </div>
        </div>
        <div className="performance-body">
          <Card style={{ width: "100%" }}>
            <Tabs
              defaultActiveKey={location?.state?.performanceTabKey || "1"}
              items={tabitems}
              onChange={onChange}
            />
          </Card>
        </div>
      </div>
      {visible && (
        <ToastMessage
          closeAction={() => {
            setVisible(false);
            dispatch(resetState());
          }}
          customToastArea={
            true
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={ToastInfo}
          toastMsgTitle={"Comment Sent Successfully "}
          toastMsgPara={"Comment has been sent to an employee successfully"}
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
    </>
  );
};

export default EmployeeDetailPerformance;
