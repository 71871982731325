import { useEffect } from "react";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import n from "../../navigation/Route.Names";
import { Card, Input } from "antd";
import "./contribute.css";
import CheckinSearchIn from "../../../themes/assets/images/performance/svg/search-Icon-checkin.svg";
import ContributeCard from "./contributeCard";
import { Link } from "react-router-dom";
import { getContributePublicGoalList } from "../../../redux/actions/performance";
import { useAppDispatch, useAppSelector } from "../../../redux/store/redux-hooks";
import { SearchIconManagerView } from "../../common/svg/svg";

const ContributorDetail = [
  {
    id: 1,
    Contributorname: "Adopt 3 innovative ideas",
    Contributordesignation: "Design Manager - Antony S",
    ProfileTitle: "Contributor",
  },
  {
    id: 2,
    Contributorname: "Achieve 90% Deployment Success",
    Contributordesignation: "Design Manager - Antony S",
    ProfileTitle: "Contributor",
  },
  {
    id: 3,
    Contributorname: "Deliver 1000 Java Profiles",
    Contributordesignation: "Design Manager - Antony S",
    ProfileTitle: "Contributor",
  },
  {
    id: 4,
    Contributorname: "Adopt 3 innovative ideas",
    Contributordesignation: "Design Manager - Antony S",
    ProfileTitle: "Contributor",
  },
  {
    id: 5,
    Contributorname: "Achieve 90% Deployment Success",
    Contributordesignation: "Design Manager - Antony S",
    ProfileTitle: "Contributor",
  },
];


const ChallengingGoal = () => {
  
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getContributePublicGoalList({}));
  }, []);

  const handleSearch = (e: any) => {
    const searchValue = e.target?.value;
    if (searchValue === "" || searchValue.length >= 2) {
      dispatch(
        getContributePublicGoalList({
          searchString: searchValue,
        })
      );
    }
  };


  const contributeGoalList =
  useAppSelector(
    (state: any) => state.performance.contributePublicGoalList
  ) || [];

  const PublicGoalList = contributeGoalList?.challenges || [];


  const items = [
    {
      title: (
        <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
          Perfomance
        </Link>
      ),
    },
    {
      title: "Contribute Goals",
    },
  ];

  return (
    <div className="challeng-goal">
      <div className="breadcrumb_newgoal">
        <BreadcrumbComponent
          // rootPath={"Perfomance"}
          // subPath={"Contribute Goals"}
          // rootLink={`/${n.PERFORMANCE_URL}`}
          items={items}
        />
        <h1>Contribute Goals</h1>
        <p>
          Find a challenging goal for yourself that elevates the organization
        </p>
      </div>
      <Card style={{ width: "100%" }}>
        <div className="contribute-head">
          <h1>List of Challenging Goals</h1>
          <div className="search">
            <Input placeholder="Search Here" onChange={handleSearch} />
            <SearchIconManagerView />
          </div>
        </div>
        <div className="goals_list">
          <span className="goals-total">
            Total Challenge: <span>{contributeGoalList?.totalChallenges}</span>
          </span>
          <div className="goals_card-otr">
            {PublicGoalList.map((item) => (
              <ContributeCard key={item?.id?.toString()} contributor={item} />
            ))}            
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ChallengingGoal;
