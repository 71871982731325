import React from "react";
import "./GoalEvalution.css";


const EvaluationCard = ({ employee }) => {
    return (
        <div className="goal-evalution-cont">
            <span className="title">{employee.EvalutionLabel}</span>
            <span>{employee.EvalutionDescrption}</span>
        </div>
    )
}
export default EvaluationCard;