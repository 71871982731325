import React, { useState } from "react";
import "./CommentCard.css";
import avatar from "../../../themes/assets/images/header-avatar.png";
import AvatarProfile from "../../Avatar/AvatarProfile";

const CommentCard = (props: any) => {
  const [expandComment, setExpandComment] = useState(false);
  const { userData } = props;
  const style = props?.large
    ? "commentCard-checkin "
    : "commentCard-checkin medium";

  const onClickMore = () => {
    setExpandComment(!expandComment);
  };

  return (
    <div className={style}>
      <div className="read_more-cont">
        <div
          className={
            expandComment ? "comment-checkin" : "comment-checkin clip-text"
          }
        >
          {props?.comment}
        </div>
        <button onClick={onClickMore}>{expandComment ? "less" : "more"}</button>
      </div>
      <div className="profile-checkin">
        {userData?.profilePictureImage ? (
          <img
            src={`data:image/format;base64,${userData?.profilePictureImage}`}
            alt=""
            className="profilepic-checkin"
          />
        ) : (
          <div className="avatar-container">
            <AvatarProfile
              name={userData?.userName}
              size={32}
              profilePic={""}
            />
          </div>
        )}
        <div className="userData-checkin">
          <div className="name-checkin">{userData?.userName}</div>
          <div className="designation-checkin">{userData?.designation}</div>
        </div>
      </div>
    </div>
  );
};

export default CommentCard;

//TODO : look into profile picture data and if no profile pic display Initials of name
