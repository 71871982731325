import React from "react";
import "./GoalSelection.css";
import { CHOOSE_GOAL, CHOOSE_GOAL_DESC } from "../../../constants";
import PersonalGoal from "../../../themes/assets/images/performance/personal-goal.png";
import ArchivedGoal from "../../../themes/assets/images/performance/archived-goal.png";
import GoalLibrary from "../../../themes/assets/images/performance/goal-library.png";
import CloseBtn from "../../../themes/assets/images/performance/Close.png";
import { useNavigate } from "react-router-dom";
import n from "../../navigation/Route.Names";
import {
  resetAllGoalMilestones,
  setGoalValues,
} from "../../../redux/reducers/performance";
import { useAppDispatch } from "../../../redux/store/redux-hooks";

const GoalSelectionCard = (props) => {
  return (
    <div onClick={props?.onClick} className="goalSelectionCard">
      <img className="goal-selection-img" src={props?.img} />
      <div className="goal-selection-card-title">{props?.title}</div>
      <div className="goal-selection-card-desc">{props?.desc}</div>
    </div>
  );
};

const GoalSelection = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickPersonaGoal = () => {
    dispatch(resetAllGoalMilestones());
    dispatch(setGoalValues({}));
    navigate(`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}`);
  };

  const onClickArchivedGoal = () => {
    navigate(`/${n.PERFORMANCE_URL}/${n.ARCHIVED_GOAL_URL}`);
  };
  const onClickGoalLibrary = () => {
    //TODO: Add Goal Library Navigation
  };

  return (
    <div onClick={props?.onClickCancel} className="goal-selection-bg">
      <div className="goal-selection-container">
        <img
          onClick={props?.onClickCancel}
          src={CloseBtn}
          alt=""
          className="closeBtn"
        />
        <div className="header-goal-selection">{CHOOSE_GOAL}</div>
        <div className="sub-header-goal-selection">{CHOOSE_GOAL_DESC}</div>
        <div className="goal-selection-card-container">
          <GoalSelectionCard
            onClick={onClickPersonaGoal}
            img={PersonalGoal}
            title={"Personal Goal"}
            desc={
              "Personal Goal allows you to make up your own goal and assign any metrics you want"
            }
          />
          <GoalSelectionCard
            onClick={onClickArchivedGoal}
            img={ArchivedGoal}
            title={"Archived Goal"}
            desc={
              "Archived goals are the goals that have been saved by you for later use"
            }
          />
          <GoalSelectionCard
            onClick={() => {}}
            img={GoalLibrary}
            title={"Goal Library"}
            desc={
              "Library Goal are selected from an organized library with suggested metrics"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GoalSelection;
