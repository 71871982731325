import "./index.css";
import { ASP_START_FORM, ASP_VIEW_FORM } from "../../../constants";
import n from "../../navigation/Route.Names";
import GoalPreview from "./GoalPreview";

const AspForm = () => {
  return (
    <div className="form_year-detail">
      <GoalPreview
        title={"2023 Year End Evaluation"}
        startDate={"1st April 2023"}
        endDate={"10th April 2023"}
        currentlyWith={"Shaista Khanum"}
        currentStep={"Employee Review"}
        navigationUrl={`/${n.PERFORMANCE_URL}/${n.GOAL_EVALUATION_URL}`}
        buttonText={ASP_START_FORM}
      />
      <GoalPreview
        title={"2024 Year End Evaluation"}
        startDate={"3rd April 2023"}
        endDate={"10th April 2023"}
        currentlyWith={"Shaista Khanum"}
        currentStep={"Employee Review"}
        navigationUrl={`/${n.PERFORMANCE_URL}/${n.VIEW_ASP}`}
        buttonText={ASP_VIEW_FORM}
      />
    </div>
  );
};

export default AspForm;
