import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuProps, TabsProps, Dropdown, Space, Card, Tabs } from "antd";
import "./index.css";
import EmployeeList from "./EmployeeList";
import ExpandMore from "../../../themes/assets/images/svg/Expand_more.svg";
import { START } from "../../../constants";
import ManagerOverview from "./ManagerOverview";
import PendingReview from "../ManagerView/PendingReview/index";
import { useAppDispatch } from "../../../redux/store/redux-hooks";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/primereact.css";

function ComingSoonComponent() {
  return <div>Coming Soon</div>;
}

const DummyButton = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(`/web/my_work/${"team_performance"}/detail`)}
    >
      hello
    </button>
  );
};

const TeamPerformance = () => {
  const [year, setYear] = useState("2024");
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  const items: MenuProps["items"] = [
    {
      label: "2024",
      key: "2024",
    },
    {
      label: "2023",
      key: "2023",
    },
    {
      label: "2022",
      key: "2022",
    },
    {
      label: "2021",
      key: "2021",
    },
    {
      label: "2020",
      key: "2020",
    },
    {
      label: "2019",
      key: "2019",
    },
  ];

  const tabitems: TabsProps["items"] = [
    {
      key: "1",
      label: "Overview",
      children: <ManagerOverview />,
    },
    {
      key: "2",
      label: "Pending Review",
      children: <PendingReview />,
    },
    {
      key: "3",
      label: "Employee List",
      children: <EmployeeList year={year} />,
    },
  ];

  const handleMoreClick: MenuProps["onClick"] = (e) => {
    console.log("click", e);
  };

  const handleYearClick: MenuProps["onClick"] = (e) => {
    setYear(e.key);
  };

  const moreitems: MenuProps["items"] = [
    {
      label: "Export",
      key: "Export",
    },
    {
      label: "Move Goals",
      key: "Move Goals",
    },
  ];

  const menuYear = {
    items,
    onClick: handleYearClick,
    selectable: true,
    defaultSelectedKeys: [year],
  };

  const menuProps = {
    items: moreitems,
    onClick: handleMoreClick,
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <div className="team-performanceContainer">
      <div className="performance-header">
        <div className="left-side">
          <h5>Team Performance</h5>
          <Dropdown menu={menuYear} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Space className="year-selection">
                {year}
                <img src={ExpandMore} alt="" />
              </Space>
            </a>
          </Dropdown>
        </div>
        <div className="right-side">
          <Dropdown.Button
            menu={menuProps}
            trigger={["click"]}
            className="menu-icon"
          />
        </div>
      </div>
      <div className="performance-body">
        <Card style={{ width: "100%" }}>
          <TabView activeIndex={activeIndex} className="manager-tabs">
            <TabPanel header="Pending Review">
              <PendingReview />
            </TabPanel>
            <TabPanel header="Employee List">
              <EmployeeList year={year} />
            </TabPanel>
          </TabView>
        </Card>
      </div>
    </div>
  );
};

export default TeamPerformance;
