import { useNavigate } from "react-router-dom";
import { ASP_START_FORM } from "../../../../../constants";
import FillButton from "../../../../common/button/FillButton";
import n from "../../../../navigation/Route.Names";
const AspForm = () => {
  let navigate = useNavigate();
  return (
    <>
      <FillButton
        fillBtnAction={() => {
          navigate(
            `/web/my_work/${"team_performance"}/manager_goal_evaluation`
          );
        }}
        fillBtnTxt={ASP_START_FORM}
      />
    </>
  );
};

export default AspForm;
