import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getUserPerformanceStaticData,
  quarterlyCheckinDataEmployee,
  postQuarterlyCheckinCommentEmployee,
  getGoalTemplate,
  saveGoal,
  getEmployeeListData,
  getEmployeeGoalListData,
  getAllGoalTemplate,
  deleteGoal,
  saveGoalComment,
  submitGoal,
  postCasecadeGoalCommentManager,
  cascadeToEmployeeList,
  archiveGoal,
  getAllArchiveGoals,
  deleteArchiveGoal,
  getRestoreArchiveGoal,
  getQuarterlyCheckinComments,
  saveQuarterlyCheckinComments,
  getContributePublicGoalList,
  getContributeViewGoal,
} from "../actions/performance";
import ContributeViewGoal from "../../components/Performance/contributeGoals/contributeViewGoal";

type performanceData = {
  toastTitle: string;
  toastMessage: string;
  toastIcon: string;
  showToast: boolean;
  years: Array<Number>;
  quarterlyCheckInEmployeeData: any;
  loadingStaticData: boolean;
  errorLoadingStaticData: boolean; //block the whole view
  loadingTabData: boolean;
  errorTabData: boolean; //block only one tab option
  minorLoading: boolean;
  minorError: boolean; //show a error notification
  minorSuccess: boolean;
  isVisible: boolean; //toastmessage view
  isDrafted: boolean;
  goalTemplateWebData: any;
  goalTemplateMilestoneData: any;
  goalTemplateLoading: boolean;
  goalTemplateError: boolean;
  saveGoalWebData: any;
  saveGoalWebMilestoneData: any;
  saveGoalLoading: boolean;
  saveGoalError: boolean;
  webMilestones: any;
  webGoal: any;
  webMilestoneQuestionList: any;
  goalValues: any;
  milestones: any;
  sectionValues: any;
  milestonequestionDTO: any;
  mandatekeysForQuestions: any;
  getEmployeeData: any;
  getEmployeeDataLoading: boolean;
  getEmployeeDataError: boolean;
  getEmployeeGoalData: any;
  getEmployeeGoalDataLoading: boolean;
  getEmployeeGoalDataError: boolean;
  getEmployeeGoalDetails: any;
  getEmployeeGoalDetailsLoading: boolean;
  getEmployeeGoalDetailsError: boolean;
  allGoalTemplateLoading: boolean;
  allGoalTemplateError: boolean;
  allGoalTemplateData: any;
  goalId: any;
  goalTemplateData: any;
  shouldGetGoals: boolean;
  deleteGoalStatus: string;
  deleteGoalMessage: string;
  deleteGoalGenericMessage: string;
  milestoneFormat: any;
  saveGoalCommentLoading: boolean;
  saveGoalCommentError: boolean;
  saveGoalCommentData: any;
  loadingSubmitGoal: boolean;
  errorloadingSubmitGoal: boolean;
  submittedGoalData: any;
  totalGoals: number;
  goalData: any;
  draftGoalExists: boolean;
  casecadeGoalCommentManager: any;
  casecadeGoalLoading: boolean;
  casecadeGoalError: boolean;
  cascadeToEmployeeDetailList: any;
  casecadeGoalEmployeeLoading: boolean;
  casecadeGoalEmployeeError: boolean;
  archiveGoalLoading: boolean;
  archiveGoalError: boolean;
  allArchiveGoalsLoading: boolean;
  allArchiveGoalsError: boolean;
  allArchiveGoals: any;
  totalArchiveGoals: number;
  deleteArchiveGoalLoading: boolean;
  deleteArchiveGoalError: boolean;
  deleteArchiveGoal: any;
  shouldGetArchiveGoals: boolean;
  cascadeGoal: any;
  newGoalValues: any;
  restoreArchiveGoalsLoading: boolean;
  resoreArchiveGoalsError: boolean;
  restoreArchiveGoals: any;
  isErrorToast: boolean;
  allQuarterlyCheckinCommentsLoading: boolean;
  allQuarterlyCheckinCommentsError: boolean;
  allQuarterlyCheckinComments: any;
  saveQuarterlyCheckinCommentsLoading: boolean;
  saveQuarterlyCheckinCommentsError: boolean;
  saveQuarterlyCheckinComments: any;
  saveQuarterlyCheckinCommentsSuccess: boolean;
  toCallMilestoneSave: boolean;
  saveMilestonePayload: {};
  contributePublicGoalLoading:boolean;
  contributePublicGoalError:boolean;
  contributePublicGoalList: any;
  contributeViewGoalLoading:boolean;
  contributeViewGoalError:boolean;
  contributeViewGoalList: any;
};

const initialDetailState: performanceData = {
  toastTitle: "",
  toastMessage: "",
  toastIcon: "",
  showToast: false,
  years: [],
  quarterlyCheckInEmployeeData: {},
  loadingStaticData: false,
  errorLoadingStaticData: false,
  loadingTabData: false,
  errorTabData: false,
  minorError: false,
  minorLoading: false,
  minorSuccess: false,
  isVisible: false,
  isDrafted: false,
  goalTemplateWebData: [],
  goalTemplateMilestoneData: [],
  goalTemplateLoading: false,
  goalTemplateError: false,
  saveGoalWebData: {},
  saveGoalWebMilestoneData: {},
  saveGoalLoading: false,
  saveGoalError: false,
  archiveGoalLoading: false,
  archiveGoalError: false,
  webMilestones: [],
  webGoal: [],
  webMilestoneQuestionList: {},
  goalValues: {},
  milestones: [],
  sectionValues: [],
  milestonequestionDTO: {},
  mandatekeysForQuestions: {},
  getEmployeeData: [],
  getEmployeeDataLoading: false,
  getEmployeeDataError: false,
  getEmployeeGoalData: [],
  getEmployeeGoalDataLoading: false,
  getEmployeeGoalDataError: false,
  getEmployeeGoalDetails: [],
  getEmployeeGoalDetailsLoading: false,
  getEmployeeGoalDetailsError: false,
  allGoalTemplateLoading: true,
  allGoalTemplateError: false,
  allGoalTemplateData: [],
  goalTemplateData: [],
  goalId: "",
  shouldGetGoals: true,
  deleteGoalStatus: "",
  deleteGoalMessage: "",
  deleteGoalGenericMessage: "",
  milestoneFormat: { order: 1 },
  saveGoalCommentLoading: false,
  saveGoalCommentError: false,
  saveGoalCommentData: null,
  loadingSubmitGoal: true,
  errorloadingSubmitGoal: false,
  submittedGoalData: null,
  totalGoals: 0,
  goalData: {},
  draftGoalExists: false,
  casecadeGoalCommentManager: {},
  casecadeGoalLoading: false,
  casecadeGoalError: false,
  cascadeToEmployeeDetailList: {},
  casecadeGoalEmployeeLoading: false,
  casecadeGoalEmployeeError: false,
  deleteArchiveGoalLoading: true,
  deleteArchiveGoalError: false,
  deleteArchiveGoal: [],
  allArchiveGoalsLoading: true,
  allArchiveGoalsError: false,
  allArchiveGoals: [],
  totalArchiveGoals: 0,
  shouldGetArchiveGoals: true,
  cascadeGoal: {},
  newGoalValues: {},
  restoreArchiveGoalsLoading: false,
  resoreArchiveGoalsError: false,
  restoreArchiveGoals: [],
  isErrorToast: false,
  allQuarterlyCheckinCommentsLoading: false,
  allQuarterlyCheckinCommentsError: false,
  allQuarterlyCheckinComments: {},
  saveQuarterlyCheckinCommentsLoading: false,
  saveQuarterlyCheckinCommentsError: false,
  saveQuarterlyCheckinComments: {},
  saveQuarterlyCheckinCommentsSuccess: false,
  toCallMilestoneSave: false,
  saveMilestonePayload: {},
  contributePublicGoalList: {},
  contributePublicGoalLoading: false,
  contributePublicGoalError: false,
  contributeViewGoalList: {},
  contributeViewGoalLoading: false,
  contributeViewGoalError: false,
};

export const performance: any = createSlice({
  name: "performance",
  initialState: initialDetailState,
  reducers: {
    viewState: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
    resetState: (state) => {
      state.isVisible = false;
      state.isDrafted = false;
    },
    draftState: (state, action: PayloadAction<boolean>) => {
      state.isDrafted = action.payload;
    },
    setWebMilestones: (state, action: PayloadAction<number>) => {
      state.webMilestones = action.payload;
    },
    setWebGoal: (state, action) => {
      state.webGoal = action.payload;
    },
    setGoalData: (state, action) => {
      state.goalData = action.payload;
    },
    setWebMilestoneQuestionList: (state, action) => {
      state.webMilestoneQuestionList = action.payload;
    },
    setMilestoneFormat: (state, action) => {
      state.milestoneFormat = action.payload;
    },
    setGoalValues: (state, action) => {
      state.goalValues = action.payload;
    },
    setCascadedGoal: (state, action) => {
      state.cascadeGoal = action.payload || {};
    },
    setMilestones: (state, action) => {
      state.milestones = action.payload;
    },
    setShouldGetGoals: (state, action) => {
      state.shouldGetGoals = action.payload;
    },
    setMilestoneQuestionDTO: (state, action) => {
      state.milestonequestionDTO = action.payload;
    },
    resetGoalWebMilestones: (state) => {
      state.saveGoalWebMilestoneData = {};
    },
    setGoalWebMilestones: (state, action) => {
      state.saveGoalWebMilestoneData = action.payload || {};
    },
    setSectionValues: (state, action) => {
      state.sectionValues = action.payload;
    },
    setMandatekeys: (state, action) => {
      state.mandatekeysForQuestions = action.payload;
    },
    setNewGoalValues: (state, action) => {
      state.newGoalValues = action.payload || {};
    },
    setToastContainer: (state, action) => {
      const {
        showToast = false,
        toastMessage = "",
        toastTitle = "",
        isErrorToast = false,
      } = action.payload || {};
      state.showToast = showToast;
      state.toastMessage = toastMessage;
      state.toastTitle = toastTitle;
      state.isErrorToast = isErrorToast;
    },
    setGoalId: (state, action) => {
      state.goalId = action.payload || "";
    },
    resetAllGoalMilestones: (state) => {
      state.goalId = "";
      state.mandatekeysForQuestions = {};
      state.saveGoalWebMilestoneData = {};
      state.milestones = [];
      state.sectionValues = {};
      state.webMilestones = [];
      state.allGoalTemplateData = [];
      state.saveGoalWebData = {};
      state.goalTemplateMilestoneData = [];
      state.goalTemplateData = [];
      state.draftGoalExists = false;
      state.cascadeGoal = {};
    },
    clearDeleteGoalStatus: (state) => {
      state.deleteGoalStatus = "";
      state.deleteGoalMessage = "";
      state.deleteGoalGenericMessage = "";
    },
    setSaveGoalWebData: (state, action) => {
      state.saveGoalWebData = action.payload;
    },
    setShouldGetArchiveGoals: (state, action) => {
      state.shouldGetArchiveGoals = action.payload;
    },
    setToCallMilestoneSave: (state, action) => {
      state.toCallMilestoneSave = action.payload || true;
    },
    setSaveMilestonePayload: (state, action) => {
      state.saveMilestonePayload = action.payload || {};
    },
    resetSubmittedGoalData: (state, action) => {
      state.submittedGoalData = action.payload || {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPerformanceStaticData.pending, (state) => {
      state.loadingStaticData = true;
      state.errorLoadingStaticData = false;
    });
    builder.addCase(
      getUserPerformanceStaticData.fulfilled,
      (state: any, action) => {
        state.loadingStaticData = false;
        state.years = action?.payload?.performanceYears;
      }
    );
    builder.addCase(
      getUserPerformanceStaticData.rejected,
      (state: any, action) => {
        state.loadingStaticData = false;
        state.errorLoadingStaticData = true;
      }
    );
    builder.addCase(submitGoal.pending, (state) => {
      state.loadingSubmitGoal = true;
      state.errorloadingSubmitGoal = false;
    });
    builder.addCase(submitGoal.fulfilled, (state: any, action) => {
      state.loadingSubmitGoal = false;
      state.submittedGoalData = action?.payload;
    });
    builder.addCase(submitGoal.rejected, (state: any, action) => {
      state.loadingSubmitGoal = false;
      state.errorloadingSubmitGoal = true;
    });
    builder.addCase(quarterlyCheckinDataEmployee.pending, (state) => {
      state.loadingTabData = true;
      state.errorTabData = false;
      state.minorError = false;
      state.minorSuccess = false;
    });
    builder.addCase(
      quarterlyCheckinDataEmployee.fulfilled,
      (state: any, action) => {
        state.loadingTabData = false;
        state.quarterlyCheckInEmployeeData = action?.payload;
      }
    );
    builder.addCase(
      quarterlyCheckinDataEmployee.rejected,
      (state: any, action) => {
        state.loadingTabData = false;
        state.errorTabData = true;
      }
    );
    builder.addCase(saveGoalComment.fulfilled, (state: any, action) => {
      const { result } = action?.payload || {};
      state.saveGoalCommentData = result || [];
      state.saveGoalCommentLoading = false;
      state.saveGoalCommentError = false;
      state.shouldGetGoals = false;
    });
    builder.addCase(saveGoalComment.rejected, (state: any, action) => {
      state.saveGoalCommentData = [];
      state.saveGoalCommentLoading = false;
      state.saveGoalCommentError = true;
    });
    builder.addCase(saveGoalComment.pending, (state) => {
      state.saveGoalCommentLoading = true;
      state.saveGoalCommentError = false;
    });
    builder.addCase(deleteGoal.pending, (state) => {
      state.allGoalTemplateLoading = true;
      state.allGoalTemplateError = false;
      state.shouldGetGoals = false;
    });
    builder.addCase(deleteGoal.fulfilled, (state: any, action) => {
      state.shouldGetGoals = true;
      const {
        genericMessage = "",
        status = "",
        message = "",
      } = action.payload || {};
      state.allGoalTemplateData = [];
      state.allGoalTemplateLoading = false;
      state.allGoalTemplateError = false;
      state.deleteGoalStatus = status || "";
      state.deleteGoalMessage = message || "";
      state.deleteGoalGenericMessage = genericMessage || "";
      state.showToast = status || "";
      state.toastMessage = message || "";
      state.toastTitle = genericMessage || "";
    });
    builder.addCase(deleteGoal.rejected, (state: any, action) => {
      state.allGoalTemplateData = [];
      state.allGoalTemplateLoading = false;
      state.allGoalTemplateError = true;
      state.shouldGetGoals = false;
    });
    builder.addCase(getAllGoalTemplate.pending, (state) => {
      state.allGoalTemplateLoading = true;
      state.allGoalTemplateError = false;
    });
    builder.addCase(getAllGoalTemplate.fulfilled, (state: any, action) => {
      const { result } = action?.payload || {};
      state.allGoalTemplateData = result || [];
      state.totalGoals = result?.goalInfo?.length || 0;
      state.allGoalTemplateLoading = false;
      state.allGoalTemplateError = false;
      state.shouldGetGoals = false;
      const exists = result?.goalInfo?.find(
        (item) => item?.goalStatus === "Not Started"
      );
      state.draftGoalExists = Boolean(exists);
    });
    builder.addCase(getAllGoalTemplate.rejected, (state: any, action) => {
      state.allGoalTemplateData = [];
      state.allGoalTemplateLoading = false;
      state.allGoalTemplateError = true;
    });
    builder.addCase(getGoalTemplate.pending, (state) => {
      state.goalTemplateLoading = true;
      state.goalTemplateError = false;
    });
    builder.addCase(getAllArchiveGoals.pending, (state) => {
      state.allArchiveGoalsLoading = true;
      state.allArchiveGoalsError = false;
    });
    builder.addCase(getAllArchiveGoals.fulfilled, (state: any, action) => {
      const { result } = action?.payload || {};
      state.allArchiveGoals = result || [];
      state.totalArchiveGoals = result?.length || 0;
      state.allArchiveGoalsLoading = false;
      state.allArchiveGoalsError = false;
      state.shouldGetArchiveGoals = false;
    });
    builder.addCase(getAllArchiveGoals.rejected, (state: any, action) => {
      state.allArchiveGoals = [];
      state.allArchiveGoalsLoading = false;
      state.allArchiveGoalsError = true;
    });
    builder.addCase(deleteArchiveGoal.pending, (state) => {
      state.deleteArchiveGoalLoading = true;
      state.deleteArchiveGoalError = false;
    });
    builder.addCase(deleteArchiveGoal.fulfilled, (state: any, action) => {
      const {
        genericMessage = "",
        status = "",
        message = "",
      } = action.payload || {};
      state.showToast = status || "";
      state.toastMessage = message || "";
      state.toastTitle = genericMessage || "";
      state.deleteArchiveGoalLoading = false;
      state.deleteArchiveGoalError = false;
      state.shouldGetArchiveGoals = true;
    });
    builder.addCase(deleteArchiveGoal.rejected, (state: any, action) => {
      state.deleteArchiveGoalLoading = false;
      state.deleteArchiveGoalError = true;
    });
    builder.addCase(archiveGoal.fulfilled, (state: any, action) => {
      const {
        genericMessage = "",
        status = "",
        message = "",
      } = action.payload || {};
      state.showToast = status || "";
      state.toastMessage = message || "";
      state.toastTitle = genericMessage || "";
      state.archiveGoalLoading = false;
      state.archiveGoalError = false;
      state.shouldGetGoals = true;
    });
    builder.addCase(archiveGoal.rejected, (state: any, action) => {
      state.archiveGoalLoading = false;
      state.archiveGoalError = true;
    });
    builder.addCase(archiveGoal.pending, (state) => {
      state.archiveGoalLoading = true;
      state.archiveGoalError = false;
    });

    builder.addCase(getGoalTemplate.fulfilled, (state: any, action) => {
      const { result, questionnaireCategory } = action?.payload || {};
      if (questionnaireCategory === "WEB_MILESTONE") {
        state.goalTemplateMilestoneData = result || [];
      } else {
        state.goalTemplateData = result || [];
      }
      state.goalTemplateLoading = false;
      state.goalTemplateError = false;
    });
    builder.addCase(getGoalTemplate.rejected, (state: any, action) => {
      state.goalTemplateData = [];
      state.goalTemplateMilestoneData = [];
      state.goalTemplateLoading = false;
      state.goalTemplateError = true;
    });
    builder.addCase(saveGoal.pending, (state) => {
      state.saveGoalLoading = true;
      state.saveGoalError = false;
    });
    builder.addCase(saveGoal.fulfilled, (state: any, action) => {
      const { result, questionnaireCategory } = action?.payload || {};
      const { message, genericMessage } = result || {};
      if (questionnaireCategory === "WEB_MILESTONE") {
        state.saveGoalWebMilestoneData = result || {};
        state.toCallMilestoneSave = false;
      } else {
        state.saveGoalWebData = { ...result, saved: true } || {};
      }
      state.showToast = true;
      state.toastMessage = message || "";
      state.toastTitle = genericMessage || "";
      state.saveGoalLoading = false;
      state.saveGoalError = false;
    });
    builder.addCase(saveGoal.rejected, (state: any, action) => {
      state.saveGoalWebData = {};
      state.saveGoalWebMilestoneData = {};
      state.saveGoalLoading = false;
      state.saveGoalError = true;
    });
    builder.addCase(postQuarterlyCheckinCommentEmployee.pending, (state) => {
      state.minorLoading = true;
      state.minorError = false;
      state.minorSuccess = false;
    });
    builder.addCase(
      postQuarterlyCheckinCommentEmployee.fulfilled,
      (state: any, action) => {
        state.minorLoading = false;
        state.minorSuccess = true;
        //do some manupulation and change the employee comment in state.quarterlyCheckInEmployeeData
      }
    );
    builder.addCase(
      postQuarterlyCheckinCommentEmployee.rejected,
      (state: any, action) => {
        state.minorLoading = false;
        state.minorError = true;
      }
    );
    builder.addCase(getRestoreArchiveGoal.pending, (state, action) => {
      state.restoreArchiveGoalsLoading = true;
      state.resoreArchiveGoalsError = false;
    });
    builder.addCase(getRestoreArchiveGoal.fulfilled, (state, action) => {
      state.restoreArchiveGoalsLoading = false;
      state.restoreArchiveGoals = action?.payload;
      const {
        genericMessage = "",
        status = "",
        message = "",
      } = action.payload || {};
      state.showToast = status || "";
      state.toastMessage = message || "";
      state.toastTitle = genericMessage || "";
      state.resoreArchiveGoalsError = false;
      state.shouldGetArchiveGoals = true;
    });
    builder.addCase(getRestoreArchiveGoal.rejected, (state, action) => {
      state.restoreArchiveGoalsLoading = false;
      state.resoreArchiveGoalsError = true;
    });
    builder.addCase(postCasecadeGoalCommentManager.pending, (state) => {
      state.casecadeGoalLoading = true;
      state.casecadeGoalError = false;
    });
    builder.addCase(
      postCasecadeGoalCommentManager.fulfilled,
      (state: any, action) => {
        state.casecadeGoalLoading = false;
        const goalInfo = action?.payload?.goalInfo || [];
        const business = goalInfo.filter(
          (item: any) => item?.category === "Business"
        );
        const technology = goalInfo.filter(
          (item: any) => item?.category === "Technology"
        );
        const people = goalInfo.filter(
          (item: any) => item?.category === "People"
        );
        state.casecadeGoalCommentManager = { business, technology, people };
      }
    );
    builder.addCase(
      postCasecadeGoalCommentManager.rejected,
      (state: any, action) => {
        state.casecadeGoalLoading = false;
        state.casecadeGoalError = true;
      }
    );

    builder.addCase(cascadeToEmployeeList.pending, (state) => {
      state.casecadeGoalEmployeeLoading = true;
      state.casecadeGoalEmployeeError = false;
    });
    builder.addCase(cascadeToEmployeeList.fulfilled, (state: any, action) => {
      state.casecadeGoalEmployeeLoading = false;
      state.casecadeToEmployeeDetailList = action?.payload;
    });
    builder.addCase(cascadeToEmployeeList.rejected, (state: any, action) => {
      state.casecadeGoalEmployeeLoading = false;
      state.casecadeGoalEmployeeError = true;
    });
    builder.addCase(getEmployeeListData.pending, (state: any, action) => {
      state.getEmployeeDataLoading = true;
      state.getEmployeeDataError = false;
    });
    builder.addCase(getEmployeeListData.fulfilled, (state: any, action) => {
      state.getEmployeeDataLoading = false;
      state.getEmployeeData = action?.payload;
      state.getEmployeeDataError = false;
    });
    builder.addCase(getEmployeeListData.rejected, (state: any, action) => {
      state.getEmployeeDataLoading = false;
      state.getEmployeeDataError = true;
    });
    builder.addCase(getEmployeeGoalListData.pending, (state: any, action) => {
      state.getEmployeeFilteredDataLoading = true;
      state.getEmployeeFilteredDataError = false;
    });
    builder.addCase(getEmployeeGoalListData.fulfilled, (state: any, action) => {
      state.getEmployeeFilteredDataLoading = false;
      const goalInfo = action?.payload?.goalSettingPage?.goalDetails || [];
      const reporteeInfo = action?.payload?.reporteeInfo || [];
      const business = goalInfo.filter(
        (item: any) => item?.category === "Business"
      );
      const technology = goalInfo.filter(
        (item: any) => item?.category === "Technology"
      );
      const people = goalInfo.filter(
        (item: any) => item?.category === "People"
      );
      state.getEmployeeFilteredData = {
        business,
        technology,
        people,
        reporteeInfo,
      };
      state.getEmployeeFilteredDataError = false;
    });
    builder.addCase(getEmployeeGoalListData.rejected, (state: any, action) => {
      state.getEmployeeFilteredDataLoading = false;
      state.getEmployeeFilteredDataError = true;
    });
    builder.addCase(getContributePublicGoalList.pending, 
      (state:any,action)=>{
        state.contributePublicGoalLoading = true;
        state.contributePublicGoalError = false;
      }
    );
    builder.addCase(getContributePublicGoalList.fulfilled, 
      (state:any,action)=>{
        state.contributePublicGoalLoading = false;
        state.contributePublicGoalList = action?.payload;
        state.contributePublicGoalError = false;     
      }
    );
    builder.addCase(getContributePublicGoalList.rejected, 
      (state:any,action)=>{
        state.contributePublicGoalLoading = false;
        state.contributePublicGoalError = true;      
      }
    );
    builder.addCase(getContributeViewGoal.pending, 
      (state:any,action)=>{
        state.contributeViewGoalLoading = true;
        state.contributeViewGoalError = false;
      }
    );
    builder.addCase(getContributeViewGoal.fulfilled, 
      (state:any,action)=>{
        state.contributeViewGoalLoading = false;
        const goalInfo = action?.payload?.result || []
        const pageList = goalInfo.pageList.map((item:any)=>item?.sectionList)
        const sectionList = pageList?.[0]?.map((item:any)=>item.questionList)
        state.contributeViewGoalList = {sectionList};
        state.contributeViewGoalError = false;     
      }
    );
    builder.addCase(getContributeViewGoal.rejected, 
      (state:any,action)=>{
        state.contributeViewGoalLoading = false;
        state.contributeViewGoalError = true;      
      }
    );
    builder.addCase(getQuarterlyCheckinComments.pending, 
      (state:any,action)=>{
        state.allQuarterlyCheckinCommentsLoading = true;
        state.allQuarterlyCheckinCommentsError = false;
        state.saveQuarterlyCheckinCommentsLoading = false;
        state.saveQuarterlyCheckinCommentsError = false; 
      }
    );
    builder.addCase(getQuarterlyCheckinComments.fulfilled, 
      (state:any,action)=>{
        state.allQuarterlyCheckinCommentsLoading = false;
        state.allQuarterlyCheckinComments = action?.payload;
        state.allQuarterlyCheckinCommentsError = false;      
      }
    );
    builder.addCase(getQuarterlyCheckinComments.rejected, 
      (state:any,action)=>{
        state.allQuarterlyCheckinCommentsLoading = false;
        state.allQuarterlyCheckinCommentsError = true;      
      }
    );
    builder.addCase(saveQuarterlyCheckinComments.pending, 
      (state:any,action)=>{
        state.saveQuarterlyCheckinCommentsLoading = true;
        state.saveQuarterlyCheckinCommentsError = false;
        state.saveQuarterlyCheckinCommentsSuccess = false;
      }
    );
    builder.addCase(saveQuarterlyCheckinComments.fulfilled, 
      (state:any,action)=>{
        state.saveQuarterlyCheckinCommentsLoading = false;
        state.saveQuarterlyCheckinCommentsSuccess = true;
        state.allQuarterlyCheckinComments = action?.payload;
        state.saveQuarterlyCheckinCommentsError = false;      
      }
    );
    builder.addCase(saveQuarterlyCheckinComments.rejected, 
      (state:any,action)=>{
        state.saveQuarterlyCheckinCommentsLoading = false;
        state.saveQuarterlyCheckinCommentsError = true;
      }
    ); 
  },
});

export const {
  viewState,
  resetState,
  draftState,
  setGoalValues,
  setMilestoneQuestionDTO,
  setSectionValues,
  setWebMilestones,
  setWebMilestoneQuestionList,
  setMilestoneFormat,
  setMandatekeys,
  setWebGoal,
  resetGoalWebMilestones,
  setGoalWebMilestones,
  setMilestones,
  setGoalId,
  resetAllGoalMilestones,
  clearDeleteGoalStatus,
  setSaveGoalWebData,
  setShouldGetGoals,
  setGoalData,
  setToastContainer,
  setCascadedGoal,
  setNewGoalValues,
  setShouldGetArchiveGoals,
  setToCallMilestoneSave,
  setSaveMilestonePayload,
  resetSubmittedGoalData,
} = performance.actions;
export default performance.reducer;
