import React, { useEffect, useState } from "react";

import "./quarterly-checkin.css";
import CommentTextArea from "../../components/CommentsTextArea";
import QuarterReview from "../../components/QuarterReview";
import ClosePopUp from "../../components/ClosePopUp";
import EmptyReviewComments from "../../components/EmptyReviewComments";
import ToastMessage from "../../../common/ToastMsg/toastMessage";
import ToastInfo from "../../../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../../../themes/assets/images/performance/svg/ToastError.svg";

import ToastClose from "../../../../themes/assets/images/performance/svg/ToastClose.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import {
  postQuarterlyCheckinCommentEmployee,
  quarterlyCheckinDataEmployee,
} from "../../../../redux/actions/performance";
import somethingWrong from "../../../../themes/assets/images/something-went-wrong.png";
import { Spin } from "antd";
import { ALL_COMMENTS } from "../../../../constants";

const QuarterlyCheckIn = (props: any) => {
  const [modalState, setModalState] = useState(false);
  const [visible, setVisible] = useState(false);
  const [comment, setComment] = useState("");
  let toStopMapping = false;
  const dispatch = useAppDispatch();

  const {
    errorLoadingStaticData,
    errorTabData,
    loadingStaticData,
    loadingTabData,
    quarterlyCheckInEmployeeData,
    years,
    minorLoading,
    minorError,
    minorSuccess,
  } = useAppSelector((state: any) => state?.performance);

  useEffect(() => {
    toStopMapping = false;
    dispatch(quarterlyCheckinDataEmployee({ year: props?.year }));
  }, [props?.year]);

  useEffect(() => {
    if (minorError || minorSuccess) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 3000);
    }
  }, [minorError, minorSuccess]);

  const employeeData = quarterlyCheckInEmployeeData?.employee;
  const managerData = quarterlyCheckInEmployeeData?.manager;

  const indexToStop = quarterlyCheckInEmployeeData?.comment?.findIndex(
    (item) => item.allowEmployeeComment
  );

  const onClickSubmit = (year, quarter, comment) => {
    dispatch(
      postQuarterlyCheckinCommentEmployee({
        year: year,
        quarter: quarter,
        comment: comment,
      })
    )
      .unwrap()
      .then((success) => {
        dispatch(quarterlyCheckinDataEmployee({ year: props?.year }));
      });
  };

  if (errorTabData) {
    return (
      <div className="quarterly-checkinEmployee centerItems ">
        <img src={somethingWrong} className="quarterly-checkin-error-main" />
        <div className="something-went-wrong-text-quarterly-checkin">
          Something went wrong
        </div>
        <div className="something-went-wrong-subHeder">
          Connect to a different network and try again
        </div>
      </div>
    );
  }

  if (loadingTabData) {
    return (
      <div className="quarterly-checkinEmployee centerItems ">
        <Spin spinning={loadingTabData} />
      </div>
    );
  }

  return (
    <Spin spinning={minorLoading}>
      <div className="quarterly-checkinEmployee quarterly-checkin-reviewContainer">
        {/* Check weather BE will send empty array when there is no comments TODO */}
        {quarterlyCheckInEmployeeData?.comments?.length > 0 ? (
          <>
            {quarterlyCheckInEmployeeData?.comments?.map(
              (item: any, index: any) => {
                if (item?.allowEmployeeComment) {
                  return (
                    <CommentTextArea
                      key={index?.toString()}
                      title={item?.quarter}
                      placeholder={
                        "Please add your self review comments for this quarter"
                      }
                      submitButtonText={"Self-Assessment Complete"}
                      onClickSubmit={() =>
                        onClickSubmit(props?.year, index + 1, comment)
                      }
                      comment={comment}
                      setComment={setComment}
                    />
                  );
                }
                return null;
              }
            )}
            {quarterlyCheckInEmployeeData?.comments?.map(
              (item: any, index: any) => {
                if (toStopMapping) return;
                if (item?.allowEmployeeComment) {
                  toStopMapping = true;
                  return null;
                }
                return (
                  <>
                    <div className="all-commentsText">{ALL_COMMENTS}</div>
                    <QuarterReview
                      data={item}
                      manager={false}
                      key={index}
                      employeeData={employeeData}
                      managerData={managerData}
                    />
                  </>
                );
              }
            )}
          </>
        ) : (
          <EmptyReviewComments />
        )}

        <ClosePopUp
          modalState={modalState}
          setModalState={setModalState}
          onHandleSubmit={() => {
            setModalState(false);
            setVisible(true);
          }}
          title={"Are you sure you want to submit?"}
          subTitle={"Submitting Self-Assessment to Rupam Lahkar"}
        />
        <div className="z-index-outer-div">
          {visible && (
            <ToastMessage
              closeAction={() => setVisible(false)}
              customToastArea={
                minorSuccess
                  ? "custom-toast-area skills-extracted"
                  : "custom-toast-area no-skills-extracted"
              }
              customToastLeftArea={"custom-left-area"}
              icon={minorSuccess ? ToastInfo : ToastError}
              toastMsgTitle={
                minorSuccess
                  ? "Quarterly Check In Completed"
                  : "Could Not Submit"
              }
              toastMsgPara={
                minorSuccess
                  ? "You have successfully completed the check in for this quarter"
                  : "Unable to submit your comment at the moment. Please try again"
              }
              customToastRightArea={"close-btn-area"}
              closeIcon={ToastClose}
            />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default QuarterlyCheckIn;

//TODO: trigger refetch every time user visits clicks on quarterly checkin
